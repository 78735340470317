// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { observer, inject } from 'mobx-react';
import {
  degreeFromId,
  degreeLogoFromId,
  meetingStringToDate,
  meetingString,
  socialString,
  getAddressFromConfigsById,
  getShortConstitutionFromConfigsById,
  getDistrictFromConfigsById,
  getCountryFromConfigsById,
  getLanguageFromConfigsById
} from '../../helpers/utilities';

import { AddConfirmationStore } from '../../stores/add-confirmation-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { LoginStore } from '../../stores/login-store';
import { ThankYouStore } from '../../stores/thank-you-store';
import { ConfigurationStore } from '../../stores/configuration-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput } from '../inputs/text';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  lodgesStep: () => void;
  thankYouStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  addConfirmationStore?: AddConfirmationStore;
  addLodgeStore?: AddLodgeStore;
  addMeetingStore?: AddMeetingStore;
  addContactStore?: AddContactStore;
  loginStore?: LoginStore;
  thankYouStore?: ThankYouStore;
  configurationStore?: ConfigurationStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject(
  'addConfirmationStore',
  'addLodgeStore',
  'addMeetingStore',
  'addContactStore',
  'loginStore',
  'thankYouStore',
  'configurationStore'
)
@observer
export class AddConfirmation extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  render() {
    if (!this.props.addConfirmationStore) {
      return;
    }

    const addLodgeStore = this.props.addLodgeStore;
    const addMeetingStore = this.props.addMeetingStore;
    const addContactStore = this.props.addContactStore;

    const addConfirmationStore = this.props.addConfirmationStore;
    console.log("------- addConfirmationStore.lodgeResults -------");
    console.log(addConfirmationStore.lodgeResults);
    console.log("---------------------------------------");

    const lodgeInfo = addConfirmationStore.lodgeInfo;
    const validationResult = addConfirmationStore.validationResult;

    const loginStore = this.props.loginStore;

    const thankYouStore = this.props.thankYouStore;
    thankYouStore.typeOfThankYou = 'submission';

    const configurationStore = this.props.configurationStore;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Confirmation</u></h4>
          </Col>
        </Row>

        <Card className='post-option-active'>
        <CardBody>
        <Row>
          <Col sm="12">
            <h1>{addLodgeStore.lodgeInput.name} No. {addLodgeStore.lodgeInput.number} {getShortConstitutionFromConfigsById(addLodgeStore.lodgeInput.constitution.split('_')[0], configurationStore.constitutionResults)}</h1>
          </Col>
        </Row>
        <br />
        <h5>Details:</h5>
        <div>
          <Row>
            <Col sm="6">
              <span className='lodge-details-heading'>Country:</span>
              <br/>
              {getCountryFromConfigsById(addLodgeStore.lodgeInput.country, configurationStore.countryResults)}
            </Col>
            <Col sm="6">
              <span className='lodge-details-heading'>District:</span>
              <br/>
              {getDistrictFromConfigsById(addLodgeStore.lodgeInput.district.split('_')[0], configurationStore.districtResults)}
            </Col>
          </Row>
          <div>
          <span className='lodge-details-heading'>Order:</span>
          <br/>
          {degreeFromId(addLodgeStore.lodgeInput.degree)}
          <br/>
          <span className='lodge-details-heading'>Language:</span>
          <br/>
          {getLanguageFromConfigsById(addLodgeStore.lodgeInput.language, configurationStore.languageResults)}
          <br/>
          <span className='lodge-details-heading'>Address:</span>
          <br/>
          {getAddressFromConfigsById(addLodgeStore.lodgeInput.address, configurationStore.addressResults)}
          <br/>
          <span className='lodge-details-heading'>Meetings:</span>
          <br/>
          {meetingString(addMeetingStore.lodgeInput.meetings)}
          <br/>
          <span className='lodge-details-heading'>Socials:</span>
          <br/>
          {socialString(addMeetingStore.lodgeInput.meetings)}
          <br/>
          <span className='lodge-details-heading'>Contact:</span>
          <br/>
          {(addContactStore.contactInput.title != '') ? addContactStore.contactInput.title + ' ' : ''}{addContactStore.contactInput.name} {addContactStore.contactInput.surname} {addContactStore.contactInput.contact_number}{(addContactStore.contactInput.contact_number_second != '') ? ' (' + addContactStore.contactInput.contact_number_second + ')' : ''}
          <br/>
          <span className='lodge-details-heading'>Website:</span>
          <br/>
          {addContactStore.contactInput.website}
          <br/>
          <span className='lodge-details-heading'>Email:</span>
          <br/>
          {addContactStore.contactInput.email}
          </div>
        </div>
        <br />
        <h5>Meetings:</h5>
        <div>
        {addMeetingStore.lodgeInput.meetings.length > 0 ? (addMeetingStore.lodgeInput.meetings.map((meeting, index) => (
          <div>
            {meetingStringToDate(meeting.position,meeting.day,meeting.month, meeting.type)}
          </div>
        ))) : ''}
        </div>

        </CardBody>
        </Card>


        <Button
        color="primary"
        onClick={async () => {
          this.props.setLoadingTrue();

          const res = await addConfirmationStore.makeSuggestion(
            addLodgeStore,
            addMeetingStore,
            addContactStore,
            loginStore,
            meetingString(addMeetingStore.lodgeInput.meetings),
            socialString(addMeetingStore.lodgeInput.meetings));
          /*const success = addConfirmationStore.checkValidation();
          console.log('----');
          console.log(success);
          console.log('----');
          if (success) {*/
            //this.props.nextStep()
          //}
          console.log('---------');
          console.log(res);
          console.log('---------');
          if (res) {
            this.props.thankYouStep();
          }
          else {
            toast.warning("Could not add new lodge!");
          }

          this.props.setLoadingFalse();
        }}
        >
          Submit
        </Button>
        <Button color="" onClick={this.props.previousStep}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Return-2--Streamline-Core"><desc>Return 2 Streamline Icon: https://streamlinehq.com</desc><g id="return-2--arrow-return-enter-keyboard"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M6.545 0.998a1 1 0 0 0 0 2h2.728a2.638 2.638 0 0 1 0 5.275H4.817V6.545a1 1 0 0 0 -1.707 -0.707L0.384 8.564a1.004 1.004 0 0 0 -0.22 1.09c0.049 0.119 0.121 0.23 0.218 0.327l2.728 2.728a1 1 0 0 0 1.707 -0.707v-1.729h4.456a4.638 4.638 0 1 0 0 -9.275H6.545Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
          Back
        </Button>
      </div>
    );
  }
}

export default AddConfirmationStore;

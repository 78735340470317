// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';

import { FAQsStore } from '../../stores/faqs-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { LoginStore } from '../../stores/login-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';


interface Props {
  searchStep: () => void;
  contributeStep: () => void;
  fAQsStore?: FAQsStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
  toggleQADivs: any;
  toggleQADiv_1: boolean;
  toggleQADiv_2: boolean;
  toggleQADiv_3: boolean;
  toggleQADiv_4: boolean;
  toggleQADiv_5: boolean;
  toggleQADiv_6: boolean;
  toggleQADiv_7: boolean;
  toggleQADiv_8: boolean;
  toggleQADiv_9: boolean;
  toggleQADiv_10: boolean;
}

@inject('fAQsStore', 'contributeStore', 'loginStore',)
@observer
export class FAQs extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
      toggleQADivs: [false,false,false,false,false,false,false,false,false,false],
      toggleQADiv_1: false,
      toggleQADiv_2: false,
      toggleQADiv_3: false,
      toggleQADiv_4: false,
      toggleQADiv_5: false,
      toggleQADiv_6: false,
      toggleQADiv_7: false,
      toggleQADiv_8: false,
      toggleQADiv_9: false,
      toggleQADiv_10: false
    };
  }



  render() {
    if (!this.props.fAQsStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const fAQsStore = this.props.fAQsStore;//typeOfThankYou

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Frequently Asked Questions</u></h4>
          </Col>
        </Row>

        <Card className='post-option-active'>
        <CardBody>
        <Row className="pointing-cursor" onClick={() => {
          //this.state.toggleQADivs[0] = !this.state.toggleQADivs[0];
          this.setState({toggleQADiv_1: !this.state.toggleQADiv_1});
        }}>
          <Col sm="12">
            <b>How you I refer a Mason to use the app?</b>

            <span className="faq-toggle">
            { this.state.toggleQADiv_1
              ?
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Subtract-1--Streamline-Core" height="14" width="14"><desc>Subtract 1 Streamline Icon: https://streamlinehq.com</desc><g id="subtract-1--button-delete-buttons-subtract-horizontal-remove-line-add-mathematics-math-minus"><path id="Vector 1931 (Stroke)" fill="#8fbffa" fill-rule="evenodd" d="M0 7a1 1 0 0 1 1 -1h12a1 1 0 1 1 0 2H1a1 1 0 0 1 -1 -1Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Add-1--Streamline-Core" height="14" width="14"><desc>Add 1 Streamline Icon: https://streamlinehq.com</desc><g id="add-1--expand-cross-buttons-button-more-remove-plus-add-+-mathematics-math"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M8 1a1 1 0 0 0 -2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0 -2H8V1Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            }
            </span>
          </Col>
        </Row>
        <Row className={`toggle-email-setting-div${
          this.state.toggleQADiv_1
            ? '-open'
            : '-closed'
          }`}>
          <Col sm="12">
            <span className="small-font">
              <ol>
                <li>Log in to the FTG system.</li>
                <li>Scan your referral QR code on your profile page.</li>
                <li>You'll be taken to the registration page, which you can only access using an existing member's referral QR code.</li>
                <li>Check your email for a confirmation code. You'll need this code to finish the registration.</li>
                <li>Use the temporary password sent upon confirmation to log in for the first time.</li>
                <li>Once logged in, you'll be asked to change your temporary password.</li>
              </ol>
            </span>
          </Col>
        </Row>
        <hr />
        <Row className="pointing-cursor" onClick={() => {
          //this.state.toggleQADivs[1] = !this.state.toggleQADivs[1];
          this.setState({toggleQADiv_2: !this.state.toggleQADiv_2});
        }}>
          <Col sm="12">
            <b>How do I add a new Lodge?</b>

            <span className="faq-toggle">
            { this.state.toggleQADiv_2
              ?
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Subtract-1--Streamline-Core" height="14" width="14"><desc>Subtract 1 Streamline Icon: https://streamlinehq.com</desc><g id="subtract-1--button-delete-buttons-subtract-horizontal-remove-line-add-mathematics-math-minus"><path id="Vector 1931 (Stroke)" fill="#8fbffa" fill-rule="evenodd" d="M0 7a1 1 0 0 1 1 -1h12a1 1 0 1 1 0 2H1a1 1 0 0 1 -1 -1Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Add-1--Streamline-Core" height="14" width="14"><desc>Add 1 Streamline Icon: https://streamlinehq.com</desc><g id="add-1--expand-cross-buttons-button-more-remove-plus-add-+-mathematics-math"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M8 1a1 1 0 0 0 -2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0 -2H8V1Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            }
            </span>
          </Col>
        </Row>
        <Row className={`toggle-email-setting-div${
          this.state.toggleQADiv_2
            ? '-open'
            : '-closed'
          }`}>
          <Col sm="12">
            <span className="small-font">
              <ol>
                <li>Visit the search page.</li>
                <li>Choose the criteria for the lodge you're looking for.</li>
                <li>Click the "Search" button.</li>
                <li>When you see the list of results, scroll down to the bottom and click the "Add Lodge" button.</li>
                <li>Fill in the lodge details.</li>
                <li>Provide the meeting information.</li>
                <li>Lastly, enter contact information for the lodge, so someone can guide interested Masons in the right direction.</li>
                <li>Review the information on the confirmation page to ensure it's accurate.</li>
                <li>Submit it for review.</li>
              </ol>
            </span>
          </Col>
        </Row>
        <hr />
        <Row className="pointing-cursor" onClick={() => {
          //this.state.toggleQADivs[2] = !this.state.toggleQADivs[2];
          this.setState({toggleQADiv_3: !this.state.toggleQADiv_3});
        }}>
          <Col sm="12">
            <b>How do I suggest an edit?</b>

            <span className="faq-toggle">
            { this.state.toggleQADiv_3
              ?
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Subtract-1--Streamline-Core" height="14" width="14"><desc>Subtract 1 Streamline Icon: https://streamlinehq.com</desc><g id="subtract-1--button-delete-buttons-subtract-horizontal-remove-line-add-mathematics-math-minus"><path id="Vector 1931 (Stroke)" fill="#8fbffa" fill-rule="evenodd" d="M0 7a1 1 0 0 1 1 -1h12a1 1 0 1 1 0 2H1a1 1 0 0 1 -1 -1Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Add-1--Streamline-Core" height="14" width="14"><desc>Add 1 Streamline Icon: https://streamlinehq.com</desc><g id="add-1--expand-cross-buttons-button-more-remove-plus-add-+-mathematics-math"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M8 1a1 1 0 0 0 -2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0 -2H8V1Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            }
            </span>
          </Col>
        </Row>
        <Row className={`toggle-email-setting-div${
          this.state.toggleQADiv_3
            ? '-open'
            : '-closed'
          }`}>
          <Col sm="12">
            <span className="small-font">
              <ol>
                <li>Visit the lodge details page for the lodge you want to update.</li>
                <li>Click the "Suggest an Edit" button.</li>
                <li>Enter the correct information.</li>
                <li>Review the information on the confirmation page to ensure it's accurate.</li>
                <li>Submit it for review.</li>
              </ol>
            </span>
          </Col>
        </Row>
        <hr />
        <Row className="pointing-cursor" onClick={() => {
          //this.state.toggleQADivs[3] = !this.state.toggleQADivs[3];
          this.setState({toggleQADiv_4: !this.state.toggleQADiv_4});
        }}>
          <Col sm="12">
            <b>How do I vote on a suggestion?</b>

            <span className="faq-toggle">
            { this.state.toggleQADiv_4
              ?
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Subtract-1--Streamline-Core" height="14" width="14"><desc>Subtract 1 Streamline Icon: https://streamlinehq.com</desc><g id="subtract-1--button-delete-buttons-subtract-horizontal-remove-line-add-mathematics-math-minus"><path id="Vector 1931 (Stroke)" fill="#8fbffa" fill-rule="evenodd" d="M0 7a1 1 0 0 1 1 -1h12a1 1 0 1 1 0 2H1a1 1 0 0 1 -1 -1Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Add-1--Streamline-Core" height="14" width="14"><desc>Add 1 Streamline Icon: https://streamlinehq.com</desc><g id="add-1--expand-cross-buttons-button-more-remove-plus-add-+-mathematics-math"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M8 1a1 1 0 0 0 -2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0 -2H8V1Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            }
            </span>
          </Col>
        </Row>
        <Row className={`toggle-email-setting-div${
          this.state.toggleQADiv_4
            ? '-open'
            : '-closed'
          }`}>
          <Col sm="12">
            <span className="small-font">
              <ol>
                <li>Visit the contributions page.</li>
                <li>Choose a suggestion.</li>
                <li>Cast your vote based on what you know to be true.</li>
              </ol>
            </span>
          </Col>
        </Row>
        <hr />
        <Row className="pointing-cursor" onClick={() => {
          //this.state.toggleQADivs[4] = !this.state.toggleQADivs[4];
          this.setState({toggleQADiv_5: !this.state.toggleQADiv_5});
        }}>
          <Col sm="12">
            <b>How do I add myself to a Lodge?</b>

            <span className="faq-toggle">
            { this.state.toggleQADiv_5
              ?
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Subtract-1--Streamline-Core" height="14" width="14"><desc>Subtract 1 Streamline Icon: https://streamlinehq.com</desc><g id="subtract-1--button-delete-buttons-subtract-horizontal-remove-line-add-mathematics-math-minus"><path id="Vector 1931 (Stroke)" fill="#8fbffa" fill-rule="evenodd" d="M0 7a1 1 0 0 1 1 -1h12a1 1 0 1 1 0 2H1a1 1 0 0 1 -1 -1Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Add-1--Streamline-Core" height="14" width="14"><desc>Add 1 Streamline Icon: https://streamlinehq.com</desc><g id="add-1--expand-cross-buttons-button-more-remove-plus-add-+-mathematics-math"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M8 1a1 1 0 0 0 -2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0 -2H8V1Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            }
            </span>
          </Col>
        </Row>
        <Row className={`toggle-email-setting-div${
          this.state.toggleQADiv_5
            ? '-open'
            : '-closed'
          }`}>
          <Col sm="12">
            <span className="small-font">
              <ol>
                <li>Visit the lodge details page for the lodge you want to join.</li>
                <li>Click the "Claim Membership" button.</li>
                <li>Enter your joining information.</li>
                <li>Submit it for review.</li>
              </ol>
            </span>
          </Col>
        </Row>
        <hr />
        <Row className="pointing-cursor" onClick={() => {
          //this.state.toggleQADivs[5] = !this.state.toggleQADivs[5];
          this.setState({toggleQADiv_6: !this.state.toggleQADiv_6});
        }}>
          <Col sm="12">
            <b>How do I remove a member from a Lodge?</b>

            <span className="faq-toggle">
            { this.state.toggleQADiv_6
              ?
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Subtract-1--Streamline-Core" height="14" width="14"><desc>Subtract 1 Streamline Icon: https://streamlinehq.com</desc><g id="subtract-1--button-delete-buttons-subtract-horizontal-remove-line-add-mathematics-math-minus"><path id="Vector 1931 (Stroke)" fill="#8fbffa" fill-rule="evenodd" d="M0 7a1 1 0 0 1 1 -1h12a1 1 0 1 1 0 2H1a1 1 0 0 1 -1 -1Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Add-1--Streamline-Core" height="14" width="14"><desc>Add 1 Streamline Icon: https://streamlinehq.com</desc><g id="add-1--expand-cross-buttons-button-more-remove-plus-add-+-mathematics-math"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M8 1a1 1 0 0 0 -2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0 -2H8V1Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            }
            </span>
          </Col>
        </Row>
        <Row className={`toggle-email-setting-div${
          this.state.toggleQADiv_6
            ? '-open'
            : '-closed'
          }`}>
          <Col sm="12">
            <span className="small-font">
              <ol>
                <li>Go to your profile page.</li>
                <li>Click on the "Membership" tab.</li>
                <li>Next to each member of the lodge, you'll see an icon.</li>
                <li>Click the icon.</li>
                <li>You will be asked to confirm if you want to suggest the removal of the selected member.</li>
                <li>Submit it for review.</li>
              </ol>
            </span>
          </Col>
        </Row>
        <hr />
        <Row className="pointing-cursor" onClick={() => {
          //this.state.toggleQADivs[6] = !this.state.toggleQADivs[6];
          this.setState({toggleQADiv_7: !this.state.toggleQADiv_7});
        }}>
          <Col sm="12">
            <b>How do I add an Emergency Meeting?</b>

            <span className="faq-toggle">
            { this.state.toggleQADiv_7
              ?
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Subtract-1--Streamline-Core" height="14" width="14"><desc>Subtract 1 Streamline Icon: https://streamlinehq.com</desc><g id="subtract-1--button-delete-buttons-subtract-horizontal-remove-line-add-mathematics-math-minus"><path id="Vector 1931 (Stroke)" fill="#8fbffa" fill-rule="evenodd" d="M0 7a1 1 0 0 1 1 -1h12a1 1 0 1 1 0 2H1a1 1 0 0 1 -1 -1Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Add-1--Streamline-Core" height="14" width="14"><desc>Add 1 Streamline Icon: https://streamlinehq.com</desc><g id="add-1--expand-cross-buttons-button-more-remove-plus-add-+-mathematics-math"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M8 1a1 1 0 0 0 -2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0 -2H8V1Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            }
            </span>
          </Col>
        </Row>
        <Row className={`toggle-email-setting-div${
          this.state.toggleQADiv_7
            ? '-open'
            : '-closed'
          }`}>
          <Col sm="12">
            <span className="small-font">
              <ol>
                <li>Visit the lodge details page for the lodge you're interested in.</li>
                <li>Click the "Emergency Meeting" button.</li>
                <li>Enter the meeting information.</li>
                <li>Submit it for review.</li>
              </ol>
            </span>
          </Col>
        </Row>
        <hr />
        <Row className="pointing-cursor" onClick={() => {
          //this.state.toggleQADivs[7] = !this.state.toggleQADivs[7];
          this.setState({toggleQADiv_8: !this.state.toggleQADiv_8});
        }}>
          <Col sm="12">
            <b>How do I remove an Emergency Meeting?</b>

            <span className="faq-toggle">
            { this.state.toggleQADiv_8
              ?
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Subtract-1--Streamline-Core" height="14" width="14"><desc>Subtract 1 Streamline Icon: https://streamlinehq.com</desc><g id="subtract-1--button-delete-buttons-subtract-horizontal-remove-line-add-mathematics-math-minus"><path id="Vector 1931 (Stroke)" fill="#8fbffa" fill-rule="evenodd" d="M0 7a1 1 0 0 1 1 -1h12a1 1 0 1 1 0 2H1a1 1 0 0 1 -1 -1Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Add-1--Streamline-Core" height="14" width="14"><desc>Add 1 Streamline Icon: https://streamlinehq.com</desc><g id="add-1--expand-cross-buttons-button-more-remove-plus-add-+-mathematics-math"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M8 1a1 1 0 0 0 -2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0 -2H8V1Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            }
            </span>
          </Col>
        </Row>
        <Row className={`toggle-email-setting-div${
          this.state.toggleQADiv_8
            ? '-open'
            : '-closed'
          }`}>
          <Col sm="12">
            <span className="small-font">
              <ol>
                <li>Visit the lodge details page for the lodge you're interested in.</li>
                <li>Next to each emergency meeting, you'll see an icon.</li>
                <li>Click the icon.</li>
                <li>You will be asked to confirm if you want to suggest the removal of the selected emergency meeting.</li>
                <li>Submit it for review.</li>
              </ol>
            </span>
          </Col>
        </Row>
        <hr />
        <Row className="pointing-cursor" onClick={() => {
          //this.state.toggleQADiv_9 = !this.state.toggleQADiv_9;
          this.setState({toggleQADiv_9: !this.state.toggleQADiv_9});
        }}>
          <Col sm="12">
            <b>How does it all work?</b>

            <span className="faq-toggle">
            { this.state.toggleQADiv_9
              ?
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Subtract-1--Streamline-Core" height="14" width="14"><desc>Subtract 1 Streamline Icon: https://streamlinehq.com</desc><g id="subtract-1--button-delete-buttons-subtract-horizontal-remove-line-add-mathematics-math-minus"><path id="Vector 1931 (Stroke)" fill="#8fbffa" fill-rule="evenodd" d="M0 7a1 1 0 0 1 1 -1h12a1 1 0 1 1 0 2H1a1 1 0 0 1 -1 -1Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Add-1--Streamline-Core" height="14" width="14"><desc>Add 1 Streamline Icon: https://streamlinehq.com</desc><g id="add-1--expand-cross-buttons-button-more-remove-plus-add-+-mathematics-math"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M8 1a1 1 0 0 0 -2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0 -2H8V1Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            }
            </span>
          </Col>
        </Row>
        <Row className={`toggle-email-setting-div${
          this.state.toggleQADiv_9
            ? '-open'
            : '-closed'
          }`}>
          <Col sm="12">
            <span className="small-font">
              <ol>
                <li>All data in the system is user curated.</li>
                <li>You make suggestions.</li>
                <li>You vote on suggestions.</li>
                <li>Once the voting acceptance threshold has been met, the suggestion becomes live data.</li>
                <li>Or once the voting rejection threshold has been met, the suggestion is removed from the system.</li>
              </ol>
            </span>
          </Col>
        </Row>
        <hr />
        <Row className="pointing-cursor" onClick={() => {
          //this.state.toggleQADivs[9] = !this.state.toggleQADivs[9];
          this.setState({toggleQADiv_10: !this.state.toggleQADiv_10});
        }}>
          <Col sm="12">
            <b>What are suggestions?</b>

            <span className="faq-toggle">
            { this.state.toggleQADiv_10
              ?
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Subtract-1--Streamline-Core" height="14" width="14"><desc>Subtract 1 Streamline Icon: https://streamlinehq.com</desc><g id="subtract-1--button-delete-buttons-subtract-horizontal-remove-line-add-mathematics-math-minus"><path id="Vector 1931 (Stroke)" fill="#8fbffa" fill-rule="evenodd" d="M0 7a1 1 0 0 1 1 -1h12a1 1 0 1 1 0 2H1a1 1 0 0 1 -1 -1Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Add-1--Streamline-Core" height="14" width="14"><desc>Add 1 Streamline Icon: https://streamlinehq.com</desc><g id="add-1--expand-cross-buttons-button-more-remove-plus-add-+-mathematics-math"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M8 1a1 1 0 0 0 -2 0v5H1a1 1 0 0 0 0 2h5v5a1 1 0 1 0 2 0V8h5a1 1 0 1 0 0 -2H8V1Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            }
            </span>
          </Col>
        </Row>
        <Row className={`toggle-email-setting-div${
          this.state.toggleQADiv_10
            ? '-open'
            : '-closed'
          }`}>
          <Col sm="12">
            <span className="small-font">This can be;
              <ul>
                <li>Adding new lodges.</li>
                <li>Changing lodge information.</li>
                <li>Claiming membership to lodges.</li>
                <li>Removing members from lodges.</li>
                <li>Adding emergency meetings.</li>
                <li>Removing emergency meetings.</li>
              </ul>
            </span>
          </Col>
        </Row>
        </CardBody>
        </Card>

        <hr />
        <br />
        <div id='navigation_buttons'>
          <Button color="primary" onClick={this.props.searchStep}>
            Search
          </Button>
          {loginStore.isLoggedIn ? (
          <Button color="primary" onClick={async () => {
            this.props.contributeStore.setEdits(loginStore);
            this.props.contributeStep();
          }}>
            Contribute
          </Button>
          ): ''}
        </div>
      </div>
    );
  }
}

export default FAQs;

import React, { Component } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import ReactLoading from "react-loading";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { slide as Menu } from 'react-burger-menu'
//import Cookies from 'js-cookie';

import { Provider } from 'mobx-react';

import { Search } from './search';
import { searchStore } from '../../stores/search-store';
import { Lodge } from './lodge';
import { lodgeStore } from '../../stores/lodge-store';
import { LodgeDetails } from './lodge-details';
import { lodgeDetailsStore } from '../../stores/lodge-details-store';

import { AddLodge } from './add-lodge';
import { addLodgeStore } from '../../stores/add-lodge-store';
import { AddMeeting } from './add-meeting';
import { addMeetingStore } from '../../stores/add-meeting-store';
import { AddContact } from './add-contact';
import { addContactStore } from '../../stores/add-contact-store';
import { AddConfirmation } from './add-confirmation';
import { addConfirmationStore } from '../../stores/add-confirmation-store';

import { SuggestEditLodge } from './suggest-edit-lodge';
import { suggestEditLodgeStore } from '../../stores/suggest-edit-lodge-store';
import { SuggestEditMeeting } from './suggest-edit-meeting';
import { suggestEditMeetingStore } from '../../stores/suggest-edit-meeting-store';
import { SuggestEditContact } from './suggest-edit-contact';
import { suggestEditContactStore } from '../../stores/suggest-edit-contact-store';
import { SuggestEditConfirmation } from './suggest-edit-confirmation';
import { suggestEditConfirmationStore } from '../../stores/suggest-edit-confirmation-store';

import { Contact } from './contact';
import { contactStore } from '../../stores/contact-store';

import { Contribute } from './contribute';
import { contributeStore } from '../../stores/contribute-store';
import { ContributeDetails } from './contribute-details';
import { contributeDetailsStore } from '../../stores/contribute-details-store';

import { configurationStore } from '../../stores/configuration-store';

import { Profile } from './profile';
import { profileStore } from '../../stores/profile-store';

import { ThankYou } from './thank-you';
import { thankYouStore } from '../../stores/thank-you-store';

import { FAQs } from './faqs';
import { fAQsStore } from '../../stores/faqs-store';

import { Quiz } from './quiz';
import { quizStore } from '../../stores/quiz-store';

import { Achievements } from './achievements';
import { achievementsStore } from '../../stores/achievements-store';

import { Leaderboard } from './leaderboard';
import { leaderboardStore } from '../../stores/leaderboard-store';

import { Feedback } from './feedback';
import { feedbackStore } from '../../stores/feedback-store';

import { Raffle } from './raffle';
import { raffleStore } from '../../stores/raffle-store';
import { RaffleName } from './raffle-name';
import { raffleNameStore } from '../../stores/raffle-name-store';
import { RaffleCode } from './raffle-code';
import { raffleCodeStore } from '../../stores/raffle-code-store';
import { RaffleEntrants } from './raffle-entrants';
import { raffleEntrantsStore } from '../../stores/raffle-entrants-store';
import { RaffleDraw } from './raffle-draw';
import { raffleDrawStore } from '../../stores/raffle-draw-store';

import { About } from './about';
import { aboutStore } from '../../stores/about-store';

import { Diagram } from './diagram';
import { diagramStore } from '../../stores/diagram-store';

import { RegistrationPre } from './registration-pre';
import { registrationPreStore } from '../../stores/registration-pre-store';
import { Registration } from './registration';
import { registrationStore } from '../../stores/registration-store';
import { RegistrationTwo } from './registration-two';
import { registrationTwoStore } from '../../stores/registration-two-store';
import { RegistrationThree } from './registration-three';
import { registrationThreeStore } from '../../stores/registration-three-store';

import { Login } from './login';
import { loginStore } from '../../stores/login-store';

import { ImpromptuMeeting } from './impromptu-meeting';
import { impromptuMeetingStore } from '../../stores/impromptu-meeting-store';
import { ImpromptuMeetingSetup } from './impromptu-meeting-setup';
import { impromptuMeetingSetupStore } from '../../stores/impromptu-meeting-setup-store';
import { ImpromptuMeetingSetupLocation } from './impromptu-meeting-setup-location';
import { impromptuMeetingSetupLocationStore } from '../../stores/impromptu-meeting-setup-location-store';
import { ImpromptuMeetingSetupImage } from './impromptu-meeting-setup-image';
import { impromptuMeetingSetupImageStore } from '../../stores/impromptu-meeting-setup-image-store';
import { ImpromptuMeetingSetupConfirmation } from './impromptu-meeting-setup-confirmation';
import { impromptuMeetingSetupConfirmationStore } from '../../stores/impromptu-meeting-setup-confirmation-store';

import { ResetPasswordEmail } from './reset-password-email';
import { resetPasswordEmailStore } from '../../stores/reset-password-email-store';
import { ResetPasswordConfirm } from './reset-password-confirm';
import { resetPasswordConfirmStore } from '../../stores/reset-password-confirm-store';
import { ResetPasswordNewPassword } from './reset-password-new-password';
import { resetPasswordNewPasswordStore } from '../../stores/reset-password-new-password-store';

import { MembershipClaim } from './membership-claim';
import { membershipClaimStore } from '../../stores/membership-claim-store';

import { EmergencyMeeting } from './emergency-meeting';
import { emergencyMeetingStore } from '../../stores/emergency-meeting-store';

import { LogVisitMeeting } from './log-visit-meeting';
import { logVisitMeetingStore } from '../../stores/log-visit-meeting-store';

import { ViewProfile } from './view-profile';
import { viewProfileStore } from '../../stores/view-profile-store';

import { FeedWall } from './feed-wall';
import { feedWallStore } from '../../stores/feed-wall-store';
import { FeedWallRightPanel } from './feed-wall-right-panel';
import { feedWallRightPanelStore } from '../../stores/feed-wall-right-panel-store';
import { FeedWallLeftPanel } from './feed-wall-left-panel';
import { feedWallLeftPanelStore } from '../../stores/feed-wall-left-panel-store';

import { NewLocation } from './new-location';
import { newLocationStore } from '../../stores/new-location-store';

import { PromotePost } from './promote-post';
import { promotePostStore } from '../../stores/promote-post-store';

import { UserAccount } from './user-account';
import { userAccountStore } from '../../stores/user-account-store';

import { ContributeModal } from '../modals/contribute';
import { UserPostModal } from '../modals/user-post';
import { TermsOfServiceModal } from '../modals/terms-of-service';
import { PrivacyPolicyModal } from '../modals/privacy-policy';

import { policyDocsStore } from '../../stores/policy-docs-store';

import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';


class ProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: props.step
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ step: nextProps.step });
  }




  get percentageDone() {
    const result = Math.floor((this.props.step / (this.props.steps.length - 1)) * 100);
    console.log(result);
    return result > 0 ? result : 0;
  }
  render() {
    return (
      <div className="d-flex justify-content-around">
        {this.props.steps.map((s, i) => (
          <div
            className={
              'progress-step-container' +
              (this.props.step > i ? ' done' : '') +
              (this.props.step === i ? ' active' : '')
            }
            key={i}
          >
            <div className="progress-step">
              <div className="progress-dot" />
              <div className="progress-line" />
            </div>
            <div className="progress-name">{s}</div>
          </div>
        ))}

      </div>
    );
  }
}

class Application extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      busyLoading: false,
      step: (this.handleReferralCode()[0] ? 40 : this.handleConfirmationCode()[0] ? 42 : 49),
      refcode: (this.handleReferralCode()[0] ? this.handleReferralCode()[1] : ''),
      confirmationcode: (this.handleConfirmationCode()[0] ? this.handleConfirmationCode()[1] : ''),
      menuOpen: false,
      data: {},
      t: false
    };

    if (this.state.refcode != "") {
      this.handleClearingQSReferralCode();
    }
    if (this.state.confirmationcode != "") {
      this.handleClearingConfirmationCode();
    }

    //this.setTourIsClosed = this.setTourIsClosed.bind(this);

    window.onbeforeunload = function() { return "Your work will be lost."; };
  }

  forceStateChange() {
    this.setState({ t: !this.state.t });
  }

  // Add a function to handle the referral code logic
  handleReferralCode() {
    const location = window.location; // Get the current location
    const queryParams = new URLSearchParams(location.search);
    const refcode = queryParams.get('refcode');
    const confirmationcode = queryParams.get('confirmation');
    let result;

    // Do something with the refcode, e.g., set it to state or perform some actions
    if (refcode) {
      // Referral code is present, you can set it to state or use it as needed
      console.log('Referral Code:', refcode);
      //this.setState({ step: 40 });
      //this.state.data.refcode = refcode;
      this.setState({ refcode: refcode });
      result = [true,refcode];
    } else {
      // No referral code found in the query string
      console.log('No referral code found.');
      result = [false,''];
    }
    return result;
  };

  // Add a function to handle the confirmation code logic
  handleConfirmationCode() {
    const location = window.location; // Get the current location
    const queryParams = new URLSearchParams(location.search);
    const confirmationcode = queryParams.get('confirmation');
    let result;

    // Do something with the confirmationcode, e.g., set it to state or perform some actions
    if (confirmationcode) {
      // Confirmation code is present, you can set it to state or use it as needed
      console.log('Confirmation Code:', confirmationcode);
      //this.setState({ step: 40 });
      //this.state.data.refcode = refcode;
      this.setState({ confirmationcode: confirmationcode });
      result = [true,confirmationcode];
    } else {
      // No confirmation code found in the query string
      console.log('No confirmation code found.');
      result = [false,''];
    }
    return result;
  };
  

  handleClearingQSReferralCode() {
    const location = window.location; // Get the current location
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('refcode');
    //const newUrl = `${location.pathname}?${queryParams.toString()}`;
    const newUrl = `${location.pathname}`;
    window.history.replaceState({}, '', newUrl);
  };

  handleClearingConfirmationCode() {
    const location = window.location; // Get the current location
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('confirmation');
    //const newUrl = `${location.pathname}?${queryParams.toString()}`;
    const newUrl = `${location.pathname}`;
    window.history.replaceState({}, '', newUrl);
  };


  setData(data) {
    this.setState({ data });
  }
  getStep() {
    return this.state.step;
  }
  previousStep() {
    this.setState({ step: this.state.step - 1 });
  }
  nextStep() {
    this.setState({ step: this.state.step + 1 });
  }
  searchStep() {
    this.setState({ step: 0 });
  }
  lodgesStep() {
    this.setState({ step: 1 });
  }
  lodgeDetailsStep() {
    this.setState({ step: 2 });
  }
  contactStep() {
    this.setState({ step: 3 });
  }
  addLodgeStep() {
    this.setState({ step: 10 });
  }
  suggestEditLodgeStep() {
    this.setState({ step: 20 });
  }
  contributeStep() {
    this.setState({ step: 30 });
  }
  registrationStep() {
    this.setState({ step: 40 });
  }
  loginStep() {
    this.setState({ step: 49 });
  }
  logoutStep() {
    this.setState({ loggedIn: false });
    //this.setState({ step: 0 });
    this.setState({ step: 49 });
  }
  profileStep() {
    //this.setState({ loggedIn: true });
    this.setState({ step: 50 });
  }
  forgotPasswordStep() {
    this.setState({ loggedIn: false });
    this.setState({ step: 60 });
  }
  changePasswordStep() {
    this.setState({ step: 70 });
  }
  membershipClaimStep() {
    this.setState({ step: 80 });
  }
  emergencyMeetingStep() {
    this.setState({ step: 90 });
  }
  thankYouStep() {
    this.setState({ step: 100 });
  }
  fAQsStep() {
    this.setState({ step: 101 });
  }
  quizStep() {
    this.setState({ step: 130 });
  }
  achievementsStep() {
    this.setState({ step: 150 });
  }
  leaderboardStep() {
    this.setState({ step: 140 });
  }
  feedbackStep() {
    this.setState({ step: 102 });
  }
  aboutStep() {
    this.setState({ step: 103 });
  }
  logVisitMeetingStep() {
    this.setState({ step: 104 });
  }
  impromptuMeetingStep() {
    this.setState({ step: 105 });
  }
  impromptuMeetingSetupStep() {
    this.setState({ step: 106 });
  }
  diagramStep() {
    this.setState({ step: 110 });
  }
  raffleStep() {
    this.setState({ step: 120 });
  }
  raffleNameStep() {
    this.setState({ step: 121 });
  }
  raffleCodeStep() {
    this.setState({ step: 122 });
  }
  raffleEntrantsStep() {
    this.setState({ step: 123 });
  }
  raffleDrawStep() {
    this.setState({ step: 124 });
  }
  viewProfileStep() {
    this.setState({ step: 160 });
  }
  feedWallStep() {
    this.setState({ loggedIn: true });
    this.setState({ step: 170 });
  }

  newLocationStep() {
    this.setState({ step: 180 });
  }
  promotePostStep() {
    this.setState({ step: 190 });
  }
  userAccountStep() {
    this.setState({ step: 200 });
  }
  getRefCode() {
    return this.state.refcode;
  }
  getFirstLogInState() {
    return (this.state.step == 43) ? true : false;
  }
  getConfirmationCode() {
    return this.state.confirmationcode;
  }
  closeMenu () {
    document.body.classList.toggle('noscroll', false);
    this.setState({menuOpen: false});
  }
  setLoadingTrue () {
    document.body.classList.toggle('noscroll', true);
    this.setState({busyLoading: true});
  }
  setLoadingFalse () {
    document.body.classList.toggle('noscroll', false);
    this.setState({busyLoading: false});
  }
  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen});
    document.body.classList.toggle('noscroll', state.isOpen);
  }

  renderProgressBar(){
    /*if (this.state.step < 10) {
      return (<ProgressBar
        step={this.state.step}
        steps={[
          'Search',
          'Results',
          'Details',
          'Contact'
        ]}
      />);
    }
    else*/ if (this.state.step >= 10 && this.state.step < 20) {
      return (<ProgressBar
        step={this.state.step - 10}
        steps={[
          'Lodge Details',
          'Meeting Details',
          'Contact Details',
          'Confirm'
        ]}
      />);
    }
    else if (this.state.step >= 20 && this.state.step < 30) {
      return (<ProgressBar
        step={this.state.step - 20}
        steps={[
          'Lodge Details',
          'Meeting Details',
          'Contact Details',
          'Confirm'
        ]}
      />);
    }
    /*else if (this.state.step >= 30 && this.state.step < 40) {
      return (<ProgressBar
        step={this.state.step - 30}
        steps={[
          'List',
          'Details'
        ]}
      />);
    }*/
    else if (this.state.step >= 40 && this.state.step < 49) {
      return (<ProgressBar
        step={this.state.step - 40}
        steps={[
          'Referral Code',
          'Profile Details',
          'Confirm Email',
          'Login'
        ]}
      />);
    }
    else if (this.state.step >= 60 && this.state.step < 70) {
      return (<ProgressBar
        step={this.state.step - 60}
        steps={[
          'Email',
          'Confirmation Code',
          'New Password'
        ]}
      />);
    }
    /*else if (this.state.step >= 121 && this.state.step < 124) {
      return (<ProgressBar
        step={this.state.step - 121}
        steps={[
          'Name',
          'Code',
          'Entrants'
        ]}
      />);
    }*/
    else if (this.state.step >= 106 && this.state.step < 110) {
      return (<ProgressBar
        step={this.state.step - 106}
        steps={[
          'Details',
          'Location',
          'Image',
          'Confirm'
        ]}
      />);
    }
    else {
      return null;
    }
  }

  renderRightFeedPanel() {
    return (this.state.loggedIn == true && this.state.step == 170) ?
    (
      <FeedWallRightPanel
        previousStep={this.previousStep.bind(this)}
        nextStep={this.nextStep.bind(this)}
        searchStep={this.searchStep.bind(this)}
        contributeStep={this.contributeStep.bind(this)}
      />
    )
      : '';
  }

  renderLeftFeedPanel() {
    return (this.state.loggedIn == true && this.state.step == 170) ?
    (
      <FeedWallLeftPanel
        previousStep={this.previousStep.bind(this)}
        nextStep={this.nextStep.bind(this)}
        searchStep={this.searchStep.bind(this)}
        contributeStep={this.contributeStep.bind(this)}
        profileStep={this.profileStep.bind(this)}
        leaderboardStep={this.leaderboardStep.bind(this)}
        viewProfileStep={this.viewProfileStep.bind(this)}
      />
    )
      : '';
  }



  renderStep() {
    switch (this.state.step) {
      case 0:
        return (
          <Search
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            newLocationStep={this.newLocationStep.bind(this)}
            setLoadingTrue={this.setLoadingTrue.bind(this)}
            setLoadingFalse={this.setLoadingFalse.bind(this)}
          />
        );
      case 1:
        return (
          <Lodge
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            addLodgeStep={this.addLodgeStep.bind(this)}
            setLoadingTrue={this.setLoadingTrue.bind(this)}
            setLoadingFalse={this.setLoadingFalse.bind(this)}
          />
        );
      case 2:
        return (
          <LodgeDetails
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            suggestEditLodgeStep={this.suggestEditLodgeStep.bind(this)}
            membershipClaimStep={this.membershipClaimStep.bind(this)}
            emergencyMeetingStep={this.emergencyMeetingStep.bind(this)}
            logVisitMeetingStep={this.logVisitMeetingStep.bind(this)}
          />
        );
      case 3:
        return (
          <Contact
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            lodgeDetailsStep={this.lodgeDetailsStep.bind(this)}
            thankYouStep={this.thankYouStep.bind(this)}
          />
        );
      case 10:// add a lodge process
        return (
          <AddLodge
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            lodgesStep={this.lodgesStep.bind(this)}
          />
        );
      case 11:
        return (
          <AddMeeting
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            lodgesStep={this.lodgesStep.bind(this)}
          />
        );
      case 12:
        return (
          <AddContact
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            lodgesStep={this.lodgesStep.bind(this)}
          />
        );
      case 13:
        return (
          <AddConfirmation
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            lodgesStep={this.lodgesStep.bind(this)}
            thankYouStep={this.thankYouStep.bind(this)}
            setLoadingTrue={this.setLoadingTrue.bind(this)}
            setLoadingFalse={this.setLoadingFalse.bind(this)}
          />
        );
      case 20: // suggested edits process
        return (
          <SuggestEditLodge
            lodgesStep={this.lodgesStep.bind(this)}
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
          />
        );
      case 21:
        return (
          <SuggestEditMeeting
            lodgesStep={this.lodgesStep.bind(this)}
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
          />
        );
      case 22:
        return (
          <SuggestEditContact
            lodgesStep={this.lodgesStep.bind(this)}
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
          />
        );
      case 23:
        return (
          <SuggestEditConfirmation
            lodgesStep={this.lodgesStep.bind(this)}
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            thankYouStep={this.thankYouStep.bind(this)}
            setLoadingTrue={this.setLoadingTrue.bind(this)}
            setLoadingFalse={this.setLoadingFalse.bind(this)}
          />
        );
      case 30: // contribution process - list of suggested edits
        return (
          <Contribute
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            thankYouStep={this.thankYouStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
            setLoadingTrue={this.setLoadingTrue.bind(this)}
            setLoadingFalse={this.setLoadingFalse.bind(this)}
          />
        );
      case 31: // contribution process - details and voting (up|down) suggested edits
        return (
          <ContributeDetails
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            thankYouStep={this.thankYouStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
          />
        );
      case 40: // registration - referral
        return (
          <RegistrationPre
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
            getRefCode={this.getRefCode.bind(this)}
          />
        );
      case 41: // registration - profile details
        return (
          <Registration
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
            thankYouStep={this.thankYouStep.bind(this)}
          />
        );
      /*case 42: // registration - lodge details
        return (
          <RegistrationTwo
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
          />
        );*/
      case 42: // registration - 2FA - enter email code
        return (
          <RegistrationThree
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
            getConfirmationCode={this.getConfirmationCode.bind(this)}
          />
        );
      case 43: // login
        return (
          <Login
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            registrationStep={this.registrationStep.bind(this)}
            profileStep={this.profileStep.bind(this)}
            forgotPasswordStep={this.forgotPasswordStep.bind(this)}
            changePasswordStep={this.changePasswordStep.bind(this)}
            setLoadingTrue={this.setLoadingTrue.bind(this)}
            setLoadingFalse={this.setLoadingFalse.bind(this)}
            getFirstLogInState={this.getFirstLogInState.bind(this)}
            feedWallStep={this.feedWallStep.bind(this)}
          />
        );
      case 49: // login
        return (
          <Login
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            registrationStep={this.registrationStep.bind(this)}
            profileStep={this.profileStep.bind(this)}
            forgotPasswordStep={this.forgotPasswordStep.bind(this)}
            changePasswordStep={this.changePasswordStep.bind(this)}
            setLoadingTrue={this.setLoadingTrue.bind(this)}
            setLoadingFalse={this.setLoadingFalse.bind(this)}
            getFirstLogInState={this.getFirstLogInState.bind(this)}
            feedWallStep={this.feedWallStep.bind(this)}
          />
        );
      case 50: // profile
        return (
          <Profile
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            lodgeDetailsStep={this.lodgeDetailsStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            diagramStep={this.diagramStep.bind(this)}
            achievementsStep={this.achievementsStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            logoutStep={this.logoutStep.bind(this)}
            setLoadingTrue={this.setLoadingTrue.bind(this)}
            setLoadingFalse={this.setLoadingFalse.bind(this)}
            forceStateChange={this.forceStateChange.bind(this)}
          />
        );
      case 60: // forgot password - enter email
        return (
          <ResetPasswordEmail
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            logoutStep={this.logoutStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
          />
        );
      case 61: // forgot password - enter confirmation code
        return (
          <ResetPasswordConfirm
            nextStep={this.nextStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
          />
        );
      /*case 62: // forgot password - enter new password
        return (
          <ResetPasswordNewPassword
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            logoutStep={this.logoutStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
          />
        );*/
      case 70: // change password - enter new password
        return (
          <ResetPasswordNewPassword
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            logoutStep={this.logoutStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
          />
        );
      case 80: // membership claim
        return (
          <MembershipClaim
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            logoutStep={this.logoutStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
            lodgeDetailsStep={this.lodgeDetailsStep.bind(this)}
            thankYouStep={this.thankYouStep.bind(this)}
          />
        );
      case 90: // emergency meeting
        return (
          <EmergencyMeeting
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            logoutStep={this.logoutStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
            lodgeDetailsStep={this.lodgeDetailsStep.bind(this)}
            thankYouStep={this.thankYouStep.bind(this)}
          />
        );
      case 100: // thank you page
        return (
          <ThankYou
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
          />
        );
      case 101: // FAQs page
        return (
          <FAQs
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
          />
        );
      case 102: // Feedback page
        return (
          <Feedback
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            thankYouStep={this.thankYouStep.bind(this)}
          />
        );
      case 103: // About page
        return (
          <About
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
          />
        );
      case 104: // Log Visit meeting
        return (
          <LogVisitMeeting
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            logoutStep={this.logoutStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
            lodgeDetailsStep={this.lodgeDetailsStep.bind(this)}
            thankYouStep={this.thankYouStep.bind(this)}
          />
        );
      case 105: // Impromptu meeting
        return (
          <ImpromptuMeeting
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            logoutStep={this.logoutStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
            lodgeDetailsStep={this.lodgeDetailsStep.bind(this)}
            impromptuMeetingSetupStep={this.impromptuMeetingSetupStep.bind(this)}
            thankYouStep={this.thankYouStep.bind(this)}
            setLoadingTrue={this.setLoadingTrue.bind(this)}
            setLoadingFalse={this.setLoadingFalse.bind(this)}
          />
        );
      case 106: // Impromptu meeting - Details
        return (
          <ImpromptuMeetingSetup
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            logoutStep={this.logoutStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
            lodgeDetailsStep={this.lodgeDetailsStep.bind(this)}
            thankYouStep={this.thankYouStep.bind(this)}
            setLoadingTrue={this.setLoadingTrue.bind(this)}
            setLoadingFalse={this.setLoadingFalse.bind(this)}
          />
        );
      case 107: // Impromptu meeting - Location
        return (
          <ImpromptuMeetingSetupLocation
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            logoutStep={this.logoutStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
            lodgeDetailsStep={this.lodgeDetailsStep.bind(this)}
            thankYouStep={this.thankYouStep.bind(this)}
            setLoadingTrue={this.setLoadingTrue.bind(this)}
            setLoadingFalse={this.setLoadingFalse.bind(this)}
          />
        );
      case 108: // Impromptu meeting - Image
        return (
          <ImpromptuMeetingSetupImage
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            logoutStep={this.logoutStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
            lodgeDetailsStep={this.lodgeDetailsStep.bind(this)}
            thankYouStep={this.thankYouStep.bind(this)}
            setLoadingTrue={this.setLoadingTrue.bind(this)}
            setLoadingFalse={this.setLoadingFalse.bind(this)}
          />
        );
      case 109: // Impromptu meeting - Confirmation
        return (
          <ImpromptuMeetingSetupConfirmation
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            logoutStep={this.logoutStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
            lodgeDetailsStep={this.lodgeDetailsStep.bind(this)}
            thankYouStep={this.thankYouStep.bind(this)}
            setLoadingTrue={this.setLoadingTrue.bind(this)}
            setLoadingFalse={this.setLoadingFalse.bind(this)}
          />
        );
      case 110: // diagram page
        return (
          <Diagram
            previousStep={this.previousStep.bind(this)}
          />
        );
      case 120: // Raffle page
        return (
          <Raffle
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            raffleNameStep={this.raffleNameStep.bind(this)}
          />
        );
      case 121: // Raffle Name page
        return (
          <RaffleName
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            raffleStep={this.raffleStep.bind(this)}
            //raffleCodeStep={this.raffleCodeStep.bind(this)}
            raffleEntrantsStep={this.raffleEntrantsStep.bind(this)}
          />
        );
      case 122: // Raffle Code page
        return (
          <RaffleCode
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            raffleNameStep={this.raffleNameStep.bind(this)}
            raffleEntrantsStep={this.raffleEntrantsStep.bind(this)}
          />
        );
      case 123: // Raffle Entrants page
        return (
          <RaffleEntrants
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            raffleDrawStep={this.raffleDrawStep.bind(this)}
          />
        );
      case 124: // Raffle Draw page
        return (
          <RaffleDraw
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
          />
        );
      case 130: // Quiz page
        return (
          <Quiz
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
          />
        );
      case 140: // Leaderboard page
        return (
          <Leaderboard
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            viewProfileStep={this.viewProfileStep.bind(this)}
          />
        );
      case 150: // Achievements page
        return (
          <Achievements
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
          />
        );
      case 160: // View Users Profile
        return (
          <ViewProfile
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
          />
        );
      case 170: // Feed
        return (
          <FeedWall
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            profileStep={this.profileStep.bind(this)}
            lodgeDetailsStep={this.lodgeDetailsStep.bind(this)}
            impromptuMeetingStep={this.impromptuMeetingStep.bind(this)}
            contactStep={this.contactStep.bind(this)}
            promotePostStep={this.promotePostStep.bind(this)}
            setLoadingTrue={this.setLoadingTrue.bind(this)}
            setLoadingFalse={this.setLoadingFalse.bind(this)}
          />
        );
      case 180: // new location
        return (
          <NewLocation
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            logoutStep={this.logoutStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
            lodgeDetailsStep={this.lodgeDetailsStep.bind(this)}
            thankYouStep={this.thankYouStep.bind(this)}
          />
        );
      case 190: // Promote Post
        return (
          <PromotePost
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
            userAccountStep={this.userAccountStep.bind(this)}
          />
        );
      case 200: // User Account
        return (
          <UserAccount
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            searchStep={this.searchStep.bind(this)}
            contributeStep={this.contributeStep.bind(this)}
          />
        );

      default:
        return <div>No step found.</div>;
    }
  }
  showSettings (event) {
    event.preventDefault();
  }


  render() {
    return (
      <Provider
        searchStore={searchStore}
        lodgeStore={lodgeStore}
        lodgeDetailsStore={lodgeDetailsStore}
        contactStore={contactStore}

        addLodgeStore={addLodgeStore}
        addMeetingStore={addMeetingStore}
        addContactStore={addContactStore}
        addConfirmationStore={addConfirmationStore}

        suggestEditLodgeStore={suggestEditLodgeStore}
        suggestEditMeetingStore={suggestEditMeetingStore}
        suggestEditContactStore={suggestEditContactStore}
        suggestEditConfirmationStore={suggestEditConfirmationStore}

        contributeStore={contributeStore}
        contributeDetailsStore={contributeDetailsStore}

        configurationStore={configurationStore}

        profileStore={profileStore}

        thankYouStore={thankYouStore}

        fAQsStore={fAQsStore}

        quizStore={quizStore}

        achievementsStore={achievementsStore}

        leaderboardStore={leaderboardStore}

        feedbackStore={feedbackStore}

        raffleStore={raffleStore}
        raffleNameStore={raffleNameStore}
        raffleCodeStore={raffleCodeStore}
        raffleEntrantsStore={raffleEntrantsStore}
        raffleDrawStore={raffleDrawStore}

        aboutStore={aboutStore}

        registrationPreStore={registrationPreStore}
        registrationStore={registrationStore}
        registrationTwoStore={registrationTwoStore}
        registrationThreeStore={registrationThreeStore}

        loginStore={loginStore}

        resetPasswordEmailStore={resetPasswordEmailStore}
        resetPasswordConfirmStore={resetPasswordConfirmStore}
        resetPasswordNewPasswordStore={resetPasswordNewPasswordStore}

        membershipClaimStore={membershipClaimStore}

        emergencyMeetingStore={emergencyMeetingStore}

        logVisitMeetingStore={logVisitMeetingStore}

        viewProfileStore={viewProfileStore}

        feedWallStore={feedWallStore}
        feedWallRightPanelStore={feedWallRightPanelStore}
        feedWallLeftPanelStore={feedWallLeftPanelStore}

        diagramStore={diagramStore}

        impromptuMeetingStore={impromptuMeetingStore}
        impromptuMeetingSetupStore={impromptuMeetingSetupStore}
        impromptuMeetingSetupLocationStore={impromptuMeetingSetupLocationStore}
        impromptuMeetingSetupImageStore={impromptuMeetingSetupImageStore}
        impromptuMeetingSetupConfirmationStore={impromptuMeetingSetupConfirmationStore}

        newLocationStore={newLocationStore}

        promotePostStore={promotePostStore}

        userAccountStore={userAccountStore}

        policyDocsStore={policyDocsStore}
      >
        <div>
        {this.state.busyLoading? (
          <div className='loading-screen'>
            <ReactLoading type="bars" color="#fff"
                  height={100} width={50} />
          </div>
        ) : ''}

        {this.state.loggedIn == true ?
        <Menu
           right
           itemListElement="div"
           isOpen={this.state.menuOpen}
           onStateChange={(state) => this.handleStateChange(state)}
        >
          <div className="d-flex justify-content-center">
            <img className='circle' src='logo.png' alt="logo" height="60px" onClick={() => {
                this.closeMenu();
                this.state.loggedIn == true
                  ? this.profileStep()
                  : this.loginStep();
              }}/>
          </div>
          <div className="d-flex justify-content-center">
            <h6>
              <b>
                <a id="contact" className="profile-text bm-item" href="#" onClick={() => {
                    this.closeMenu();
                    this.state.loggedIn == true
                      ? this.profileStep()
                      : this.loginStep();
                  }}>
                  {loginStore.username}
                </a>
              </b>
            </h6>
          </div>
          <hr/>
          {/*<a id="contact" className="bm-item" href="#" onClick={() => {
            this.closeMenu();
            this.state.loggedIn == true
              ? this.profileStep()
              : this.loginStep();
          }}>Profile</a>
          <hr/>*/}
          <a id="home" className="bm-item" href="#" onClick={() => {
            this.closeMenu();
            this.state.loggedIn == true
              ? this.feedWallStep()
              : this.loginStep();
          }}>Feed</a>
          <a id="home" className="bm-item" href="#" onClick={() => {
            this.closeMenu();
            this.state.loggedIn == true
              ? this.searchStep()
              : this.loginStep();
          }}>Search</a>
          <a id="about" className="bm-item" href="#" onClick={async () => {
            this.closeMenu();
            contributeStore.setEdits(loginStore);
            this.contributeStep();
          }}>Contribute</a>
          <hr/>
          <a id="home" className="bm-item" href="#" onClick={async () => {
            this.closeMenu();
            impromptuMeetingStore.setImpromptuMeetings(loginStore);
            this.impromptuMeetingStep();
          }}>Socials</a>
          <a id="home" className="bm-item" href="#" onClick={() => {
            this.closeMenu();
            this.state.loggedIn == true
              ? this.raffleStep()
              : this.loginStep();
          }}>Raffle</a>
          <a id="home" className="bm-item" href="#" onClick={() => {
            this.closeMenu();
            leaderboardStore.setAllLeaderboards(loginStore);
            this.state.loggedIn == true
              ? this.leaderboardStep()
              : this.loginStep();
          }}>Leaderboard</a>
          {/*<a id="home" className="bm-item" href="#" onClick={() => {
            this.closeMenu();
            this.state.loggedIn == true
              ? this.diagramStep()
              : this.loginStep();
          }}>Diagram</a>*/}
          <hr/>
          <a id="home" className="bm-item" href="#" onClick={() => {
            this.closeMenu();
            this.state.loggedIn == true
              ? this.fAQsStep()
              : this.loginStep();
          }}>FAQs</a>
          {/*<Button className="bm-item" color="" onClick={async () => {
            this.closeMenu();
            this.state.loggedIn == true
              ? this.fAQsStep()
              : this.loginStep();
          }}>
            FAQs Button
          </Button>*/}

          <a id="home" className="bm-item" href="#" onClick={() => {
            this.closeMenu();
            this.state.loggedIn == true
              ? this.feedbackStep()
              : this.loginStep();
          }}>Feedback</a>
          <a id="home" className="bm-item" href="#" onClick={() => {
            this.closeMenu();
            this.state.loggedIn == true
              ? this.aboutStep()
              : this.loginStep();
          }}>About</a>
          <hr/>
          <a className="menu-item--small" href="#" onClick={() => {
            this.closeMenu();
            loginStore.setLoggedOutStatus();
            this.logoutStep();
          }}>Logout</a>
        </Menu>
        : ''}

          <nav>
           <a href="/">
             <img src="logo.png" alt="logo" height="30px" />
           </a>
          </nav>


          <Container className="pt-5">
            {this.renderProgressBar()}
            <Row>
              <Col sm="2">
                <div className="feed-wall-left-panel">{this.renderLeftFeedPanel()}</div>
              </Col>
              <Col lg="8">
                <div className="page-container">{this.renderStep()}</div>
                <ContributeModal />
                <UserPostModal />
                <TermsOfServiceModal />
                <PrivacyPolicyModal />
              </Col>
              <Col sm="2">
                <div className="feed-wall-right-panel">{this.renderRightFeedPanel()}</div>
              </Col>
            </Row>
          </Container>

          <div id="footer"className='footer'>
            <a href="https://www.tempelhoffenterprises.com">
              <img alt='logo' src="footer_logo.png" height="40px"/>
            </a>
          </div>
        </div>
        <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />

      </Provider>

    );
  }
}

export default Application;

// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import Tour from 'reactour'
import { observer, inject } from 'mobx-react';

import { LoginStore } from '../../stores/login-store';
import { ProfileStore } from '../../stores/profile-store';
import { FeedWallStore } from '../../stores/feed-wall-store';
import { ThankYouStore } from '../../stores/thank-you-store';
import { ContributeStore } from '../../stores/contribute-store';
import { ConfigurationStore } from '../../stores/configuration-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { PolicyDocsStore } from '../../stores/policy-docs-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { TextInput, PasswordInput } from '../inputs/text';
import { NumberInput } from '../inputs/number';

export const AddToHomeScreenBookmark: React.FC = () => {
  return (
    <div className="readMoreContent">
      <p>
        <h2>How to add the Freemason Travellers Guide (FTG) to the home screen of your smartphone or tablet</h2>
        <p className="small-font">
          You can use FTG on your smartphone or tablet by visiting the <a href="https://freemason-alpha.com">Freemason Travellers Guide</a> URL
          in your web browser app, but you can also add FTG as a web app to your
          home screen for quick access.
        </p>

        <p className="small-font">
          <b>iOS (iPhone, iPad, iPod)</b>
          <br/>
          To add Freemason Travellers Guide as a web app on your iOS device home screen, please follow these steps:
          <ol>
            <li>Open Safari and navigate to the Freemason Travellers Guide URL</li>
            <li>Tap on the share icon in the bottom toolbar.</li>
            <li>When share options appear, tap on 'Add to home screen'.</li>
            <li>Optionally, change the shortcut name</li>
            <li>See the shortcut appear.</li>
          </ol>
        </p>
        <img className="width-height-hundred" src="homescreen-steps-ios.png" />
        <p className="small-font">
          The FTG icon will appear on your home screen like any other app, so you can drag it around and put it where you like.
        </p>

        <p className="small-font">
          <b>Android</b>
          <br/>
          To add BlogIn as a web app on your Android device, please complete the following steps:
          <ol>
          <li>Launch Chrome for Android and navigate to the Freemason Travellers Guide URL</li>
          <li>Tap the Chrome menu button on the top right</li>
          <li>Tap Add to home screen.</li>
          <li>You’ll be able to enter a name for the shortcut.</li>
          <li>Chrome will add it to your home screen.</li>
          </ol>
        </p>
        <img className="width-height-hundred" src="homescreen-steps-android.png" />
        <p className="small-font">
          You will find FTG icon on your home screen and when you tap the icon, Chrome will load the Freemason Travellers Guide as a “web app” so it will get its own entry in the app switcher and won’t have any browser interface getting in the way.
        </p>
        <p className="small-font">
          Other popular Android browsers also offer this feature. For example, Firefox for Android can do this if you tap the menu button, tap the Page option, and tap Add to Home Screen.
        </p>


      </p>
    </div>
  );
};


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  registrationStep: () => void;
  profileStep: () => void;
  loginStep: () => void;
  addLodgeStep: () => void;
  forgotPasswordStep: () => void;
  changePasswordStep: () => void;
  feedWallStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  getFirstLogInState: () => boolean;
  loginStore?: LoginStore;
  thankYouStore?: ThankYouStore;
  contributeStore?: ContributeStore;
  configurationStore?: ConfigurationStore;
  profileStore?: ProfileStore;
  feedWallStore?: FeedWallStore;
  policyDocsStore?: PolicyDocsStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
  isTourOpen: boolean;
  termsOfServiceOpen: boolean;
  privacyPolicyOpen: boolean;
  addToHomeScreenBookMarkOpen: boolean;
  username: string;
  password: string;
}

@inject('loginStore', 'thankYouStore', 'contributeStore', 'configurationStore', 'profileStore', 'feedWallStore', 'policyDocsStore')
@observer
export class Login extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
      isTourOpen: false,
      termsOfServiceOpen: false,
      privacyPolicyOpen: false,
      addToHomeScreenBookMarkOpen: false,
      username: "",
      password: "",
    };
    this.setTourIsClosed = this.setTourIsClosed.bind(this);
  }

  setTourIsOpen() {
    this.setState({ isTourOpen: true });
  }
  setTourIsClosed() {
    this.setState({ isTourOpen: false });
  }


  render() {
    if (!this.props.loginStore) {
      return;
    }

    const loginStore = this.props.loginStore;

    //console.log("------- loginStore.lodgeResults -------");
    //console.log(loginStore.lodgeResults);
    //console.log("---------------------------------------");

    const loginInput = loginStore.loginInput;
    const validationResult = loginStore.validationResult;

    const profileStore = this.props.profileStore;
    const feedWallStore = this.props.feedWallStore;

    const policyDocsStore = this.props.policyDocsStore;

    let firstLogIn = this.props.getFirstLogInState();

    let steps = [
      {
        selector: '.basic-step',
        content: 'You will need a referral code to register an account. Once registered you will be able to login.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.enter-email-step',
        content: 'Enter the email address you used to register your account.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.enter-password-step',
        content: 'Enter the password you set when setting up your account.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.click-login-step',
        content: 'Click login',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.forgot-password-step',
        content: 'If you have forgotten your password, click here to reset it.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.terms-of-service-step',
        content: 'You can find the Terms of Service here.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.privacy-policy-step',
        content: 'You can find the Privacy Policy here.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.basic-step',
        content: 'If you still having issues logging into the system, please contact info@freemason-alpha.com ',
        style: {
          borderRadius: '5px',
        },
      },
      // ...
    ];

    // Define the new step object
    const homeScreenStep = {
      selector: '.add-to-homescreen-step',
      content: 'Finally you might want to save the app to your home screen or bookmark it for easy access.',
      style: {
        borderRadius: '5px',
      }
    };

    if (firstLogIn) {
      steps.push(homeScreenStep);
    }


    return (
      <div className="basic-step">
      <Card>
      <CardBody>
        <Row>
          <Col sm="12">
            <h1>Login</h1>
          </Col>
        </Row>

        <p className="small-font"><i>To see that none but masons are present. 2B1Ask1</i></p>

        <Row>
          <Col className="enter-email-step">
            <TextInput
              id="username"
              label="Email"
              value={this.state.username}
              onChange={(e) => {
                  this.setState({username: e.target.value});
                  loginInput.username = e.target.value;
                }
              }
              invalid={!!validationResult.username}
              validationMessage={validationResult.username}
            />
          </Col>
        </Row>
        <Row>
          <Col className="enter-password-step">
            <PasswordInput
              id="password"
              label="Password"
              value={this.state.password}
              onChange={(e) => {
                  this.setState({password: e.target.value});
                  loginInput.password = e.target.value;
                }
              }
              invalid={!!validationResult.password}
              validationMessage={validationResult.password}
            />
          </Col>
        </Row>


        {firstLogIn ? (
          <div>
            <span className="small-font-italic">
              <p>Congratulations on successfully registering with the Freemason Travellers Guide!</p>
              <p>We're thrilled to welcome you to our community!</p>
              <p><b>To get started, please use the random password you received via email to log in.</b></p>
              <p>Thank you for choosing Freemason Travellers Guide. We look forward to providing you with an exceptional experience!</p>
            </span>

            <div className="row-justified-and-centered-flex">
            <Button className="add-to-homescreen-step" color="" onClick={async () => {
              // Button to display steps to add to home screen
              this.setState({ addToHomeScreenBookMarkOpen: true })
            }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Bookmark--Streamline-Core" height="14" width="14"><desc>Bookmark Streamline Icon: https://streamlinehq.com</desc><g id="bookmark--bookmarks-tags-favorite"><path id="Union" fill="#8fbffa" d="M4 0c-0.39782 0 -0.77936 0.158035 -1.06066 0.43934C2.65804 0.720644 2.5 1.10218 2.5 1.5v12c0 0.2022 0.12182 0.3845 0.30866 0.4619 0.18684 0.0774 0.40189 0.0347 0.54489 -0.1083L7 10.2071l3.6464 3.6465c0.143 0.143 0.3581 0.1857 0.5449 0.1083 0.1869 -0.0774 0.3087 -0.2597 0.3087 -0.4619v-12c0 -0.39783 -0.158 -0.779356 -0.4393 -1.06066C10.7794 0.158035 10.3978 0 10 0H4Z" stroke-width="1"></path></g></svg>
              <span className=""> Add to homescreen</span>
            </Button>
            </div>
            <br/>
          </div>
        ): ''}

        <div id='navigation_buttons'>
          <Button className="click-login-step" color="" disabled={loginStore.isLoading} onClick={async () => {

            this.props.setLoadingTrue();

            toast.info("Verifying credentials");
            loginStore.validate();
            const success = loginStore.checkValidation();
            console.log('----');
            console.log(success);
            console.log('----');
            if (success) {
              await loginStore.loginUser();
              if (loginStore.isLoggedIn) {
                if (loginStore.change_password) {
                  toast.warning("Change your password!");
                  this.props.changePasswordStep();
                }
                else {
                  // load the configurations
                  await this.props.configurationStore.getConfigurations();

                  await profileStore.getUserWatchList(loginStore.miniToken);
                  await profileStore.getUserProfileFeed(loginStore.miniToken);

                  await feedWallStore.getUserFeed(loginStore.miniToken);

                  toast.success("Welcome back!");
                  //this.props.profileStep();
                  this.props.feedWallStep();
                }
              } else {
                //this.props.loginStep();
                //notify();
                toast.error("Incorrect login details!");
              }
            }
            this.props.setLoadingFalse();
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Login-1--Streamline-Core"><desc>Login 1 Streamline Icon: https://streamlinehq.com</desc><g id="login-1--arrow-enter-frame-left-login-point-rectangle"><path id="Subtract" fill="#8fbffa" fill-rule="evenodd" d="M1.5 0A1.5 1.5 0 0 0 0 1.5v11A1.5 1.5 0 0 0 1.5 14h7a1.5 1.5 0 0 0 1.5 -1.5V9.25h-0.516a2 2 0 0 1 -3.398 1.164l-2 -2a2 2 0 0 1 0 -2.828l2 -2A2 2 0 0 1 9.484 4.75H10V1.5A1.5 1.5 0 0 0 8.5 0h-7Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union" fill="#2859c5" fill-rule="evenodd" d="M8.25 5a0.75 0.75 0 0 0 -1.28 -0.53l-2 2a0.75 0.75 0 0 0 0 1.06l2 2A0.75 0.75 0 0 0 8.25 9V8H13a1 1 0 1 0 0 -2H8.25V5Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            Login
          </Button>

          <Button className="forgot-password-step" color="" onClick={async () => {
              //toast.error("Forgot your password!");
              this.props.forgotPasswordStep();
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Key--Streamline-Core"><desc>Key Streamline Icon: https://streamlinehq.com</desc><g id="key--entry-key-lock-login-pass-unlock-access"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M10.293 1.293a1 1 0 0 1 1.414 0l2 2a1 1 0 0 1 -1.414 1.414L11 3.414 9.914 4.5l1.293 1.293a1 1 0 0 1 -1.414 1.414L8.5 5.914 7.446 6.968a4 4 0 1 1 -1.414 -1.414l1.757 -1.757 0.004 -0.004 0.004 -0.004 2.496 -2.496ZM2 9a2 2 0 1 1 4 0 2 2 0 0 1 -4 0Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            Forgot Password
          </Button>

          <Button color="" onClick={async () => {
            this.setTourIsOpen();
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Help-Question-1--Streamline-Core" height="14" width="14"><desc>Help Question 1 Streamline Icon: https://streamlinehq.com</desc><g id="help-question-1--circle-faq-frame-help-info-mark-more-query-question"><path id="Ellipse 373" fill="#8fbffa" d="M0 7a7 7 0 1 0 14 0A7 7 0 1 0 0 7" stroke-width="1"></path><g id="Union"><path fill="#2859c5" d="M5.75 5.25A1.25 1.25 0 1 1 7 6.5a0.75 0.75 0 0 0 -0.75 0.75v0.646a0.75 0.75 0 1 0 1.5 0 2.751 2.751 0 1 0 -3.5 -2.646 0.75 0.75 0 0 0 1.5 0Z" stroke-width="1"></path></g><g id="Union_2"><path fill="#2859c5" d="M7 11.5a1 1 0 1 0 0 -2 1 1 0 0 0 0 2Z" stroke-width="1"></path></g></g></svg>
            <span className=""> Help</span>
          </Button>
        </div>
        <br/>
        <div className="row-justified-and-centered small-font">
          <span className="policy-docs terms-of-service-step" onClick={() => {
            policyDocsStore.toggleTermsModal();
            this.setState({termsOfServiceOpen: true});
            this.forceUpdate();
          }}>Terms of Service</span>
          <span> * </span>
          <span className="policy-docs privacy-policy-step" onClick={() => {
            policyDocsStore.togglePrivacyModal();
            this.setState({privacyPolicyOpen: true});
            this.forceUpdate();
          }}>Privacy Policy</span>
        </div>
        </CardBody>
        </Card>

        <Modal isOpen={this.state.addToHomeScreenBookMarkOpen}>
          <ModalHeader
            toggle={() => this.setState({ addToHomeScreenBookMarkOpen: false })}
          ></ModalHeader>
          <ModalBody>
            <AddToHomeScreenBookmark />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => this.setState({ addToHomeScreenBookMarkOpen: false })}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <Tour
        steps={steps}
        isOpen={this.state.isTourOpen}
        onRequestClose={this.setTourIsClosed}
        showNavigation={false}
        />

      </div>
    );
  }
}

export default Login;

import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';


const ProfileSponsoredPosts = ({ sponsoredPosts, cancelSponsoredPost, getDescription }) => {
  return (
    <div>
      Sponsored Posts
      <div>
        {sponsoredPosts.map((advert, index) => (
          <Card key={advert.public_id} className='post-option-active'>
            <CardBody>
              <Row>
                <Col sm="6">
                  <b>{advert.title}</b>
                  <br />
                  {
                    advert.description.length > 100
                    ? <span id={`sponsored_post_description_cut_${index}`} className='show_post_description'>
                        {advert.description.substring(0, 200) + "..."}
                        <Button className="small-font" color="#007bff" onClick={() => getDescription('sponsored_post_', index)}>
                          see more
                        </Button>
                      </span>
                    : ''
                  }
                  <span id={`sponsored_post_description_full_${index}`} className={`${
                    advert.description.length < 100
                      ? 'show_post_description pre pre-code'
                      : 'hide_post_description pre pre-code'
                    }`}>
                    {advert.description}
                  </span>
                  <br />
                  <img className="feed-wall-image" src={process.env.REACT_APP_API_URL + '/sponsors/' + advert.image} alt={advert.title} />
                </Col>
                <Col sm="6">
                  <span className='small-font-italic'>
                    Started: {advert.started}
                    <br />
                    Ends: {advert.ends}
                    <br />
                    Running for: {advert.duration}
                    <br />
                    Total views: {advert.all_views}
                    <br />
                    Total clicks: {advert.all_clicks}
                    <br />
                    Click Through Rate (CTR): {advert.click_through_rate.toFixed(2)}%
                    <br />
                    Spent: {advert.cost} of Initial budget: {advert.budget}
                    <br />
                    Remaining Budget: {advert.active ? advert.remaining_budget : '0.00'}
                    <br />
                    Avg views pd: {advert.avg_views_per_day}
                    <br />
                    Avg clicks pd: {advert.avg_clicks_per_day}
                    <br />
                    Stats updated at: {advert.stats_updated_at}
                    <br />
                    Active: {advert.active ? 'Active' : 'Not active'}
                  </span>
                </Col>
              </Row>
              { advert.active ?
                <Row>
                  <Col sm="12">
                    <Button className="small-font" onClick={async () => {
                      if (window.confirm('You are sure you want to cancel this sponsored post!')) {
                        cancelSponsoredPost(advert.public_id);
                      }
                    }}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
                : ''
              }
            </CardBody>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default ProfileSponsoredPosts;

// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import InfiniteScroll from "react-infinite-scroll-component";
import Tour from 'reactour'

import {
  degreeFromId,
  degreeLogoFromId,
  orderLogoFromConstitutionAndOrder,
  checkIfFuture,
  checkIfMeetingNotLogged,
  constructWAShareLink
} from '../../helpers/utilities';

import { FeedWallStore } from '../../stores/feed-wall-store';
import { FeedWallRightPanelStore } from '../../stores/feed-wall-right-panel-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { ImpromptuMeetingStore } from '../../stores/impromptu-meeting-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { LoginStore } from '../../stores/login-store';
import { LogVisitMeetingStore } from '../../stores/log-visit-meeting-store';
import { ContactStore } from '../../stores/contact-store';
import { ProfileStore } from '../../stores/profile-store';
import { PromotePostStore } from '../../stores/promote-post-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput } from '../inputs/text';

export const PromotedPost: React.FC = () => {
  return (
    <div className="readMoreContent">
      <p>
        <h2>Promoted Posts</h2>
        <p className="small-font">
          Enhance Your Content's Visibility with Promoted Posts! Looking to ensure
          that your message stands out in the crowd? Look no further. Promoted Posts
          offer a powerful tool to boost the visibility of your content. Simply flag a
          meeting, social or one of your own posts, and watch it take center stage,
          captivating users as they engage with the platform. With Promoted Posts,
          you wield the ability to elevate your content's impact effortlessly.
          Join us in unlocking the full potential of your posts today!
        </p>

        <p className="small-font">
        All you have to do is click the <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Announcement-Megaphone--Streamline-Core" height="14" width="14"><desc>Announcement Megaphone Streamline Icon: https://streamlinehq.com</desc><g id="annoncement-megaphone"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M7.615 3.497a0.5 0.5 0 1 0 -0.866 0.5l0.018 0.032 -6.413 6.86a0.5 0.5 0 0 0 -0.068 0.592l0.609 1.055a0.5 0.5 0 0 0 0.546 0.237l1.708 -0.397 0.338 0.586 0.001 0.002A1.921 1.921 0 0 0 7 11.482l3.59 -0.834 0.017 0.03a0.5 0.5 0 1 0 0.866 -0.5l-0.188 -0.326a0.497 0.497 0 0 0 -0.028 -0.049l-3.424 -5.93a0.497 0.497 0 0 0 -0.028 -0.047l-0.19 -0.33Zm-1.592 8.212 -1.856 0.43 0.185 0.322a0.921 0.921 0 0 0 1.671 -0.752Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_2" fill="#2859c5" fill-rule="evenodd" d="M7.181 0a0.75 0.75 0 0 1 0.75 0.75v1.253a0.75 0.75 0 0 1 -1.5 0V0.75a0.75 0.75 0 0 1 0.75 -0.75Zm6.806 6.806a0.75 0.75 0 0 1 -0.75 0.75h-1.253a0.75 0.75 0 0 1 0 -1.5h1.253a0.75 0.75 0 0 1 0.75 0.75Zm-11.608 0.75a0.75 0.75 0 1 0 0 -1.5H1.126a0.75 0.75 0 1 0 0 1.5h1.253ZM4.315 3.94a0.75 0.75 0 0 1 -1.06 0l-0.887 -0.886a0.75 0.75 0 1 1 1.06 -1.06l0.887 0.886a0.75 0.75 0 0 1 0 1.06Zm7.679 -0.886a0.75 0.75 0 0 0 -1.06 -1.06l-0.887 0.886a0.75 0.75 0 0 0 1.06 1.06l0.887 -0.886Z" clip-rule="evenodd" stroke-width="1"></path></g></svg> icon
        on the post you would like to promote and follow the instructions presented. <b>It's as easy as that!</b>
        </p>
      </p>
    </div>
  );
};

export const SponsoredPost: React.FC = () => {
  return (
    <div className="readMoreContent">
      <p>
        <h2>Sponsored Posts</h2>
        <p className="small-font">
          Elevate your brand's presence with Sponsored Posts! Craft compelling content
          that captivates and resonates with your audience, then let us do the rest.
          Sponsored Posts seamlessly blend into users' feeds, delivering your
          message in an authentic and engaging way. Collaborate with us to create
          content that speaks directly to your target demographic, ensuring maximum
          impact and relevance. Whether it's driving brand awareness, generating
          leads, or sparking conversation, Sponsored Posts are your secret weapon
          for standing out in the digital crowd. Get ready to make waves and
          unlock new possibilities for your brand with Sponsored Posts. Let's
          elevate your brand together!
        </p>
      </p>
    </div>
  );
};

interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  profileStep: () => void;
  lodgeDetailsStep: () => void;
  impromptuMeetingStep: () => void;
  contactStep: () => void;
  promotePostStep: () => void;
  leaderboardStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  feedWallStore?: FeedWallStore;
  feedWallRightPanelStore?: FeedWallRightPanelStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
  lodgeDetailsStore?: LodgeDetailsStore;
  impromptuMeetingStore?: ImpromptuMeetingStore;
  logVisitMeetingStore?: LogVisitMeetingStore;
  contactStore?: ContactStore;
  profileStore?: ProfileStore;
  promotePostStore?: PromotePostStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
  promotedPostOpen: boolean;
  sponsoredPostOpen: boolean;
  isTourOpen: boolean;
  userFeedUpcomingMeetings: any; // change this
}

@inject(
  'feedWallStore',
  'contributeStore',
  'loginStore',
  'lodgeDetailsStore',
  'impromptuMeetingStore',
  'logVisitMeetingStore',
  'contactStore',
  'feedWallRightPanelStore',
  'profileStore',
  'promotePostStore'
)
@observer
export class FeedWall extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
      promotedPostOpen: false,
      sponsoredPostOpen: false,
      isTourOpen: false,
      userFeedUpcomingMeetings: []//this.props.feedWallStore.userFeedUpcomingMeetings
    };
    this.setTourIsClosed = this.setTourIsClosed.bind(this);
  }
  
  componentDidMount() {
    this.setState({userFeedUpcomingMeetings: this.props.feedWallStore.userFeedUpcomingMeetings});
  }

  getDescription = (id) => {
    document.getElementById('meeting_description_cut_'+id).style.display = 'none';
    document.getElementById('meeting_description_full_'+id).style.display = 'inherit';
    document.getElementById('meeting_image_'+id).classList.remove("post-restricted-height");
  }

  // as you scroll down more data is fetched for the Infinite Scroll
  fetchMoreData = async () => {
    let t = await this.props.feedWallStore.getUserFeedWallOnly(this.props.loginStore.miniToken);
    this.setState({userFeedUpcomingMeetings: t});
  };

  reDir = (website) => {
    window.open(website, '_blank');
  }

  setTourIsOpen() {
    this.setState({ isTourOpen: true });
  }
  setTourIsClosed() {
    this.setState({ isTourOpen: false });
  }



  /*checkMobile(left_steps, general_steps, right_steps) {
    if (document.getElementById("search_nav_button_text") !== null && document.getElementById("search_nav_button_text").style.display !== 'none') {
  // Your logic
//}
    //if (document.getElementById("search_nav_button_text").style['display'] != 'none') {
      alert("shown");
      return left_steps.concat(general_steps).concat(right_steps);
    } else {
      alert("hidden");
      return general_steps;
    }
  }*/

  checkMobile(left_steps, general_steps, right_steps) {
    // Get the element
    var searchNavButton = document.getElementById("search_nav_button_text");

    // Check if the element exists and is visible
    if (searchNavButton && window.getComputedStyle(searchNavButton).display !== 'none') {
      //alert("shown");
      return left_steps.concat(general_steps).concat(right_steps);
    } else {
      //alert("hidden");
      return general_steps;
    }
  }


  render() {
    if (!this.props.feedWallStore) {
      return;
    }
    const loginStore = this.props.loginStore;
    const feedWallStore = this.props.feedWallStore;
    const feedWallRightPanelStore = this.props.feedWallRightPanelStore;

    const logVisitMeetingStore = this.props.logVisitMeetingStore;
    const contactStore = this.props.contactStore;
    const impromptuMeetingStore = this.props.impromptuMeetingStore;

    const promotePostStore = this.props.promotePostStore;

    const profileStore = this.props.profileStore;

    

    

    const left_steps = [
      {
        selector: '.profile-navigate-step',
        content: 'Click to navigate to your profile section.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.your-contributions-step',
        content: 'Quick view of your contributions.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.top-three-leaderboard-step',
        content: 'Top 3 in the leaderboard. Click to navigate to the Leaderboard.',
        style: {
          borderRadius: '5px',
        },
      },
      // ...
    ];

    const right_steps = [
      {
        selector: '.sponsored-post-step',
        content: 'This is a Sponsored Post, if you to would to advertise within the platform please contact advertise@freemason-alpha.com and we will send you all the details.',
        style: {
          borderRadius: '5px',
        },
      },
      // ...
    ];

    const general_steps = [
      {
        selector: '.navigation-toolbar-step',
        content: 'Navigation toolbar.',
        style: {
          borderRadius: '5px',
        },


      },
      {
        selector: '.search-step',
        content: 'Find a lodge.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.contributions-step',
        content: 'Help vet and confirm suggestions made in the system.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.socials-step',
        content: 'Find list of all the social meetings.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.your-post-step',
        content: 'Create a post to let everyone knows what\'s happening.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.your-feed-step',
        content: 'Latest posts, meetings, socials and content.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.feed-post-log-attendance-step',
        content: 'Log you visit or your intended attendance for this meeting.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.feed-post-contact-step',
        content: 'Send a message to the lodge contact.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.feed-post-share-step',
        content: 'Share this event or meeting via Whats App.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.feed-post-promote-step',
        content: 'You can promote a post by clicking this icon and following the steps.',
        style: {
          borderRadius: '5px',
        },
      },
      // ...
    ];

    const steps = this.checkMobile(left_steps, general_steps, right_steps);
    //const full_steps = left_steps.concat(general_steps).concat(right_steps);
    //const mobile_steps = general_steps;

    return (
      <div className="page-container-feed-modification">

      <Card className="post-option-active">
      <CardBody className="top-buttons">
        <Row className="row-justified-and-centered navigation-toolbar-step">
          <Col>
            <div id='navigation_buttons'>
              <Button className="search-step" color="" onClick={this.props.searchStep}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Definition-Search-Book--Streamline-Core"><desc>Definition Search Book Streamline Icon: https://streamlinehq.com</desc><g id="definition-search-book"><path id="Subtract" fill="#8fbffa" fill-rule="evenodd" d="M0.5 0.125a0.5 0.5 0 0 0 -0.5 0.5v10a0.5 0.5 0 0 0 0.5 0.5h4.756A4.492 4.492 0 0 1 5 9.625c0 -1.271 0.527 -2.42 1.375 -3.238v-4.96A2 2 0 0 0 4.5 0.126h-4Zm7.125 1.303v4.105a4.5 4.5 0 0 1 6.347 3.584H14V0.625a0.5 0.5 0 0 0 -0.5 -0.5h-4a2 2 0 0 0 -1.875 1.303Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union" fill="#2859c5" fill-rule="evenodd" d="M7.75 9.625a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1 -3.5 0Zm1.75 -3.25a3.25 3.25 0 1 0 1.706 6.017l1.263 1.263a0.75 0.75 0 0 0 1.06 -1.06l-1.262 -1.264A3.25 3.25 0 0 0 9.5 6.375Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
                <span id="search_nav_button_text" className="feed-button-text"> Search</span>
              </Button>
              {loginStore.isLoggedIn ? (
              <Button className="contributions-step" color="" onClick={async () => {
                this.props.contributeStore.setEdits(loginStore);
                this.props.contributeStep();
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Application-Add--Streamline-Core"><desc>Application Add Streamline Icon: https://streamlinehq.com</desc><g id="application-add--application-new-add-square"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M4.846 0h-3.77C0.483 0 0 0.482 0 1.077v3.77c0 0.594 0.482 1.076 1.077 1.076h3.77c0.594 0 1.076 -0.482 1.076 -1.077v-3.77C5.923 0.483 5.441 0 4.846 0Zm8.077 8.077h-3.77c-0.594 0 -1.076 0.482 -1.076 1.077v3.77c0 0.594 0.482 1.076 1.077 1.076h3.77c0.594 0 1.076 -0.482 1.076 -1.077v-3.77c0 -0.594 -0.482 -1.076 -1.077 -1.076Zm-11.846 0h3.77c0.594 0 1.076 0.482 1.076 1.077v3.77c0 0.594 -0.482 1.076 -1.077 1.076h-3.77A1.077 1.077 0 0 1 0 12.923v-3.77c0 -0.594 0.482 -1.076 1.077 -1.076Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_2" fill="#2859c5" fill-rule="evenodd" d="M11.51 0.75a0.75 0.75 0 1 0 -1.5 0v1.733H8.279a0.75 0.75 0 1 0 0 1.5h1.733v1.732a0.75 0.75 0 0 0 1.5 0V3.983h1.732a0.75 0.75 0 1 0 0 -1.5h-1.732V0.75Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
                <span className="feed-button-text contributions-step"> Contribute</span>
              </Button>
              ): ''}
              <Button className="socials-step" color="" onClick={async () => {
                await impromptuMeetingStore.setImpromptuMeetings(loginStore);
                this.props.impromptuMeetingStep();
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Cocktail--Streamline-Core"><desc>Cocktail Streamline Icon: https://streamlinehq.com</desc><g id="cocktail--cook-alcohol-food-cocktail-drink-cooking-nutrition-alcoholic-beverage-glass"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M2.536 0.026a1.447 1.447 0 0 0 -1.138 2.342L6.25 8.543v3.946H4a0.75 0.75 0 0 0 0 1.5h6a0.75 0.75 0 0 0 0 -1.5H7.75V8.543l4.852 -6.175A1.447 1.447 0 0 0 11.464 0.026H2.536Z" clip-rule="evenodd" stroke-width="1"></path><path id="Subtract" fill="#2859c5" fill-rule="evenodd" d="M10.197 3H3.803l2.214 2.82a1.25 1.25 0 0 0 1.966 0L10.197 3Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
                <span className="feed-button-text"> Socials</span>
              </Button>
              <Button color="" onClick={async () => {
                this.setTourIsOpen();
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Help-Question-1--Streamline-Core" height="14" width="14"><desc>Help Question 1 Streamline Icon: https://streamlinehq.com</desc><g id="help-question-1--circle-faq-frame-help-info-mark-more-query-question"><path id="Ellipse 373" fill="#8fbffa" d="M0 7a7 7 0 1 0 14 0A7 7 0 1 0 0 7" stroke-width="1"></path><g id="Union"><path fill="#2859c5" d="M5.75 5.25A1.25 1.25 0 1 1 7 6.5a0.75 0.75 0 0 0 -0.75 0.75v0.646a0.75 0.75 0 1 0 1.5 0 2.751 2.751 0 1 0 -3.5 -2.646 0.75 0.75 0 0 0 1.5 0Z" stroke-width="1"></path></g><g id="Union_2"><path fill="#2859c5" d="M7 11.5a1 1 0 1 0 0 -2 1 1 0 0 0 0 2Z" stroke-width="1"></path></g></g></svg>
                <span className="feed-button-text"> Help</span>
              </Button>
            </div>
          </Col>
        </Row>
      </CardBody>
      </Card>

      <div className="your-post-step">
        <Card className="post-option-active">
        <CardBody>
          <Row>
            <Col className="user-post-box">
            <img className="user-post-box-image" src="logo.png" alt="logo" height="30px" onClick={() => {
               //this.props.profileStep();
             }}/>
            <div className="user-post-box-text-field" onClick={ () => {
              profileStore.toggleUserPostModal();
              //this.setState({userPostOpen: true});
            }}>
              <span>What's on your mind, {loginStore.username}?</span>
            </div>
            </Col>
          </Row>
        </CardBody>
        </Card>
      </div>

        <div>
        {this.state.userFeedUpcomingMeetings.length > 0 ? (
          <div>
          {this.state.userFeedUpcomingMeetings.map((lodge_meeting, index) => (
            //<Card className="post-option-active">
            <Card className={`post-option-active ${
              lodge_meeting.sponsored && index == 0
                ? 'sponsor-ad'
                : ''
              }`}>
            <CardBody className={`card-padding-override ${
              lodge_meeting.post_type == 1
                ? 'your-feed-step'
                : ''
              }`}>
              {
                lodge_meeting.sponsored || lodge_meeting.promoted ?
                  <div className="pointing-cursor promoted-sponsored-info-button" onClick={() =>
                    lodge_meeting.sponsored ?
                      this.setState({ sponsoredPostOpen: true })
                    :
                    lodge_meeting.promoted ?
                      this.setState({ promotedPostOpen: true })
                    :
                    ''
                  }>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Information-Circle--Streamline-Core" height="14" width="14"><desc>Information Circle Streamline Icon: https://streamlinehq.com</desc><g id="information-circle--information-frame-info-more-help-point-circle"><path id="Ellipse 644" fill="#8fbffa" d="M0 7a7 7 0 1 0 14 0A7 7 0 1 0 0 7" stroke-width="1"></path><path id="Union" fill="#2859c5" fill-rule="evenodd" d="M8 4a1 1 0 1 1 -2 0 1 1 0 0 1 2 0Zm-3.125 6c0 -0.345 0.28 -0.625 0.625 -0.625h0.875v-2.25H6a0.625 0.625 0 1 1 0 -1.25h1c0.345 0 0.625 0.28 0.625 0.625v2.875H8.5a0.625 0.625 0 1 1 0 1.25h-3A0.625 0.625 0 0 1 4.875 10Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
                  </div>
                :
                (lodge_meeting.post_type == 1 || lodge_meeting.post_type == 2) && lodge_meeting.promoted == false
                  ?
                  <div className="pointing-cursor promoted-sponsored-info-button feed-post-promote-step" onClick={async () => {
                      if (window.confirm('Are you sure you want to promote this meeting?')) {

                        promotePostStore.setPostData(lodge_meeting);
                        this.props.promotePostStep();
                      }
                    }}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Announcement-Megaphone--Streamline-Core" height="14" width="14"><desc>Announcement Megaphone Streamline Icon: https://streamlinehq.com</desc><g id="annoncement-megaphone"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M7.615 3.497a0.5 0.5 0 1 0 -0.866 0.5l0.018 0.032 -6.413 6.86a0.5 0.5 0 0 0 -0.068 0.592l0.609 1.055a0.5 0.5 0 0 0 0.546 0.237l1.708 -0.397 0.338 0.586 0.001 0.002A1.921 1.921 0 0 0 7 11.482l3.59 -0.834 0.017 0.03a0.5 0.5 0 1 0 0.866 -0.5l-0.188 -0.326a0.497 0.497 0 0 0 -0.028 -0.049l-3.424 -5.93a0.497 0.497 0 0 0 -0.028 -0.047l-0.19 -0.33Zm-1.592 8.212 -1.856 0.43 0.185 0.322a0.921 0.921 0 0 0 1.671 -0.752Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_2" fill="#2859c5" fill-rule="evenodd" d="M7.181 0a0.75 0.75 0 0 1 0.75 0.75v1.253a0.75 0.75 0 0 1 -1.5 0V0.75a0.75 0.75 0 0 1 0.75 -0.75Zm6.806 6.806a0.75 0.75 0 0 1 -0.75 0.75h-1.253a0.75 0.75 0 0 1 0 -1.5h1.253a0.75 0.75 0 0 1 0.75 0.75Zm-11.608 0.75a0.75 0.75 0 1 0 0 -1.5H1.126a0.75 0.75 0 1 0 0 1.5h1.253ZM4.315 3.94a0.75 0.75 0 0 1 -1.06 0l-0.887 -0.886a0.75 0.75 0 1 1 1.06 -1.06l0.887 0.886a0.75 0.75 0 0 1 0 1.06Zm7.679 -0.886a0.75 0.75 0 0 0 -1.06 -1.06l-0.887 0.886a0.75 0.75 0 0 0 1.06 1.06l0.887 -0.886Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
                  </div>
                : ''
              }
            <Row>
              <Col className="col-sm-1 post_header_logo">
                <img alt='logo' src={`${lodge_meeting.lodge_constitution != '' ? orderLogoFromConstitutionAndOrder(lodge_meeting.lodge_constitution, lodge_meeting.lodge_degree) : "logo.png"}`} height="40px"
                onClick={async () => {
                  //const success = true;//await quoteStore.getQuote();
                  //if (success) {
                  //  this.props.nextStep()
                  //}
                  //this.props.lodgeDetailsStore.setSelectedLodgeName(lodge.name);
                  this.props.setLoadingTrue();

                  if (lodge_meeting.post_type == 1) {
                    this.props.lodgeDetailsStore.clearLodgeInfo();
                    await this.props.lodgeDetailsStore.setLodgeInfo(lodge_meeting.lodge_name, lodge_meeting.lodge_number, lodge_meeting.lodge_degree, loginStore.miniToken, loginStore.lodges);
                    this.props.lodgeDetailsStep();
                  }
                  else {
                    this.props.impromptuMeetingStore.setImpromptuMeetings(loginStore);
                    this.props.impromptuMeetingStep();
                  }

                  this.props.setLoadingFalse();
                }}/>
              </Col>
              <Col className="col-sm-11 post_header_title pointing-cursor">
              <span className="" onClick={async () => {
                if(lodge_meeting.sponsored) {
                  let res = await feedWallRightPanelStore.sponsoredPostImpression(loginStore.miniToken, lodge_meeting.id);
                  if(res) {
                    this.reDir(lodge_meeting.website);
                  }
                }
                else {
                  //const success = true;//await quoteStore.getQuote();
                  //if (success) {
                  //  this.props.nextStep()
                  //}
                  //this.props.lodgeDetailsStore.setSelectedLodgeName(lodge.name);
                  this.props.setLoadingTrue();

                  if (lodge_meeting.post_type == 1) {
                    this.props.lodgeDetailsStore.clearLodgeInfo();
                    await this.props.lodgeDetailsStore.setLodgeInfo(lodge_meeting.lodge_name, lodge_meeting.lodge_number, lodge_meeting.lodge_degree, loginStore.miniToken, loginStore.lodges);
                    this.props.lodgeDetailsStep();
                  }
                  else {
                    this.props.impromptuMeetingStore.setImpromptuMeetings(loginStore);
                    this.props.impromptuMeetingStep();
                  }

                  this.props.setLoadingFalse();
                }
              }}>
                {lodge_meeting.title}
              </span>
              <br/>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Circle-Clock--Streamline-Core"><desc>Circle Clock Streamline Icon: https://streamlinehq.com</desc><g id="circle-clock--clock-loading-measure-time-circle"><path id="Ellipse 548" fill="#8fbffa" d="M0 7a7 7 0 1 0 14 0A7 7 0 1 0 0 7" stroke-width="1"></path><path id="Vector 1528 (Stroke)" fill="#2859c5" fill-rule="evenodd" d="M7 3.875c0.345 0 0.625 0.28 0.625 0.625v2.274L9.98 9.6a0.625 0.625 0 1 1 -0.96 0.8l-2.5 -3a0.625 0.625 0 0 1 -0.145 -0.4V4.5c0 -0.345 0.28 -0.625 0.625 -0.625Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
              <span className='x-small-font'> {lodge_meeting.date_time} </span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Earth-1--Streamline-Core"><desc>Earth 1 Streamline Icon: https://streamlinehq.com</desc><g id="earth-1--planet-earth-globe-world"><path id="Vector" fill="#8fbffa" d="M7 14A7 7 0 1 0 7 0a7 7 0 0 0 0 14Z" stroke-width="1"></path><path id="Union" fill="#2859c5" fill-rule="evenodd" d="M4.164 8.722H0.213A7 7 0 0 1 9.203 0.354v1.884a1.68 1.68 0 0 1 -1.68 1.68 1.68 1.68 0 0 0 -1.68 1.736v1.388a1.68 1.68 0 0 1 -1.68 1.68Zm8.31 -0.376 1.172 0.859a7.021 7.021 0 0 1 -3.537 4.068v-0.811a1.175 1.175 0 0 0 -1.189 -1.176 1.668 1.668 0 0 1 0 -3.322h2.024a3.431 3.431 0 0 1 1.53 0.382Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
              <span className='x-small-font'>
              <a href={`http://maps.google.com/maps?q=${
                lodge_meeting.gps !== ''
                  ? lodge_meeting.gps
                  : ''
                }`} target="_blank">
                {lodge_meeting.address}
              </a>
              </span>
              <div>
                <span className="small-font-italic">
                  {lodge_meeting.sponsored ? 'Sponsored' : lodge_meeting.promoted ? 'Promoted' : ''}
                </span>
              </div>
              </Col>              
            </Row>

            <Row className="small-font">
              <Col>
              {/*lodge_meeting.description*/}
              {
                lodge_meeting.description.length > 100
                ? <span id={`meeting_description_cut_${index}`} className='show_post_description'>{lodge_meeting.description.substring(0, 200) + "..."}
                <Button className="small-font" color="#007bff" onClick={() => {this.getDescription(index)}}>
                  see more
                </Button>
                </span>
                : ''
              }
              <span id={`meeting_description_full_${index}`} className={`${
                lodge_meeting.description.length < 100
                  ? 'show_post_description pre pre-code'
                  : 'hide_post_description pre pre-code'
                }`}>
              {lodge_meeting.description}
              {lodge_meeting.post_type == 2
                ?
                <div>
                  <br/>
                  Contact: {lodge_meeting.contact_name}
                  <br/>
                  Tel: {lodge_meeting.contact_number}
                  <br/>
                  Email: {lodge_meeting.contact_email}
                </div>
                 : ''
               }
              </span>
              </Col>
            </Row>
            {/*}<Row className="x-small-font">
              <Col>
              <span>
              <a href={`http://maps.google.com/maps?q=${
                lodge_meeting.gps !== ''
                  ? lodge_meeting.gps
                  : ''
                }`} target="_blank">{lodge_meeting.address}</a>
              </span>
              </Col>
            </Row>*/}
            <Row>
              <Col id={`meeting_image_${index}`} sm="12" className="post-restricted-height">
              {
                lodge_meeting.post_type == 1
                ?
                <img className='feed-wall-image' src={lodge_meeting.image}/>
                :
                lodge_meeting.image != ''
                ?
                  lodge_meeting.image.includes('.pdf') ? (
                    <div>
                      <embed src={process.env.REACT_APP_API_URL + lodge_meeting.image+"#toolbar=0"} type="application/pdf" className="width-height-hundred"/>
                      <br/>
                      <iframe src={process.env.REACT_APP_API_URL + lodge_meeting.image+"#toolbar=0"} className="width-height-hundred"/>
                    </div>
                  )
                  :
                  lodge_meeting.image.includes('.mp3') ? (
                      <audio controls>
                       <source src={process.env.REACT_APP_API_URL + lodge_meeting.image} type="audio/mp3" />
                       Your browser does not support the audio tag.
                      </audio>
                  ) :
                  lodge_meeting.image.includes('.mp4') ? (
                    <video controls className="video-container" preload="metadata">
                      <source src={process.env.REACT_APP_API_URL + lodge_meeting.image + "#t=1.75"} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) :
                  (
                    <img className="feed-wall-image" src={process.env.REACT_APP_API_URL + lodge_meeting.image} />
                  )
                : ''
              }
              </Col>
            </Row>
            <hr/>
            <Row className="row-justified-and-centered small-font">
              <Col>
              {
                lodge_meeting.post_type == 1
                ?
                  checkIfMeetingNotLogged(lodge_meeting.date, lodge_meeting.lodge_id, loginStore.visitationMeetings)
                  ? <Button className="feed-post-log-attendance-step small-font" color=""
                    onClick={async () => {
                      if (window.confirm('Are you sure you want to log attendance for this meeting?')) {
                        //this.props.logVisitMeetingStep();
                        logVisitMeetingStore.logAttendance(
                          lodge_meeting.date,
                          lodge_meeting.time,
                          lodge_meeting.meeting_type,
                          lodge_meeting.lodge_id,
                          lodge_meeting.description_id,
                          lodge_meeting.meeting_description_extra,
                          lodge_meeting.address_id,
                          loginStore,
                          checkIfFuture(lodge_meeting.date +' '+ lodge_meeting.time),
                          {
                            'name': lodge_meeting.lodge_name,
                            'number': lodge_meeting.lodge_number,
                            'address': lodge_meeting.address,
                            'meeting_description': lodge_meeting.description
                          });
                      }
                  }}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="One-Finger-Hold--Streamline-Core"><desc>One Finger Hold Streamline Icon: https://streamlinehq.com</desc><g id="one-finger-hold"><path id="Union" fill="#2859c5" fill-rule="evenodd" d="M1.5 4.196a2.696 2.696 0 0 1 3.95 -2.387 0.75 0.75 0 1 0 0.7 -1.327A4.196 4.196 0 0 0 0.11 5.15a0.75 0.75 0 1 0 1.46 -0.34 2.706 2.706 0 0 1 -0.07 -0.614ZM10.5 7a3.5 3.5 0 1 0 0 -7 3.5 3.5 0 0 0 0 7Z" clip-rule="evenodd" stroke-width="1"></path><g id="Vector 107"><path fill="#8fbffa" d="m3.133 4.26 0 5.436 -0.543 0.102a1.715 1.715 0 0 0 -1.096 2.659l0.764 1.11A1 1 0 0 0 3.08 14l6.608 0a1 1 0 0 0 1 -1l0 -2.09a2.573 2.573 0 0 0 -2.573 -2.574l-2.464 0 0 -4.077a1.26 1.26 0 0 0 -2.519 0Z" stroke-width="1"></path></g><path id="Vector 1529 (Stroke)" fill="#8fbffa" fill-rule="evenodd" d="M11.942 2.058a0.625 0.625 0 0 1 0 0.884l-1 1a0.625 0.625 0 1 1 -0.884 -0.884l1 -1a0.625 0.625 0 0 1 0.884 0Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
                      <span className="feed-button-text">Attend</span>
                      {lodge_meeting.attendance > 0 ?
                      <span className=""> ({lodge_meeting.attendance})</span>
                      : ''}
                    </Button>
                  :
                  <Button className="feed-post-log-attendance-step small-font" color=""
                    onClick={async () => {
                      this.props.profileStep()
                  }}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="User-Check-Validate--Streamline-Core" height="14" width="14"><desc>User Check Validate Streamline Icon: https://streamlinehq.com</desc><g id="user-check-validate--actions-close-checkmark-check-geometric-human-person-single-success-up-user"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M5 6a3 3 0 1 0 0 -6 3 3 0 0 0 0 6ZM0.5 12.5h5.55a2 2 0 0 1 2.998 -2.434l0.6 0.405 0.077 -0.111A5.002 5.002 0 0 0 0 12a0.5 0.5 0 0 0 0.5 0.5Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_2" fill="#2859c5" fill-rule="evenodd" d="M13.7 8.15a0.75 0.75 0 0 1 0.15 1.05l-3.12 4.5a0.75 0.75 0 0 1 -1.05 0.15l-2.25 -1.56a0.75 0.75 0 1 1 0.9 -1.2l1.65 1.11 2.67 -3.9a0.75 0.75 0 0 1 1.05 -0.15Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
                      <span className="feed-button-text">Attending</span>
                      {lodge_meeting.attendance > 0 ?
                      <span className=""> ({lodge_meeting.attendance})</span>
                      : ''}
                    </Button>
                : ''
              }
              </Col>
              <Col>
                {/*<div className="svg_comment"></div>*/}
                {
                  lodge_meeting.post_type == 1
                  ? <Button className="feed-post-contact-step small-font" color=""
                    onClick={async () => {
                      contactStore.setContactLodgeId(lodge_meeting.lodge_id);
                      this.props.contactStep();
                    }}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Mail-Send-Envelope--Streamline-Core"><desc>Mail Send Envelope Streamline Icon: https://streamlinehq.com</desc><g id="mail-send-envelope--envelope-email-message-unopened-sealed-close"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M1.5 1.25A1.5 1.5 0 0 0 0 2.75v8.5a1.5 1.5 0 0 0 1.5 1.5h11a1.5 1.5 0 0 0 1.5 -1.5v-8.5a1.5 1.5 0 0 0 -1.5 -1.5h-11Z" clip-rule="evenodd" stroke-width="1"></path><path id="Intersect" fill="#2859c5" fill-rule="evenodd" d="M14 3.092 7.383 7.504A0.72 0.72 0 0 1 7 7.607a0.72 0.72 0 0 1 -0.383 -0.103L0 3.092v1.502l5.927 3.952 0.005 0.004c0.315 0.205 0.693 0.307 1.068 0.307 0.375 0 0.753 -0.102 1.068 -0.307L14 4.594V3.092Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
                      <span className="feed-button-text">Contact</span>
                    </Button>
                  : '' /*<Button className="small-font" color=""
                    onClick={async () => {}}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Chat-Bubble-Text-Square--Streamline-Core"><desc>Chat Bubble Text Square Streamline Icon: https://streamlinehq.com</desc><g id="chat-bubble-text-square--messages-message-bubble-text-square-chat"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M2.5 0A1.5 1.5 0 0 0 1 1.5v8.919l-0.974 2.923a0.5 0.5 0 0 0 0.595 0.643L4.561 13H12.5a1.5 1.5 0 0 0 1.5 -1.5v-10A1.5 1.5 0 0 0 12.5 0h-10Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_2" fill="#2859c5" fill-rule="evenodd" d="M4.5 4.22a0.625 0.625 0 1 0 0 1.25h6a0.625 0.625 0 1 0 0 -1.25h-6Zm0 3.31a0.625 0.625 0 1 0 0 1.25h4a0.625 0.625 0 1 0 0 -1.25h-4Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
                      Comment
                    </Button>*/
                }
              </Col>
              <Col>
                <Button className={`small-font ${
                  lodge_meeting.post_type == 1
                    ? 'feed-post-share-step'
                    : ''
                  }`} color=""
                  onClick={async () => {
                    if (window.confirm('Are you sure you want to share this meeting?')) {
                      let lnk = "";
                      if (lodge_meeting.post_type == 1) {
                        lnk = constructWAShareLink(lodge_meeting.date_time + "\n" + lodge_meeting.lodge_name + ' No. ' + lodge_meeting.lodge_number + "\n" + lodge_meeting.address + "\n\n" + lodge_meeting.description);
                      }
                      else if (lodge_meeting.post_type == 2) {
                        lnk = constructWAShareLink(lodge_meeting.date_time + "\n" + lodge_meeting.title + "\n" + lodge_meeting.address + "\n\n" + lodge_meeting.description + "\n\n" + "Contact: " + lodge_meeting.contact_name + "\n" + "Tel: " + lodge_meeting.contact_number + "\n" + "Email: " + lodge_meeting.contact_email);
                      }
                      else {
                        lnk = "";
                      }
                      //e.preventDefault();
                      //window.location.href=lnk;
                      window.open(lnk, "_blank");
                    }
                  }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Share-Link--Streamline-Core"><desc>Share Link Streamline Icon: https://streamlinehq.com</desc><g id="share-link--share-transmit"><path id="Vector 1175 (Stroke)" fill="#8fbffa" fill-rule="evenodd" d="M12.394 2.053a1 1 0 0 1 -0.447 1.341L4.737 7l7.21 3.606a1 1 0 1 1 -0.894 1.788l-9 -4.5a1 1 0 0 1 0 -1.788l9 -4.5a1 1 0 0 1 1.341 0.447Z" clip-rule="evenodd" stroke-width="1"></path><path id="Ellipse 375" fill="#2859c5" d="M8.5 2.75a2.75 2.75 0 1 0 5.5 0 2.75 2.75 0 1 0 -5.5 0" stroke-width="1"></path><path id="Ellipse 376" fill="#2859c5" d="M8.5 11.25a2.75 2.75 0 1 0 5.5 0 2.75 2.75 0 1 0 -5.5 0" stroke-width="1"></path><path id="Ellipse 377" fill="#2859c5" d="M0 7a2.75 2.75 0 1 0 5.5 0A2.75 2.75 0 1 0 0 7" stroke-width="1"></path></g></svg>
                    <span className="feed-button-text">Share</span>
                </Button>
              </Col>

            </Row>
            </CardBody>
            </Card>
          ))}
          </div>
          )
          : 'No news at the moment'}
        </div>

        <InfiniteScroll
          dataLength={this.state.userFeedUpcomingMeetings.length}
          next={this.fetchMoreData}
          hasMore={true}
          loader={
            <Card className="post-option-active">
            <CardBody>
            </CardBody>
            </Card>
          }
        >
        </InfiniteScroll>

        <hr />
        <br />
        <div id='navigation_buttons'>
          <Button color="" onClick={this.props.searchStep}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Definition-Search-Book--Streamline-Core"><desc>Definition Search Book Streamline Icon: https://streamlinehq.com</desc><g id="definition-search-book"><path id="Subtract" fill="#8fbffa" fill-rule="evenodd" d="M0.5 0.125a0.5 0.5 0 0 0 -0.5 0.5v10a0.5 0.5 0 0 0 0.5 0.5h4.756A4.492 4.492 0 0 1 5 9.625c0 -1.271 0.527 -2.42 1.375 -3.238v-4.96A2 2 0 0 0 4.5 0.126h-4Zm7.125 1.303v4.105a4.5 4.5 0 0 1 6.347 3.584H14V0.625a0.5 0.5 0 0 0 -0.5 -0.5h-4a2 2 0 0 0 -1.875 1.303Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union" fill="#2859c5" fill-rule="evenodd" d="M7.75 9.625a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1 -3.5 0Zm1.75 -3.25a3.25 3.25 0 1 0 1.706 6.017l1.263 1.263a0.75 0.75 0 0 0 1.06 -1.06l-1.262 -1.264A3.25 3.25 0 0 0 9.5 6.375Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            Search
          </Button>
          {loginStore.isLoggedIn ? (
          <Button color="" onClick={async () => {
            this.props.contributeStore.setEdits(loginStore);
            this.props.contributeStep();
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Application-Add--Streamline-Core"><desc>Application Add Streamline Icon: https://streamlinehq.com</desc><g id="application-add--application-new-add-square"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M4.846 0h-3.77C0.483 0 0 0.482 0 1.077v3.77c0 0.594 0.482 1.076 1.077 1.076h3.77c0.594 0 1.076 -0.482 1.076 -1.077v-3.77C5.923 0.483 5.441 0 4.846 0Zm8.077 8.077h-3.77c-0.594 0 -1.076 0.482 -1.076 1.077v3.77c0 0.594 0.482 1.076 1.077 1.076h3.77c0.594 0 1.076 -0.482 1.076 -1.077v-3.77c0 -0.594 -0.482 -1.076 -1.077 -1.076Zm-11.846 0h3.77c0.594 0 1.076 0.482 1.076 1.077v3.77c0 0.594 -0.482 1.076 -1.077 1.076h-3.77A1.077 1.077 0 0 1 0 12.923v-3.77c0 -0.594 0.482 -1.076 1.077 -1.076Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_2" fill="#2859c5" fill-rule="evenodd" d="M11.51 0.75a0.75 0.75 0 1 0 -1.5 0v1.733H8.279a0.75 0.75 0 1 0 0 1.5h1.733v1.732a0.75 0.75 0 0 0 1.5 0V3.983h1.732a0.75 0.75 0 1 0 0 -1.5h-1.732V0.75Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            Contribute
          </Button>
          ): ''}
        </div>
        <Modal isOpen={this.state.promotedPostOpen}>
          <ModalHeader
            toggle={() => this.setState({ promotedPostOpen: false })}
          ></ModalHeader>
          <ModalBody>
            <PromotedPost />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => this.setState({ promotedPostOpen: false })}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.sponsoredPostOpen}>
          <ModalHeader
            toggle={() => this.setState({ sponsoredPostOpen: false })}
          ></ModalHeader>
          <ModalBody>
            <SponsoredPost />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => this.setState({ sponsoredPostOpen: false })}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <Tour
        steps={steps}
        isOpen={this.state.isTourOpen}
        onRequestClose={this.setTourIsClosed}
        onAfterOpen={this.props.setLoadingTrue}
        onBeforeClose={this.props.setLoadingFalse}
        showNavigation={false}
        />
      </div>
    );
  }
}

export default FeedWall;

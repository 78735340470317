// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  FormGroup
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import {districtTitleSwitch} from '../../helpers/utilities';

import { SearchStore } from '../../stores/search-store';
import { LodgeStore } from '../../stores/lodge-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LoginStore } from '../../stores/login-store';
import { ConfigurationStore } from '../../stores/configuration-store';

import { SelectInput } from '../inputs/select';
import { SearchableSelectInput } from '../inputs/searchable-select'
import { NumberInput } from '../inputs/number';
import { TextInput } from '../inputs/text';

interface Props {
  nextStep: () => void;
  contributeStep: () => void;
  newLocationStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  searchStore?: SearchStore;
  lodgeStore?: LodgeStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
  configurationStore?: ConfigurationStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
  advancedSearchToggle: boolean;
}

@inject('searchStore', 'lodgeStore', 'contributeStore', 'loginStore', 'configurationStore')
@observer
export class Search extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
      advancedSearchToggle: false,
    };
  }



  render() {
    if (!this.props.searchStore) {
      return;
    }

    const searchStore = this.props.searchStore;

    const searchInput = searchStore.searchInput;
    const validationResult = searchStore.validationResult;

    const loginStore = this.props.loginStore;

    const configurationStore = this.props.configurationStore;
    //console.log("------- configurationStore.addressResults -------");
    //console.log(configurationStore.addressResults);
    //console.log("---------------------------------------");

    return (
      <div>
      <Row>
        <Col sm="12">
          <h4><u>Search</u></h4>
        </Col>
      </Row>

      <Card>
      <CardBody>
        <h5>Criteria:</h5>
        <div>
        <Row>
          <Col>
            <TextInput
              id="name_number"
              label="Lodge Name/Number*"
              value={searchInput.search}
              onChange={e =>
                (searchInput.search = e.target.value)
              }
              invalid={!!validationResult.search}
              validationMessage={validationResult.search}
            />
          </Col>
        </Row>
        <a href='#' onClick={ () => {
          //searchStore.toggleExpand();
          this.setState({advancedSearchToggle: !this.state.advancedSearchToggle});
//          this.forceUpdate();
        }}>
          {this.state.advancedSearchToggle ? 'Advanced Search Hide' : 'Advanced Search Show'}
        </a>
        {this.state.advancedSearchToggle && (
          <div>
          <br/>
          <Row>
            <Col>

            <FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type='checkbox'
                    name='radioToday'
                    checked={searchInput.today_only}
                    onClick={() => {
                      searchInput.today_only = !searchInput.today_only;
                      //this.props.applicationStore.validationResult = {};
                    }}
                  />{' '}
                  Meeting Today
                </Label>
              </FormGroup>
            </FormGroup>

            </Col>
          </Row>
          <Row>
            <Col>
            <SelectInput
              id='country'
              label='Country'
              invalid={!!validationResult.country}
              validationMessage={validationResult.country}
              value={searchInput.country}
              onChange={e => {
                searchStore.searchInput.country = e.target.value;
                configurationStore.filterAddresses(configurationStore.addressResults, searchStore.searchInput.country);
                configurationStore.filterConstitutions(configurationStore.constitutionResults, searchStore.searchInput.country);
                searchInput.constitution = '';
                searchInput.district = '';
                configurationStore.filteredDistricts = [];
                searchInput.address = '';
              }}
              options={configurationStore.countryResults}
            />
            </Col>
            <Col>
            <SelectInput
              id='constitution'
              label='Constitution'
              invalid={!!validationResult.constitution}
              validationMessage={validationResult.constitution}
              value={searchInput.constitution}
              onChange={e => {
                //console.log(e.target.value.split('_', 1));
                //console.log(e.target.value.split('_', 1)[0]);
                searchInput.constitution = e.target.value;
                configurationStore.filterDistricts(configurationStore.districtResults, searchStore.searchInput.country, searchInput.constitution.split('_', 1)[0]);
                searchStore.searchInput.district = '';
                searchStore.searchInput.address = '';
              }}
              options={configurationStore.filteredConstitutions}
            />
            </Col>
          </Row>
          <Row>
            <Col>
            <SelectInput
              id='district'
              label={districtTitleSwitch(searchInput.country, searchInput.constitution.split('_', 1)[0], configurationStore.filteredConstitutions)}
              invalid={!!validationResult.district}
              validationMessage={validationResult.district}
              value={searchInput.district}
              onChange={e => {
                searchInput.district = e.target.value;
              }}
              options={configurationStore.filteredDistricts}
            />
            </Col>
            <Col>
            <SelectInput
              id='degree'
              label='Order'
              invalid={!!validationResult.degree}
              validationMessage={validationResult.degree}
              value={searchInput.degree}
              onChange={e => {
                searchStore.searchInput.degree = e.target.value;
              }}
              options={configurationStore.degreeResults}
            />
            </Col>
          </Row>
          <Row>
            <Col>
            <SearchableSelectInput
              id='address'
              label='Address'
              invalid={!!validationResult.address}
              validationMessage={validationResult.address}
              value={searchInput.address}
              onChange={(selectedOption) => {
                if (selectedOption != null) {
                  searchStore.searchInput.address = selectedOption.value; // Set the address_id using the selected option's value property
                }
              }}
              options={configurationStore.filteredAddresses}
            />
            <br/>
            <Button color="primary" onClick={async () => {
              this.props.newLocationStep();
            }}>
              Missing address?
            </Button>
            </Col>
          </Row>
          </div>
        )}

        </div>
        <br/>
        <Button color="primary" onClick={async () => {
          //const success = true;//await quoteStore.getQuote();
          //if (success) {
          //  this.props.nextStep()
          //}
          this.props.setLoadingTrue();

          this.props.lodgeStore.clearLodges();
          this.props.lodgeStore.searchString = searchInput.search;
          this.props.lodgeStore.country = searchInput.country;
          this.props.lodgeStore.district = searchInput.district.split('_', 1)[0];
          this.props.lodgeStore.constitution = searchInput.constitution.split('_', 1)[0];
          this.props.lodgeStore.degree = searchInput.degree;
          this.props.lodgeStore.today_only = searchInput.today_only;
          this.props.lodgeStore.address = searchInput.address;
          //this.props.lodgeStore.setSearchString(searchInput.search);
          this.props.lodgeStore.setLodges(loginStore);
          this.props.nextStep();

          this.props.setLoadingFalse();
        }}>
          Search
        </Button>
        {loginStore.isLoggedIn ? (
          <Button color="primary" onClick={async () => {
            this.props.contributeStore.setEdits(loginStore);
            this.props.contributeStep();
          }}>
            Contribute
          </Button>
        ): ''}

      </CardBody>
      </Card>
      </div>
    );
  }
}

export default Search;

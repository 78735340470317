import { observable, action } from 'mobx';
import { UserAccount } from '../domain/user-account';
import api from '../api';

export class UserAccountStore {
  @observable isLoading = false;
  @observable typeOfThankYou = '';
  @observable editResults: any = [];

  @observable toggleOptionsDiv: { [k: number]: boolean } = {};

  constructor() {

  }




}

export const userAccountStore = new UserAccountStore();
(window as any).userAccountStore = userAccountStore;

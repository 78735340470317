// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';

import { UserAccountStore } from '../../stores/user-account-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { LoginStore } from '../../stores/login-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  userAccountStore?: UserAccountStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('userAccountStore', 'contributeStore', 'loginStore',)
@observer
export class UserAccount extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  render() {
    if (!this.props.userAccountStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const userAccountStore = this.props.userAccountStore;//typeOfThankYou

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Account</u></h4>
          </Col>
        </Row>

        <Card className='post-option-active'>
        <CardBody>
          <Row className="">
            <Col>
              <span className="small-font">Your current balance is: {loginStore.user_credits} Credits</span>
            </Col>
          </Row>
        </CardBody>
        </Card>

        <div className="">
          <div className='centered-header'>
            <b className='pointing-cursor lined-heading x-small-font' onClick={() => {
              userAccountStore.toggleOptionsDiv[0] = !userAccountStore.toggleOptionsDiv[0];
            }}>
            { userAccountStore.toggleOptionsDiv[0]
              ?
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Arrow-Shrink--Streamline-Core" height="14" width="14"><desc>Arrow Shrink Streamline Icon: https://streamlinehq.com</desc><g id="arrow-shrink--expand-retract-shrink-bigger-big-small-smaller"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M0.293 0.293a1 1 0 0 1 1.414 0l1.397 1.396L4.146 0.646A0.5 0.5 0 0 1 5 1v3.5a0.5 0.5 0 0 1 -0.5 0.5H1a0.5 0.5 0 0 1 -0.354 -0.854L1.69 3.104 0.293 1.707a1 1 0 0 1 0 -1.414Zm9.016 0.245a0.5 0.5 0 0 1 0.545 0.108l1.043 1.043L12.293 0.293a1 1 0 1 1 1.414 1.414l-1.396 1.397 1.043 1.042A0.5 0.5 0 0 1 13 5H9.5a0.5 0.5 0 0 1 -0.5 -0.5V1a0.5 0.5 0 0 1 0.309 -0.462ZM1 9a0.5 0.5 0 0 0 -0.354 0.854l1.043 1.043 -1.396 1.396a1 1 0 1 0 1.414 1.414l1.397 -1.396 1.042 1.043A0.5 0.5 0 0 0 5 13V9.5a0.5 0.5 0 0 0 -0.5 -0.5H1Zm8 0.5a0.5 0.5 0 0 1 0.5 -0.5H13a0.5 0.5 0 0 1 0.354 0.854l-1.043 1.043 1.396 1.396a1 1 0 0 1 -1.414 1.414l-1.397 -1.396 -1.042 1.043A0.5 0.5 0 0 1 9 13V9.5Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Arrow-Expand--Streamline-Core" height="14" width="14"><desc>Arrow Expand Streamline Icon: https://streamlinehq.com</desc><g id="arrow-expand--expand-small-bigger-retract-smaller-big"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M0 1v3.5a0.5 0.5 0 0 0 0.854 0.354L2.146 3.56l2.147 2.146a1 1 0 0 0 1.414 -1.414L3.561 2.146 4.854 0.854A0.5 0.5 0 0 0 4.5 0h-4a0.5 0.5 0 0 0 -0.5 0.5V1Zm5.707 8.707a1 1 0 0 0 -1.414 -1.414l-2.147 2.146L0.854 9.146A0.5 0.5 0 0 0 0 9.5v4a0.5 0.5 0 0 0 0.5 0.5h4a0.5 0.5 0 0 0 0.354 -0.854L3.56 11.854l2.146 -2.147Zm2.586 -1.414a1 1 0 0 1 1.414 0l2.147 2.146 1.292 -1.293A0.5 0.5 0 0 1 14 9.5v4a0.5 0.5 0 0 1 -0.5 0.5h-4a0.5 0.5 0 0 1 -0.354 -0.854l1.293 -1.292 -2.146 -2.147a1 1 0 0 1 0 -1.414ZM9.5 0a0.5 0.5 0 0 0 -0.354 0.854l1.293 1.292 -2.146 2.147a1 1 0 0 0 1.414 1.414l2.147 -2.146 1.292 1.293A0.5 0.5 0 0 0 14 4.5v-4a0.5 0.5 0 0 0 -0.5 -0.5h-4Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            }
            Top up Options
            </b>
          </div>
          <div className={`toggle-email-setting-div${
            userAccountStore.toggleOptionsDiv[0]
              ? '-open'
              : '-closed'
            }`}>
        <Row>
          <Col sm="4">
            <div className="pricing-top-label"><b>Starter Pack</b></div>
            <Card className='post-option-active top-up-option'>
            <CardBody>
            <Row>
              <Col>
                <b>10 Credits</b>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="x-small-font">FOR</span>
                <br/>
                <b>R33.00</b>
                <br/><br/>
              </Col>
            </Row>
            {/*<Row>
              <Col className="promote-button-col">
              <Button className="small-font promote-button" color="primary"
                onClick={async () => {
                  if (window.confirm('You are currently unable to top up your account!')) {

                    //promotePostStore.setPostData(lodge_meeting);
                    //this.props.promotePostStep();
                  }
                }}>
                  <span className="">Top up account</span>
              </Button>
              </Col>
            </Row>*/}
            <Row>
              <Col className="row-justified-and-centered-flex promote-button-col">
              <form name="PayFastPayNowForm" action="https://payment.payfast.io/eng/process" method="post" target="_blank">
                <input required type="hidden" name="cmd" value="_paynow" />
                <input required type="hidden" name="receiver" pattern="[0-9]" value="24874815" />
                <input type="hidden" name="return_url" value="https://freemason-alpha.com/ftg" />
                <input type="hidden" name="cancel_url" value="https://freemason-alpha.com/ftg" />
                <input type="hidden" name="notify_url" value="https://freemason-alpha.com/ftg" />
                <input required type="hidden" name="amount" value="33" />
                <input required type="hidden" name="item_name" value="10 Credits" />
                <input type="hidden" name="item_description" value="10 credits within the Freemason Travellers Guide"/ >
                <table>
                  <tr>
                    <td>
                      <input type="image" src="https://my.payfast.io/images/buttons/BuyNow/Light-Small-BuyNow.png" alt="Buy Now" title="Buy Now with Payfast" />
                    </td>
                  </tr>
                </table>
              </form>
              </Col>
            </Row>
            </CardBody>
            </Card>
          </Col>
          <Col sm="4">
          <div className="pricing-top-label"><b>Most Loved</b></div>
            <Card className='post-option-active top-up-option'>
            <CardBody>
            <Row>
              <Col>
                <b>100 Credits</b>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="x-small-font">FOR</span>
                <br/>
                <b>R297.00</b> <s>R330.00</s>
                <br/>
                <span className="small-font-italic">(10% off)</span>
              </Col>
            </Row>
            {/*<Row>
              <Col className="promote-button-col">
              <Button className="small-font promote-button" color="primary"
                onClick={async () => {
                  if (window.confirm('You are currently unable to top up your account!')) {

                    //promotePostStore.setPostData(lodge_meeting);
                    //this.props.promotePostStep();
                  }
                }}>
                  <span className="">Top up account</span>
              </Button>
              </Col>
            </Row>*/}
            <Row>
              <Col className="row-justified-and-centered-flex promote-button-col">
              <form name="PayFastPayNowForm" action="https://payment.payfast.io/eng/process" method="post" target="_blank">
                <input required type="hidden" name="cmd" value="_paynow" />
                <input required type="hidden" name="receiver" pattern="[0-9]" value="24874815" />
                <input type="hidden" name="return_url" value="https://freemason-alpha.com/ftg" />
                <input type="hidden" name="cancel_url" value="https://freemason-alpha.com/ftg" />
                <input type="hidden" name="notify_url" value="https://freemason-alpha.com/ftg" />
                <input required type="hidden" name="amount" value="297" />
                <input required type="hidden" name="item_name" value="100 Credits" />
                <input type="hidden" name="item_description" value="100 credits within the Freemason Travellers Guide" />
                <table>
                  <tr>
                    <td>
                      <input type="image" src="https://my.payfast.io/images/buttons/BuyNow/Light-Small-BuyNow.png" alt="Buy Now" title="Buy Now with Payfast" />
                    </td>
                  </tr>
                </table>
              </form>
              </Col>
            </Row>
            </CardBody>
            </Card>
          </Col>
          <Col sm="4">
            <div className="pricing-top-label"><b>Best Value</b></div>
            <Card className='post-option-active top-up-option'>
            <CardBody>
            <Row>
              <Col>
                <b>1000 Credits</b>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="x-small-font">FOR</span>
                <br/>
                <b>R2640.00</b> <s>R3300.00</s>
                <br/>
                <span className="small-font-italic">(20% off)</span>
              </Col>
            </Row>
            {/*<Row>
              <Col className="promote-button-col">
              <Button className="small-font promote-button" color="primary"
                onClick={async () => {
                  if (window.confirm('You are currently unable to top up your account!')) {

                    //promotePostStore.setPostData(lodge_meeting);
                    //this.props.promotePostStep();
                  }
                }}>
                  <span className="">Top up account</span>
              </Button>
              </Col>
            </Row>*/}
            <Row>
              <Col className="row-justified-and-centered-flex promote-button-col">
              <form name="PayFastPayNowForm" action="https://payment.payfast.io/eng/process" method="post" target="_blank">
                <input required type="hidden" name="cmd" value="_paynow" />
                <input required type="hidden" name="receiver" pattern="[0-9]" value="24874815" />
                <input type="hidden" name="return_url" value="https://freemason-alpha.com/ftg" />
                <input type="hidden" name="cancel_url" value="https://freemason-alpha.com/ftg" />
                <input type="hidden" name="notify_url" value="https://freemason-alpha.com/ftg" />
                <input required type="hidden" name="amount" value="2640" />
                <input required type="hidden" name="item_name" value="1000 Credits" />
                <input type="hidden" name="item_description" value="1000 credits within the Freemason Travellers Guide" />
                <table>
                  <tr>
                    <td>
                      <input type="image" src="https://my.payfast.io/images/buttons/BuyNow/Light-Small-BuyNow.png" alt="Buy Now" title="Buy Now with Payfast" />
                    </td>
                  </tr>
                </table>
              </form>
              </Col>
            </Row>
            </CardBody>
            </Card>
          </Col>
        </Row>
          </div>
        </div>

        <Card className='post-option-active'>
        <CardBody>
          <Row>
            <Col sm="12" className="">
              <span>Transactions</span>
            </Col>
          </Row>
        </CardBody>
        </Card>

        <hr />
        <br />
        <div id='navigation_buttons'>
          <Button color="primary" onClick={this.props.searchStep}>
            Search
          </Button>
          {loginStore.isLoggedIn ? (
          <Button color="primary" onClick={async () => {
            this.props.contributeStore.setEdits(loginStore);
            this.props.contributeStep();
          }}>
            Contribute
          </Button>
          ): ''}
        </div>
      </div>
    );
  }
}

export default UserAccount;

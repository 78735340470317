// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  FormGroup
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';

import { AddLodgeStore } from '../../stores/add-lodge-store';
import { NewLocationStore } from '../../stores/new-location-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { ThankYouStore } from '../../stores/thank-you-store';
import { LoginStore } from '../../stores/login-store';
import { ConfigurationStore } from '../../stores/configuration-store';

import { CurrencyInput } from '../inputs/currency';
import { DateInput } from '../inputs/date';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput, TextArea } from '../inputs/text';

interface Props {
  previousStep: () => void;
  nextStep: () => void;
  thankYouStep: () => void;
  lodgeDetailsStep: () => void;
  newLocationStore?: NewLocationStore;
  lodgeDetailsStore?: LodgeDetailsStore;
  thankYouStore?: ThankYouStore;
  loginStore?: LoginStore;
  configurationStore?: ConfigurationStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('newLocationStore', 'lodgeDetailsStore', 'thankYouStore', 'loginStore', 'configurationStore')
@observer
export class NewLocation extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false
    };
  }

  render() {
    if (!this.props.newLocationStore) {
      return;
    }

    const newLocationStore = this.props.newLocationStore;
    console.log("------- newLocationStore.lodgeResults -------");
    console.log(newLocationStore.lodgeResults);
    console.log("---------------------------------------");

    const newLocationInput = newLocationStore.newLocationInput;
    const validationResult = newLocationStore.validationResult;

    const lodgeDetailsStore = this.props.lodgeDetailsStore;//lodgeInput.lodge_id
    //console.log("------- lodgeDetailsStore.lodgeInput -------");
    //console.log(lodgeDetailsStore.lodgeInput);
    //console.log("---------------------------------------");

    const configurationStore = this.props.configurationStore;

    const thankYouStore = this.props.thankYouStore;
    thankYouStore.typeOfThankYou = 'suggestion';

    const loginStore = this.props.loginStore;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h1>New Location</h1>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col>
            <TextInput
              id="name"
              label="Name"
              value={newLocationInput.name}
              onChange={e =>
                (newLocationInput.name = e.target.value)
              }
              invalid={!!validationResult.name}
              validationMessage={validationResult.name}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              id="address"
              label="Address"
              value={newLocationInput.address}
              onChange={e =>
                (newLocationInput.address = e.target.value)
              }
              invalid={!!validationResult.address}
              validationMessage={validationResult.address}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              id="city"
              label="City"
              value={newLocationInput.city}
              onChange={e =>
                (newLocationInput.city = e.target.value)
              }
              invalid={!!validationResult.city}
              validationMessage={validationResult.city}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              id="region_name"
              label="Region"
              value={newLocationInput.region_name}
              onChange={e =>
                (newLocationInput.region_name = e.target.value)
              }
              invalid={!!validationResult.region_name}
              validationMessage={validationResult.region_name}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectInput
              id='country'
              label='Country'
              invalid={!!validationResult.country}
              validationMessage={validationResult.country}
              value={newLocationInput.country}
              onChange={e => {
                newLocationInput.country = e.target.value;
              }}
              options={configurationStore.countryResults}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              id="latitude"
              label="Latitude"
              value={newLocationInput.latitude}
              onChange={e =>
                (newLocationInput.latitude = e.target.value)
              }
              invalid={!!validationResult.latitude}
              validationMessage={validationResult.latitude}
            />
          </Col>
          <Col>
            <TextInput
              id="longitude"
              label="Longitude"
              value={newLocationInput.longitude}
              onChange={e =>
                (newLocationInput.longitude = e.target.value)
              }
              invalid={!!validationResult.longitude}
              validationMessage={validationResult.longitude}
            />
          </Col>
        </Row>


        <br/>
        <Button color="primary" onClick={async () => {
          //toast.info("TEST!");

          newLocationStore.validate();
          const success = newLocationStore.checkValidation();
          console.log('----');
          console.log(success);
          console.log('----');
          if (success) {
            if (await newLocationStore.addLocation(
                  newLocationInput.name,
                  newLocationInput.address,
                  newLocationInput.city,
                  newLocationInput.region_name,
                  newLocationInput.country,
                  newLocationInput.latitude,
                  newLocationInput.longitude,
                  loginStore
                )) {
                toast.success("Your suggested location has been logged!");
                this.props.thankYouStep();
            }
            else {
              toast.warning("Could not log your suggested location!");
            }
          }
        }}>
          Send
        </Button>
        <Button color="" onClick={this.props.lodgeDetailsStep}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Return-2--Streamline-Core"><desc>Return 2 Streamline Icon: https://streamlinehq.com</desc><g id="return-2--arrow-return-enter-keyboard"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M6.545 0.998a1 1 0 0 0 0 2h2.728a2.638 2.638 0 0 1 0 5.275H4.817V6.545a1 1 0 0 0 -1.707 -0.707L0.384 8.564a1.004 1.004 0 0 0 -0.22 1.09c0.049 0.119 0.121 0.23 0.218 0.327l2.728 2.728a1 1 0 0 0 1.707 -0.707v-1.729h4.456a4.638 4.638 0 1 0 0 -9.275H6.545Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
          Back
        </Button>
      </div>
    );
  }
}

export default NewLocation;

// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { observer, inject } from 'mobx-react';
import {
  degreeFromId,
  degreeFromIdNoCollective,
  meetingStringToDate,
  getLanguageFromConfigsById
} from '../../helpers/utilities';

import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { SuggestEditLodgeStore } from '../../stores/suggest-edit-lodge-store';
import { SuggestEditMeetingStore } from '../../stores/suggest-edit-meeting-store';
import { SuggestEditContactStore } from '../../stores/suggest-edit-contact-store';
import { ConfigurationStore } from '../../stores/configuration-store';
import { LoginStore } from '../../stores/login-store';
import { LogVisitMeetingStore } from '../../stores/log-visit-meeting-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';


interface Props {
  nextStep: () => void;
  previousStep: () => void;
  suggestEditLodgeStep: () => void;
  membershipClaimStep: () => void;
  emergencyMeetingStep: () => void;
  logVisitMeetingStep: () => void;
  lodgeDetailsStore?: LodgeDetailsStore;
  suggestEditLodgeStore?: SuggestEditLodgeStore;
  suggestEditMeetingStore?: SuggestEditMeetingStore;
  suggestEditContactStore?: SuggestEditContactStore;
  loginStore?: LoginStore;
  configurationStore?: ConfigurationStore;
  logVisitMeetingStore?: LogVisitMeetingStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('lodgeDetailsStore',
'suggestEditLodgeStore',
'suggestEditMeetingStore',
'suggestEditContactStore',
'loginStore',
'configurationStore',
'logVisitMeetingStore'
)
@observer
export class LodgeDetails extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false
    };

  }

  handleClearingOfEdit = async () => {
    //this.props.suggestEditLodgeStore.clearLodgeInput();
    //this.props.suggestEditMeetingStore.clearMeetingInput();
    //this.props.suggestEditContactStore.clearContactInput();
    this.props.suggestEditLodgeStep();
    //await this.props.configurationStore.getAllAddresses(); // you shouldn't have to reload them
  };

  checkTimeWithinRangeEveryX = (lodgeDetailsStore) => {
    let result = false;
    try {
      const givenDateTime = new Date(lodgeDetailsStore.todaysMeetingTime);
      const currentDateTime = new Date();
      // Calculate the difference in milliseconds between the given date and time and the current date and time
      const timeDifference = Math.abs(givenDateTime.getTime() - currentDateTime.getTime());
      console.log(timeDifference);
      if (timeDifference <= 3600000) { // 3600000 milliseconds = 1 hour
        //console.log("The given date and time is within one hour of the current date and time.");
        result = true;
      } else {
        //console.log("The given date and time is NOT within one hour of the current date and time.");
        result = false;
      }
    } catch (error) {
        console.log("Invalid date and time: " + error.message);
    }
    //return result;
    lodgeDetailsStore.canCheckIn = result;
  }

  checkIfToday = (the_date) => {
    // Create a new Date object for the given date
    const givenDate = new Date(the_date); // Replace this with your desired date

    // Get the current date
    const currentDate = new Date();

    // Compare year, month, and day components
    const isToday = (
      givenDate.getFullYear() === currentDate.getFullYear() &&
      givenDate.getMonth() === currentDate.getMonth() &&
      givenDate.getDate() === currentDate.getDate()
    );

    return isToday;
  }

  checkIfFuture = (the_date) => {
    // Create a new Date object for the given date
    const givenDate = new Date(the_date);

    // Get the current date
    const currentDate = new Date();

    // Compare givenDate and currentDate
    const isFuture = (
      givenDate > currentDate
    );

    return (isFuture) ? 'future' : 'past';
  }

  checkTimeWithinRange = (meetings) => {
    let result = false;
    for (let i = 0; i < meetings.length; i++) {
      const meeting = meetings[i];
      try {

          let g = meetingStringToDate(meeting.position,meeting.day,meeting.month,meeting.type).split(' - ')[0] + 'T' + meeting.time;
          console.log(g);

          const givenDateTime = new Date(g);

          const currentDateTime = new Date();

          // Calculate the difference in milliseconds between the given date and time and the current date and time
          const timeDifference = Math.abs(givenDateTime.getTime() - currentDateTime.getTime());
          console.log(timeDifference);
          //if (timeDifference <= 3600000) { // 3600000 milliseconds = 1 hour
          if (timeDifference <= 36000000) { // 36000000 milliseconds = 10 hours
              //console.log("The given date and time is within one hour of the current date and time.");
              result = true;
              break;
          } else {
              //console.log("The given date and time is NOT within one hour of the current date and time.");
              result = false;
          }
      } catch (error) {
          console.log("Invalid date and time: " + error.message);
      }
    }
    return result;
  }

  testTime = () => {
    const timer = setInterval(() => {
      this.checkTimeWithinRangeEveryX(this.props.lodgeDetailsStore);
    }, 5000); // Adjust the interval as needed (in milliseconds)

    return timer;
  }


  checkIfMeetingNotLogged = (the_date, lodge_id, visits) => {
    return !visits.some(visit => {
        return visit.datetime.includes(the_date) && visit.lodge_id === lodge_id;
    });
  };

  render() {
    if (!this.props.lodgeDetailsStore) {
      return;
    }

    const lodgeDetailsStore = this.props.lodgeDetailsStore;

    const suggestEditLodgeStore = this.props.suggestEditLodgeStore;
    suggestEditLodgeStore.setSuggestEditLodgeData(lodgeDetailsStore.lodgeInfo);

    const suggestEditMeetingStore = this.props.suggestEditMeetingStore;
    suggestEditMeetingStore.setSuggestEditMeetingData(lodgeDetailsStore.lodgeMeetingsTwo);

    const suggestEditContactStore = this.props.suggestEditContactStore;
    suggestEditContactStore.setSuggestEditContactData(lodgeDetailsStore.lodgeInfo);

    const loginStore = this.props.loginStore;

    const logVisitMeetingStore = this.props.logVisitMeetingStore;

    const configurationStore = this.props.configurationStore;

    let name_number = '';
    name_number = 'https://freemason-alpha.tempelhoffenterprises.com/freemason-travelers-guide/public/getCalendarForLodge/'+lodgeDetailsStore.lodgeInput.name+'_'+lodgeDetailsStore.lodgeInput.number;
    //name_number = 'http://localhost:8000/getCalendarForLodge/'+lodgeDetailsStore.lodgeInput.name+'_'+lodgeDetailsStore.lodgeInput.number;


    if (this.props.lodgeDetailsStore.theTimer == null) {
      if(this.checkIfToday(lodgeDetailsStore.todaysMeetingTime.split('T')[0])){
        this.props.lodgeDetailsStore.theTimer = this.testTime();
      }
    }

    return (
      <div>
        <Row>
          <Col sm="12">
            {lodgeDetailsStore.lodgeInfo.map((lodge, index) => (
              <div>
                <h4><b><u>{lodge.name} No. {lodge.number} {lodge.short_constitution}<img alt='logo' className="orderlogo" src={`star${
                  lodge.isFavorite
                    ? '_2.png'
                    : '_1.png'
                  }`} height="40px" onClick={async () => {
                    await this.props.lodgeDetailsStore.updateWatchList(lodge.id, !lodge.isFavorite);

                    lodge.isFavorite = !lodge.isFavorite;

                  }} /></u></b></h4>
              </div>
            ))}
          </Col>
        </Row>

        <Card className='post-option-active'>
        <CardBody>
        <Row className='row-justified-and-centered'>
          <Col>
            <img className='lodge-details-image' src={'lodges/'+lodgeDetailsStore.lodgeInfo[0].image}/>
          </Col>
        </Row>
        <h5>{lodgeDetailsStore.isDetailsLoading ? 'Loading...' : 'Details:'}</h5>
        <div>
        {lodgeDetailsStore.lodgeInfo.map((lodge, index) => (
          <div>
          <span className='lodge-details-heading'>Address:</span>
          <br/>
          <span>
          <a href={`http://maps.google.com/maps?q=${
            lodge.gps !== ''
              ? lodge.gps
              : ''
            }`} target="_blank">{lodge.address}</a>
          </span>
          <br/>
          <span className='lodge-details-heading'>Meetings:</span>
          <br/>
          <span>
          {lodge.meeting_time} {lodge.meeting_date}
          </span>
          <br/>
          <span className='lodge-details-heading'>Installation:</span>
          <br/>
          {lodge.installation_time} {lodge.installation_date}
          {/*<br/>
          Contact:
          <br/>
          {lodge.contact} <a href={`tel:${lodge.contact_number}`} target="_blank">{lodge.contact_number}</a>*/}
          <br/>
          <span className='lodge-details-heading'>Website:</span>
          <br/>
          <a href={lodge.website} target="_blank">{lodge.website}</a>
          {/*
          <br/>
          Email:
          <br/>
          {lodge.email}
          */}
          <br/>
          <span className='lodge-details-heading'>Order:</span>
          <br/>
          {degreeFromIdNoCollective(lodge.degree)}
          <br/>
          <span className='lodge-details-heading'>Language:</span>
          <br/>
          {getLanguageFromConfigsById(lodge.language, configurationStore.languageResults)}
          <br/>
          <span className='lodge-details-heading'>State:</span>
          <br/>
          {lodge.active}
          <br/>
          </div>
        ))}
        </div>
        <hr/>
        <h5>{lodgeDetailsStore.isMeetingsLoading ? '' : 'Meetings:'}</h5>
        <div>
        {lodgeDetailsStore.lodgeMeetings.map((meeting, index) => (
          <div>

          <span className={`date${
            this.checkIfToday(meeting.date)
              ? '-today'
              : ''
            }`}>
            {/*lodgeDetailsStore.canCheckIn*/
              /*loginStore.isLoggedIn*/
              this.checkIfMeetingNotLogged(meeting.date, lodgeDetailsStore.lodgeInfo[0].id, loginStore.visitationMeetings)
              ? <Button color="primary"
                onClick={async () => {
                  if (window.confirm('Are you sure you want to log attendance for this meeting?')) {
                    //this.props.logVisitMeetingStep();
                    logVisitMeetingStore.logAttendance(
                      meeting.date,
                      meeting.time,
                      meeting.meeting_type_id,
                      lodgeDetailsStore.lodgeInfo[0].id,
                      meeting.meeting_description_id,
                      meeting.meeting_description_extra,
                      lodgeDetailsStore.lodgeInfo[0].address_id,
                      loginStore,
                      this.checkIfFuture(meeting.date +' '+ meeting.time),
                      {
                        'name': lodgeDetailsStore.lodgeInfo[0].name,
                        'number': lodgeDetailsStore.lodgeInfo[0].number,
                        'address': lodgeDetailsStore.lodgeInfo[0].address,
                        'meeting_description': meeting.meeting_description
                      });
                    clearInterval(this.props.lodgeDetailsStore.theTimer);
                    this.props.lodgeDetailsStore.theTimer = null;
                    this.props.lodgeDetailsStore.todaysMeetingTime = '';
                  }
              }}
              style={{ background: 'none', border: 'none', padding: '0px', margin: '0px' }}
              >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="One-Finger-Hold--Streamline-Core"><desc>One Finger Hold Streamline Icon: https://streamlinehq.com</desc><g id="one-finger-hold"><path id="Union" fill="#2859c5" fill-rule="evenodd" d="M1.5 4.196a2.696 2.696 0 0 1 3.95 -2.387 0.75 0.75 0 1 0 0.7 -1.327A4.196 4.196 0 0 0 0.11 5.15a0.75 0.75 0 1 0 1.46 -0.34 2.706 2.706 0 0 1 -0.07 -0.614ZM10.5 7a3.5 3.5 0 1 0 0 -7 3.5 3.5 0 0 0 0 7Z" clip-rule="evenodd" stroke-width="1"></path><g id="Vector 107"><path fill="#8fbffa" d="m3.133 4.26 0 5.436 -0.543 0.102a1.715 1.715 0 0 0 -1.096 2.659l0.764 1.11A1 1 0 0 0 3.08 14l6.608 0a1 1 0 0 0 1 -1l0 -2.09a2.573 2.573 0 0 0 -2.573 -2.574l-2.464 0 0 -4.077a1.26 1.26 0 0 0 -2.519 0Z" stroke-width="1"></path></g><path id="Vector 1529 (Stroke)" fill="#8fbffa" fill-rule="evenodd" d="M11.942 2.058a0.625 0.625 0 0 1 0 0.884l-1 1a0.625 0.625 0 1 1 -0.884 -0.884l1 -1a0.625 0.625 0 0 1 0.884 0Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
                </Button>
              : ''
            }
            {meeting.date}@{meeting.time} - {meeting.meeting_type} {meeting.meeting_type == 'Meeting' || meeting.meeting_type == 'Emergency Meeting' ? '('+meeting.meeting_description+')' : ''}</span>
            {meeting.meeting_description_id == 9 ? " - " + meeting.meeting_description_extra : ''}
            <br/>
            <span className='small-font-italic'>
            {
              (this.checkIfFuture(meeting.date +' '+ meeting.time) == 'future')
              ? ((meeting.attendance == 0) ? 'be the first to log attendance.' : (meeting.attendance == 1) ? (meeting.attendance + ' person is interested in attending this meeting') : (meeting.attendance + ' people are interested in attending this meeting'))
              : ((meeting.attendance == 1) ? (meeting.attendance + ' person attended this meeting') : (meeting.attendance == 0) ? '' : (meeting.attendance + ' people attended this meeting'))
            }
            </span>

            {
              meeting.meeting_type == 'Emergency Meeting'
              ? <Button
                color="primary"
                onClick={async () => {
                  if (window.confirm('Are you sure you wish to vote for removal of this emergency meeting?')) {
                    if (await lodgeDetailsStore.suggestEmergencyMeetingRemoval(
                      lodgeDetailsStore.lodgeInfo[0].id, //lodge.id,
                      meeting.date,
                      meeting.time,
                      loginStore)) {
                        toast.success("Your suggestion has been logged!");
                    }
                    else {
                      toast.warning("Your suggestion could not be logged!");
                    }
                  }
                }}
                style={{ background: 'none', border: 'none', padding: '0px', margin: '0px'}}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Recycle-Bin-2--Streamline-Core"><desc>Recycle Bin 2 Streamline Icon: https://streamlinehq.com</desc><g id="recycle-bin-2--remove-delete-empty-bin-trash-garbage"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M2.5 2.823a0.5 0.5 0 0 0 -0.5 0.5v9.145c0 0.4 0.156 0.784 0.437 1.069 0.28 0.285 0.663 0.447 1.063 0.447h7c0.4 0 0.783 -0.162 1.063 -0.447 0.28 -0.285 0.437 -0.67 0.437 -1.07V3.324a0.5 0.5 0 0 0 -0.5 -0.5h-9Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_2" fill="#2859c5" fill-rule="evenodd" d="M5.625 7.124a0.625 0.625 0 1 0 -1.25 0v4.002a0.625 0.625 0 0 0 1.25 0V7.124Zm4 0a0.625 0.625 0 1 0 -1.25 0v4.002a0.625 0.625 0 0 0 1.25 0V7.124Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_3" fill="#2859c5" fill-rule="evenodd" d="M7 1.5a1.75 1.75 0 0 0 -1.677 1.25h3.354A1.75 1.75 0 0 0 7 1.5Zm3.211 1.25a3.25 3.25 0 0 0 -6.422 0H1a0.75 0.75 0 0 0 0 1.5h12a0.75 0.75 0 0 0 0 -1.5h-2.789Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
              </Button>
              : ''
            }

          </div>
        ))}
        {lodgeDetailsStore.lodgeMeetings.length < 1 ? '' :
          <div className="small-font">
            <br/>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Blank-Calendar--Streamline-Core" height="14" width="14"><desc>Blank Calendar Streamline Icon: https://streamlinehq.com</desc><g id="blank-calendar--blank-calendar-date-day-month-empty"><path id="Subtract" fill="#2859c5" fill-rule="evenodd" d="M3.5 0a1 1 0 0 1 1 1v1h5V1a1 1 0 0 1 2 0v1h1A1.5 1.5 0 0 1 14 3.5v1H0v-1A1.5 1.5 0 0 1 1.5 2h1V1a1 1 0 0 1 1 -1Z" clip-rule="evenodd" stroke-width="1"></path><path id="Rectangle 481" fill="#8fbffa" d="M0 4.5h14v8a1.5 1.5 0 0 1 -1.5 1.5h-11A1.5 1.5 0 0 1 0 12.5v-8Z" stroke-width="1"></path></g></svg>
            <a href={name_number} target="_blank">Download Calendar</a>
          </div>
        }
        </div>
        </CardBody>
        </Card>


        <br/>
        <div id='navigation_buttons' style={lodgeDetailsStore.isDetailsLoading ? { display: 'none' } : { display: 'block' }}>
          <Button color="" onClick={() => {
            this.props.nextStep();
            clearInterval(this.props.lodgeDetailsStore.theTimer);
            this.props.lodgeDetailsStore.theTimer = null;
            this.props.lodgeDetailsStore.todaysMeetingTime = '';
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Mail-Send-Envelope--Streamline-Core"><desc>Mail Send Envelope Streamline Icon: https://streamlinehq.com</desc><g id="mail-send-envelope--envelope-email-message-unopened-sealed-close"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M1.5 1.25A1.5 1.5 0 0 0 0 2.75v8.5a1.5 1.5 0 0 0 1.5 1.5h11a1.5 1.5 0 0 0 1.5 -1.5v-8.5a1.5 1.5 0 0 0 -1.5 -1.5h-11Z" clip-rule="evenodd" stroke-width="1"></path><path id="Intersect" fill="#2859c5" fill-rule="evenodd" d="M14 3.092 7.383 7.504A0.72 0.72 0 0 1 7 7.607a0.72 0.72 0 0 1 -0.383 -0.103L0 3.092v1.502l5.927 3.952 0.005 0.004c0.315 0.205 0.693 0.307 1.068 0.307 0.375 0 0.753 -0.102 1.068 -0.307L14 4.594V3.092Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            Contact
          </Button>
          <Button color="" onClick={() => {
            this.props.previousStep();
            clearInterval(this.props.lodgeDetailsStore.theTimer);
            this.props.lodgeDetailsStore.theTimer = null;
            this.props.lodgeDetailsStore.todaysMeetingTime = '';
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Return-2--Streamline-Core"><desc>Return 2 Streamline Icon: https://streamlinehq.com</desc><g id="return-2--arrow-return-enter-keyboard"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M6.545 0.998a1 1 0 0 0 0 2h2.728a2.638 2.638 0 0 1 0 5.275H4.817V6.545a1 1 0 0 0 -1.707 -0.707L0.384 8.564a1.004 1.004 0 0 0 -0.22 1.09c0.049 0.119 0.121 0.23 0.218 0.327l2.728 2.728a1 1 0 0 0 1.707 -0.707v-1.729h4.456a4.638 4.638 0 1 0 0 -9.275H6.545Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            Back
          </Button>
          { !lodgeDetailsStore.isMember
            ? <Button color="" onClick={() => {
              if (window.confirm('Are you sure you wish to claim membership to this lodge?')) {
                this.props.membershipClaimStep();
                clearInterval(this.props.lodgeDetailsStore.theTimer);
                this.props.lodgeDetailsStore.theTimer = null;
                this.props.lodgeDetailsStore.todaysMeetingTime = '';
                //console.log('LODGE ID:');
                //console.log(this.props.lodgeDetailsStore.lodgeInfo[0].id);

                //console.log('LODGES:');
                //console.log(this.props.loginStore.lodges[0]);
              }
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="User-Add-Plus--Streamline-Core"><desc>User Add Plus Streamline Icon: https://streamlinehq.com</desc><g id="user-add-plus--actions-add-close-geometric-human-person-plus-single-up-user"><path id="Ellipse 337" fill="#8fbffa" d="M2 3a3 3 0 1 0 6 0 3 3 0 1 0 -6 0" stroke-width="1"></path><g id="Vector"><path fill="#8fbffa" d="M8.75 8.69269C7.83382 7.65466 6.49337 6.99997 5 6.99997c-2.76142 0 -5 2.23858 -5 5.00003 0 0.2761 0.223858 0.5 0.5 0.5h6.78095C6.66612 12.1588 6.25 11.503 6.25 10.75c0 -1.10458 0.89543 -2.00001 2 -2.00001h0.5v-0.0573Z" stroke-width="1"></path></g><g id="Vector_2"><path fill="#2859c5" fill-rule="evenodd" d="M10.75 7.5c0.4142 0 0.75 0.33579 0.75 0.75V10h1.75c0.4142 0 0.75 0.3358 0.75 0.75s-0.3358 0.75 -0.75 0.75H11.5v1.75c0 0.4142 -0.3358 0.75 -0.75 0.75s-0.75 -0.3358 -0.75 -0.75V11.5H8.25c-0.41421 0 -0.75 -0.3358 -0.75 -0.75s0.33579 -0.75 0.75 -0.75H10V8.25c0 -0.41421 0.3358 -0.75 0.75 -0.75Z" clip-rule="evenodd" stroke-width="1"></path></g></g></svg>
                Claim Membership
              </Button>
            : ''}
          {loginStore.isLoggedIn ? (
            <Button color="" onClick={ () => {
              this.handleClearingOfEdit();
              clearInterval(this.props.lodgeDetailsStore.theTimer);
              this.props.lodgeDetailsStore.theTimer = null;
              this.props.lodgeDetailsStore.todaysMeetingTime = '';
              configurationStore.filterDescriptions(configurationStore.descriptionResults, lodgeDetailsStore.lodgeInfo[0].degree);
              configurationStore.filterAddresses(configurationStore.addressResults, lodgeDetailsStore.lodgeInfo[0].country);
              this.props.emergencyMeetingStep();
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Browser-Add--Streamline-Core"><desc>Browser Add Streamline Icon: https://streamlinehq.com</desc><g id="browser-add--app-code-apps-add-window-plus"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M1.75 0.046C0.81 0.046 0.047 0.81 0.047 1.751v10.498c0 0.942 0.763 1.705 1.705 1.705H12.25c0.942 0 1.705 -0.763 1.705 -1.705V1.751c0 -0.942 -0.763 -1.705 -1.705 -1.705H1.751Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_2" fill="#2859c5" d="M1.75 0.046C0.81 0.046 0.047 0.81 0.047 1.751V3.5h13.908V1.75c0 -0.94 -0.763 -1.704 -1.705 -1.704H1.751Z" stroke-width="1"></path><path id="Union_3" fill="#2859c5" fill-rule="evenodd" d="M7.75 6.227a0.75 0.75 0 0 0 -1.5 0v1.75H4.5a0.75 0.75 0 0 0 0 1.5h1.75v1.75a0.75 0.75 0 0 0 1.5 0v-1.75H9.5a0.75 0.75 0 0 0 0 -1.5H7.75v-1.75Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
              Emergency Meeting
            </Button>
          ): ''}
          <br/>
          <br/>
          {loginStore.isLoggedIn ? (
            <Button color="" onClick={ () => {
              this.handleClearingOfEdit();
              clearInterval(this.props.lodgeDetailsStore.theTimer);
              this.props.lodgeDetailsStore.theTimer = null;
              this.props.lodgeDetailsStore.todaysMeetingTime = '';
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Hand-Held-Tablet-Writing--Streamline-Core"><desc>Hand Held Tablet Writing Streamline Icon: https://streamlinehq.com</desc><g id="hand-held-tablet-writing--tablet-kindle-device-electronics-ipad-writing-digital-paper-notepad"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M1.867 0.094C0.923 0.094 0.203 0.879 0.203 1.79v10.418c0 0.912 0.72 1.697 1.664 1.697h8.225c0.944 0 1.664 -0.785 1.664 -1.697V1.844a1.75 1.75 0 0 0 -1.75 -1.75H1.867Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_2" fill="#2859c5" fill-rule="evenodd" d="M0.953 10.25h-0.75l0 0.75 0 1.21c0 0.911 0.72 1.696 1.664 1.696h8.225c0.944 0 1.664 -0.785 1.664 -1.697l0 -1.209 0 -0.75H0.953Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_3" fill="#2859c5" fill-rule="evenodd" d="M2.828 3.007a0.625 0.625 0 0 0 0 1.25h2.495a0.625 0.625 0 1 0 0 -1.25H2.828Zm0 2.868a0.625 0.625 0 0 0 0 1.25h1.248a0.625 0.625 0 1 0 0 -1.25H2.828Z" clip-rule="evenodd" stroke-width="1"></path><path id="Vector" fill="#2859c5" d="M9.402 7.394a0.5 0.5 0 0 1 -0.266 0.14l-2.148 0.386a0.5 0.5 0 0 1 -0.582 -0.573l0.359 -2.182a0.5 0.5 0 0 1 0.14 -0.273L11.021 0.796a1 1 0 0 1 1.42 0l1.06 1.06a1 1 0 0 1 0 1.42L9.402 7.394Z" stroke-width="1"></path></g></svg>
              Suggest an Edit
            </Button>
          ): ''}
        </div>
      </div>
    );
  }
}

export default LodgeDetails;

// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import {
  degreeFromId,
  degreeLogoFromId,
  orderLogoFromConstitutionAndOrder,
  checkIfFuture,
  checkIfMeetingNotLogged,
  constructWAShareLink
} from '../../helpers/utilities';
import Tour from 'reactour'

import { PromotePostStore } from '../../stores/promote-post-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { LoginStore } from '../../stores/login-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  userAccountStep: () => void;
  promotePostStore?: PromotePostStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
  isTourOpen: boolean;
}

@inject('promotePostStore', 'contributeStore', 'loginStore',)
@observer
export class PromotePost extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
      isTourOpen: false
    };
    this.setTourIsClosed = this.setTourIsClosed.bind(this);
  }

  setTourIsOpen() {
    this.setState({ isTourOpen: true });
  }
  setTourIsClosed() {
    this.setState({ isTourOpen: false });
  }

  render() {
    if (!this.props.promotePostStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const promotePostStore = this.props.promotePostStore;

    const today = new Date();
    const addOneDay = new Date(today.setDate(today.getDate() + 2));

    const steps = [
      {
        selector: '.basic-step',
        content: 'Check that you have selected the correct post. Ensure that the contact details are correct.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.cost-to-promote-step',
        content: 'Promoting the post will cost you 1 credit.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.current-balance-step',
        content: 'Your current account balance. You can click here to top up your account.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.promoting-ends-at-step',
        content: 'This post will be promoted for at least 24 hours. Appearing on the top of the feed.',
        style: {
          borderRadius: '5px',
        },
      },
      {
        selector: '.promote-step',
        content: 'If you are happy with the details, then you can click to promote this post.',
        style: {
          borderRadius: '5px',
        },
      },
      // ...
    ];

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Promote Post</u></h4>
          </Col>
        </Row>

        <Row>
        <Col sm="6">
          <Card className={`post-option-active ${
            promotePostStore.postData.sponsored
              ? 'sponsor-ad'
              : ''
            }`}>
          <CardBody className="card-padding-override">
          <Row>
            <Col className="post_header_logo">
              <img alt='logo' src={`${promotePostStore.postData.lodge_constitution != '' ? orderLogoFromConstitutionAndOrder(promotePostStore.postData.lodge_constitution, promotePostStore.postData.lodge_degree) : "logo.png"}`} height="40px"/>
            </Col>
            <Col className="post_header_title pointing-cursor">
            <span>
              {promotePostStore.postData.title}
            </span>
            <br/>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Circle-Clock--Streamline-Core"><desc>Circle Clock Streamline Icon: https://streamlinehq.com</desc><g id="circle-clock--clock-loading-measure-time-circle"><path id="Ellipse 548" fill="#8fbffa" d="M0 7a7 7 0 1 0 14 0A7 7 0 1 0 0 7" stroke-width="1"></path><path id="Vector 1528 (Stroke)" fill="#2859c5" fill-rule="evenodd" d="M7 3.875c0.345 0 0.625 0.28 0.625 0.625v2.274L9.98 9.6a0.625 0.625 0 1 1 -0.96 0.8l-2.5 -3a0.625 0.625 0 0 1 -0.145 -0.4V4.5c0 -0.345 0.28 -0.625 0.625 -0.625Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            <span className='x-small-font'> {promotePostStore.postData.date_time} </span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Earth-1--Streamline-Core"><desc>Earth 1 Streamline Icon: https://streamlinehq.com</desc><g id="earth-1--planet-earth-globe-world"><path id="Vector" fill="#8fbffa" d="M7 14A7 7 0 1 0 7 0a7 7 0 0 0 0 14Z" stroke-width="1"></path><path id="Union" fill="#2859c5" fill-rule="evenodd" d="M4.164 8.722H0.213A7 7 0 0 1 9.203 0.354v1.884a1.68 1.68 0 0 1 -1.68 1.68 1.68 1.68 0 0 0 -1.68 1.736v1.388a1.68 1.68 0 0 1 -1.68 1.68Zm8.31 -0.376 1.172 0.859a7.021 7.021 0 0 1 -3.537 4.068v-0.811a1.175 1.175 0 0 0 -1.189 -1.176 1.668 1.668 0 0 1 0 -3.322h2.024a3.431 3.431 0 0 1 1.53 0.382Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            <span className='x-small-font'>
            <a href={`http://maps.google.com/maps?q=${
              promotePostStore.postData.gps !== ''
                ? promotePostStore.postData.gps
                : ''
              }`} target="_blank">
              {promotePostStore.postData.address}
            </a>
            </span>
            <div>
              <span className="small-font-italic">
                {promotePostStore.postData.sponsored ? 'Sponsored' : promotePostStore.postData.promoted ? 'Promoted' : ''}
              </span>
            </div>
            </Col>
          </Row>

          <Row className="small-font basic-step">
            <Col>
            <span>
            {promotePostStore.postData.description}
            {promotePostStore.postData.post_type == 2
              ?
              <div>
                <br/>
                Contact: {promotePostStore.postData.contact_name}
                <br/>
                Tel: {promotePostStore.postData.contact_number}
                <br/>
                Email: {promotePostStore.postData.contact_email}
              </div>
               : ''
             }
            </span>
            </Col>
          </Row>
          <Row>
            <Col sm="12" className="post-restricted-height">
            {
              promotePostStore.postData.post_type == 1 || promotePostStore.postData.post_type == 2
              ?
              <img className='feed-wall-image' src={promotePostStore.postData.image}/>
              :
              promotePostStore.postData.image != ''
              ?
                promotePostStore.postData.image.includes('.pdf') ? (
                    <embed src={process.env.REACT_APP_API_URL + promotePostStore.postData.image+"#toolbar=0"} type="application/pdf" className="width-height-hundred"/>
                ) : (
                  <img className="feed-wall-image" src={process.env.REACT_APP_API_URL + promotePostStore.postData.image} />
                )
              : ''
            }
            </Col>
          </Row>
          </CardBody>
          </Card>
        </Col>

        <Col sm="6">
          <Card className='post-option-active'>
          <CardBody>
          <Button className="pointing-cursor promoted-sponsored-info-button" color="" onClick={async () => {
            this.setTourIsOpen();
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Help-Question-1--Streamline-Core" height="14" width="14"><desc>Help Question 1 Streamline Icon: https://streamlinehq.com</desc><g id="help-question-1--circle-faq-frame-help-info-mark-more-query-question"><path id="Ellipse 373" fill="#8fbffa" d="M0 7a7 7 0 1 0 14 0A7 7 0 1 0 0 7" stroke-width="1"></path><g id="Union"><path fill="#2859c5" d="M5.75 5.25A1.25 1.25 0 1 1 7 6.5a0.75 0.75 0 0 0 -0.75 0.75v0.646a0.75 0.75 0 1 0 1.5 0 2.751 2.751 0 1 0 -3.5 -2.646 0.75 0.75 0 0 0 1.5 0Z" stroke-width="1"></path></g><g id="Union_2"><path fill="#2859c5" d="M7 11.5a1 1 0 1 0 0 -2 1 1 0 0 0 0 2Z" stroke-width="1"></path></g></g></svg>
          </Button>
          <Row>
            <Col sm="12">
              <span className='small-font'>
                <span className="cost-to-promote-step">Cost: 1 Credit</span>
                <br/>
                <span className="current-balance-step">Current balance: <a href="#" onClick={this.props.userAccountStep}>{loginStore.user_credits} Credits</a></span>
                <br/>
                <span className="promoting-ends-at-step">Ends At: {addOneDay.toISOString().split("T")[0]+" 00:00:00"}</span>
              </span>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col sm="12" className="promote-button-col">
            <Button className="small-font promote-button promote-step" color="primary"
              onClick={async () => {
                //if (window.confirm('Are you sure you want to promote this meeting?')) {
                if (window.confirm('You are currently unable to promote this post!')) {

                  //promotePostStore.setPostData(lodge_meeting);
                  //this.props.promotePostStep();
                }
              }}>
                <span className="">Promote</span>
            </Button>
            </Col>
          </Row>
          </CardBody>
          </Card>
        </Col>
        </Row>

        <hr />
        <br />
        <div id='navigation_buttons'>
          <Button color="primary" onClick={this.props.searchStep}>
            Search
          </Button>
          {loginStore.isLoggedIn ? (
          <Button color="primary" onClick={async () => {
            this.props.contributeStore.setEdits(loginStore);
            this.props.contributeStep();
          }}>
            Contribute
          </Button>
          ): ''}
        </div>

        <Tour
        steps={steps}
        isOpen={this.state.isTourOpen}
        onRequestClose={this.setTourIsClosed}
        showNavigation={false}
        />
      </div>
    );
  }
}

export default PromotePost;

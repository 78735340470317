import * as React from 'react';
import { observer } from 'mobx-react';
import {
  Input,
  FormFeedback,
  InputGroup,
  FormGroup,
  Label,
} from 'reactstrap';

interface Props {
  id: string;
  label: string;
  value: any;
  onChange: (e: any) => void;
  invalid: boolean;
  validationMessage: string;
  readonly?: boolean;
  placeholder?: string;
}

export const TextInput: React.FC<Props> = observer((props: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e);
  };
  
  return (
    <FormGroup>
      <Label for={props.id}>{props.label}</Label>
      <InputGroup>
        <Input
          value={props.value || ''}
          onChange={handleChange}
          invalid={props.invalid}
          id={props.id}
          readOnly={props.readonly || false}
          placeholder={props.placeholder}
        />
      </InputGroup>
      <FormFeedback style={{ display: props.invalid ? 'block' : 'none' }} >
        {props.validationMessage}
      </FormFeedback>
    </FormGroup>
  )
})


export const TextArea: React.FC<Props> = observer((props: Props) => {
  return (
    <FormGroup>
      <Label for={props.id}>{props.label}</Label>
      <InputGroup>
        <Input
          type="textarea"
          value={props.value || ''}
          onChange={props.onChange}
          invalid={props.invalid}
          id={props.id}
          placeholder={props.placeholder}
        />
      </InputGroup>
      <FormFeedback style={{ display: props.invalid ? 'block' : 'none' }} >
        {props.validationMessage}
      </FormFeedback>
    </FormGroup>
  );
});

export const PasswordInput: React.FC<Props> = observer((props: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e);
  };
  return (
    <FormGroup>
      <Label for={props.id}>{props.label}</Label>
      <InputGroup>
        <Input
          type="password"
          value={props.value || ''}
          onChange={handleChange}
          invalid={props.invalid}
          id={props.id}
        />
      </InputGroup>
      <FormFeedback style={{ display: props.invalid ? 'block' : 'none' }} >
        {props.validationMessage}
      </FormFeedback>
    </FormGroup>
  )
})

// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { observer, inject } from 'mobx-react';

import { ResetPasswordEmailStore } from '../../stores/reset-password-email-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { TextInput, PasswordInput } from '../inputs/text';
import { NumberInput } from '../inputs/number';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  loginStep: () => void;
  forgotPasswordStep: () => void;
  resetPasswordEmailStore?: ResetPasswordEmailStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('loginStore', 'thankYouStore', 'contributeStore', 'resetPasswordEmailStore')
@observer
export class ResetPasswordEmail extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  render() {
    if (!this.props.resetPasswordEmailStore) {
      return;
    }

    const resetPasswordEmailStore = this.props.resetPasswordEmailStore;
    const resetPasswordEmailInput = resetPasswordEmailStore.resetPasswordEmailInput;
    const validationResult = resetPasswordEmailStore.validationResult;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h1>Reset Password</h1>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col>
            <TextInput
              id="username"
              label="Email"
              value={resetPasswordEmailInput.username}
              onChange={e =>
                (resetPasswordEmailInput.username = e.target.value)
              }
              invalid={!!validationResult.username}
              validationMessage={validationResult.username}
            />
          </Col>
        </Row>

        <div id='navigation_buttons'>
          <Button color="primary" onClick={async () => {

            resetPasswordEmailStore.validate();
            const success = resetPasswordEmailStore.checkValidation();
            //console.log('----');
            //console.log(success);
            //console.log('----');
            if (success) {
              toast.info("Confirming status");
              await resetPasswordEmailStore.confirmUser();
              if (resetPasswordEmailStore.isUser) {
                //toast.success("Welcome back!");
                //toast.success("Hi!!!");
                this.props.nextStep();
              } else {
                toast.error("An error has occured!");
              }
            }
          }}>
            Next
          </Button>


          <Button color="" onClick={async () => {
            this.props.loginStep();
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Return-2--Streamline-Core"><desc>Return 2 Streamline Icon: https://streamlinehq.com</desc><g id="return-2--arrow-return-enter-keyboard"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M6.545 0.998a1 1 0 0 0 0 2h2.728a2.638 2.638 0 0 1 0 5.275H4.817V6.545a1 1 0 0 0 -1.707 -0.707L0.384 8.564a1.004 1.004 0 0 0 -0.22 1.09c0.049 0.119 0.121 0.23 0.218 0.327l2.728 2.728a1 1 0 0 0 1.707 -0.707v-1.729h4.456a4.638 4.638 0 1 0 0 -9.275H6.545Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            Back
          </Button>

        </div>
      </div>
    );
  }
}

export default ResetPasswordEmail;

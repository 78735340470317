// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  FormGroup
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { observer, inject } from 'mobx-react';

import { AddLodgeStore } from '../../stores/add-lodge-store';
import { MembershipClaimStore } from '../../stores/membership-claim-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { ThankYouStore } from '../../stores/thank-you-store';
import { LoginStore } from '../../stores/login-store';

import { CurrencyInput } from '../inputs/currency';
import { DateInput } from '../inputs/date';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput, TextArea } from '../inputs/text';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  thankYouStep: () => void;
  lodgeDetailsStep: () => void;
  membershipClaimStore?: MembershipClaimStore;
  lodgeDetailsStore?: LodgeDetailsStore;
  thankYouStore?: ThankYouStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('membershipClaimStore', 'lodgeDetailsStore', 'thankYouStore', 'loginStore')
@observer
export class MembershipClaim extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false
    };
  }

  render() {
    if (!this.props.membershipClaimStore) {
      return;
    }

    const membershipClaimStore = this.props.membershipClaimStore;
    console.log("------- membershipClaimStore.lodgeResults -------");
    console.log(membershipClaimStore.lodgeResults);
    console.log("---------------------------------------");

    const membershipClaimInput = membershipClaimStore.membershipClaimInput;
    const validationResult = membershipClaimStore.validationResult;

    const lodgeDetailsStore = this.props.lodgeDetailsStore;//lodgeInput.lodge_id
    //console.log("------- lodgeDetailsStore.lodgeInput -------");
    //console.log(lodgeDetailsStore.lodgeInput);
    //console.log("---------------------------------------");

    const thankYouStore = this.props.thankYouStore;
    thankYouStore.typeOfThankYou = 'message';

    const loginStore = this.props.loginStore;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h1>Membership Claim</h1>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col>
            <TextInput
              id="proposer"
              label="Proposer"
              value={membershipClaimInput.proposer}
              onChange={e =>
                (membershipClaimInput.proposer = e.target.value)
              }
              invalid={!!validationResult.proposer}
              validationMessage={validationResult.proposer}
            />
          </Col>
          <Col>
            <TextInput
              id="seconder"
              label="Seconder"
              value={membershipClaimInput.seconder}
              onChange={e =>
                (membershipClaimInput.seconder = e.target.value)
              }
              invalid={!!validationResult.seconder}
              validationMessage={validationResult.seconder}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <DateInput
                label="Date Joined"
                invalid={!!validationResult.joined}
                validationMessage={validationResult.joined}
                value={membershipClaimInput.joined}
                onChange={e => {
                  membershipClaimInput.joined = e.target.value;
                }}
                id="joined"
              />
          </Col>
        </Row>

        <br/>
        <Button color="primary" onClick={async () => {
          //toast.info("TEST!");

          membershipClaimStore.validate();
          const success = membershipClaimStore.checkValidation();
          console.log('----');
          console.log(success);
          console.log('----');
          if (success) {
            if (await membershipClaimStore.claimMembership(
                  lodgeDetailsStore.lodgeInfo[0].id,
                  membershipClaimInput.proposer,
                  membershipClaimInput.seconder,
                  membershipClaimInput.joined,
                  loginStore
                )) {
                toast.success("Your claim has been logged!");
                this.props.thankYouStep();
            }
            else {
              toast.warning("Could not log your claim!");
            }
          }
        }}>
          Send
        </Button>
        <Button color="" onClick={this.props.lodgeDetailsStep}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Return-2--Streamline-Core"><desc>Return 2 Streamline Icon: https://streamlinehq.com</desc><g id="return-2--arrow-return-enter-keyboard"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M6.545 0.998a1 1 0 0 0 0 2h2.728a2.638 2.638 0 0 1 0 5.275H4.817V6.545a1 1 0 0 0 -1.707 -0.707L0.384 8.564a1.004 1.004 0 0 0 -0.22 1.09c0.049 0.119 0.121 0.23 0.218 0.327l2.728 2.728a1 1 0 0 0 1.707 -0.707v-1.729h4.456a4.638 4.638 0 1 0 0 -9.275H6.545Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
          Back
        </Button>
      </div>
    );
  }
}

export default MembershipClaim;

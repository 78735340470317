// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import {
  getShortConstitutionFromConfigsById,
  degreeFromIdNoCollective,
} from '../../helpers/utilities';

import { ContributeStore } from '../../stores/contribute-store';
import { ContributeDetailsStore } from '../../stores/contribute-details-store';
import { LoginStore } from '../../stores/login-store';
import { ThankYouStore } from '../../stores/thank-you-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { ConfigurationStore } from '../../stores/configuration-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  addLodgeStep: () => void;
  thankYouStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  loginStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  contributeStore?: ContributeStore;
  contributeDetailsStore?: ContributeDetailsStore;
  loginStore?: LoginStore;
  thankYouStore?: ThankYouStore;
  configurationStore?: ConfigurationStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('contributeStore', 'contributeDetailsStore', 'loginStore', 'thankYouStore', 'configurationStore')
@observer
export class Contribute extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };

  }

  countSuggestionType = (edits: any[], type: string): number => {
    let res = 0;
    edits.forEach((edit) => {
      if (edit.suggested_edit.suggestion_type == type) {
        res += 1;
      }
    });
    return res;
  };

  render() {
    if (!this.props.contributeStore) {
      return;
    }

    if(!this.props.loginStore.isLoggedIn)
    {
      this.props.loginStep();
    }

    const contributeStore = this.props.contributeStore;
    //console.log("------- contributeStore.lodgeResults -------");
    //console.log(contributeStore.editResults);
    //console.log("---------------------------------------");

    const loginStore = this.props.loginStore;

    const thankYouStore = this.props.thankYouStore;

    const contributeDetailsStore = this.props.contributeDetailsStore;

    const configurationStore = this.props.configurationStore;

    try {
      thankYouStore.typeOfThankYou = 'vote';
    } catch (error) {
      // Handle the error here or log it
      console.error("Error updating state:", error);
    }

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Contributions</u></h4>
          </Col>
        </Row>
        <hr/>
        <Card className="post-option-active">
          <CardBody className="top-buttons">
            <Row className="row-justified-and-centered">
              <Col>
                <div id='navigation_buttons'>
                  <Button className={`btn tab ${contributeStore.page == 0 ? 'active' : ''}`} onClick={async () => {
                    contributeStore.page = 0;
                    this.forceUpdate();
                  }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Bank--Streamline-Core"><desc>Bank Streamline Icon: https://streamlinehq.com</desc><g id="bank--institution-saving-bank-payment-finance"><path id="Subtract" fill="#2859c5" fill-rule="evenodd" d="M10.284 5.934v5.054h2.664V5.933l-0.038 0.001h-2.626Z" clip-rule="evenodd" stroke-width="1"></path><path id="Subtract_2" fill="#2859c5" fill-rule="evenodd" d="M5.668 5.934v5.054h2.664V5.934H5.668Z" clip-rule="evenodd" stroke-width="1"></path><path id="Subtract_3" fill="#2859c5" fill-rule="evenodd" d="M1.052 5.933v5.055h2.664V5.934H1.09l-0.038 0Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M6.386 0.233a1.21 1.21 0 0 1 1.248 0.013l5.911 3.87c0.42 0.277 0.547 0.754 0.385 1.161 -0.156 0.392 -0.55 0.657 -1.02 0.657H1.09c-0.47 0 -0.864 -0.265 -1.02 -0.657 -0.162 -0.407 -0.034 -0.884 0.385 -1.16l0.001 -0.001 5.91 -3.87a0.496 0.496 0 0 1 0.02 -0.013ZM0 11.877c0 -0.6 0.572 -0.889 1 -0.889h12c0.428 0 1 0.289 1 0.89v1.167c0 0.6 -0.572 0.889 -1 0.889H1c-0.428 0 -1 -0.289 -1 -0.89v-1.167Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
                    <span className="feed-button-text"> Lodges </span><span className="small-font">({this.countSuggestionType(contributeStore.editResults, "3") + this.countSuggestionType(contributeStore.editResults, "4")})</span>
                  </Button>

                  <Button className={`btn tab ${contributeStore.page == 1 ? 'active' : ''}`} onClick={async () => {
                    contributeStore.page = 1;
                    this.forceUpdate();
                  }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="User-Multiple-Group--Streamline-Core" height="14" width="14"><desc>User Multiple Group Streamline Icon: https://streamlinehq.com</desc><g id="user-multiple-group--close-geometric-human-multiple-person-up-user"><g id="Union"><path fill="#8fbffa" d="M5.04 6.755C7.3032 6.7549 8.7177 4.3048 7.586 2.3449C7.0609 1.4353 6.0903 0.875 5.04 0.875C2.7768 0.875 1.3623 3.325 2.4939 5.285C3.0191 6.1947 3.9896 6.755 5.04 6.755Z" stroke-width="1"></path><path fill="#8fbffa" d="M0.14 12.635C0.14 8.863 4.2233 6.5055 7.49 8.3915C9.0061 9.2668 9.94 10.8844 9.94 12.635C9.94 12.9056 9.7206 13.125 9.45 13.125H0.63C0.3594 13.125 0.14 12.9056 0.14 12.635Z" stroke-width="1"></path></g><path id="Subtract" fill="#2859c5" fill-rule="evenodd" d="M11.0944 13.125H13.37C13.6406 13.125 13.86 12.9056 13.86 12.635C13.8599 9.8357 11.5188 7.6054 8.7228 7.7409C10.2618 8.897 11.1665 10.7102 11.165 12.635C11.165 12.8055 11.1405 12.9702 11.0944 13.125ZM8.1094 6.6305C10.2764 7.2833 12.3374 5.3454 11.8193 3.1423C11.4312 1.4925 9.7322 0.5116 8.1094 1.0004C8.8151 1.7677 9.2062 2.7725 9.205 3.815C9.2065 4.8578 8.8153 5.863 8.1094 6.6305Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
                      <span className="feed-button-text"> Membership </span><span className="small-font">({this.countSuggestionType(contributeStore.editResults, "5")})</span>
                  </Button>

                  <Button className={`btn tab ${contributeStore.page == 2 ? 'active' : ''}`} onClick={async () => {
                    contributeStore.page = 2;
                    this.forceUpdate();
                  }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="No-Poverty--Streamline-Core" height="14" width="14"><desc>No Poverty Streamline Icon: https://streamlinehq.com</desc><g id="no-poverty"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M3.164 4.462a1.715 1.715 0 1 0 0 -3.431 1.715 1.715 0 0 0 0 3.43Zm7.672 0a1.715 1.715 0 1 0 0 -3.431 1.715 1.715 0 0 0 0 3.43Zm0 0.357a3.06 3.06 0 0 0 -1.898 0.656 2.959 2.959 0 0 1 0.95 2.917h3.521a0.5 0.5 0 0 0 0.5 -0.5 3.073 3.073 0 0 0 -3.073 -3.073Zm-7.672 0a3.06 3.06 0 0 1 1.898 0.656 2.959 2.959 0 0 0 -0.95 2.917H0.59a0.5 0.5 0 0 1 -0.5 -0.5A3.073 3.073 0 0 1 3.164 4.82ZM7 9.435a1.715 1.715 0 1 0 0 -3.43 1.715 1.715 0 0 0 0 3.43Zm-3.073 3.43a3.073 3.073 0 0 1 6.146 0 0.5 0.5 0 0 1 -0.5 0.5H4.427a0.5 0.5 0 0 1 -0.5 -0.5Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
                    <span className="feed-button-text"> Emergency Meetings </span><span className="small-font">({this.countSuggestionType(contributeStore.editResults, "6")})</span>
                  </Button>

                  <Button className={`btn tab ${contributeStore.page == 3 ? 'active' : ''}`} onClick={async () => {
                    contributeStore.page = 3;
                    this.forceUpdate();
                    //await profileStore.getUserWatchList(loginStore.miniToken);
                  }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Earth-1--Streamline-Core" height="14" width="14"><desc>Earth 1 Streamline Icon: https://streamlinehq.com</desc><g id="earth-1--planet-earth-globe-world"><path id="Vector" fill="#8fbffa" d="M7 14A7 7 0 1 0 7 0a7 7 0 0 0 0 14Z" stroke-width="1"></path><path id="Union" fill="#2859c5" fill-rule="evenodd" d="M4.164 8.722H0.213A7 7 0 0 1 9.203 0.354v1.884a1.68 1.68 0 0 1 -1.68 1.68 1.68 1.68 0 0 0 -1.68 1.736v1.388a1.68 1.68 0 0 1 -1.68 1.68Zm8.31 -0.376 1.172 0.859a7.021 7.021 0 0 1 -3.537 4.068v-0.811a1.175 1.175 0 0 0 -1.189 -1.176 1.668 1.668 0 0 1 0 -3.322h2.024a3.431 3.431 0 0 1 1.53 0.382Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
                    <span className="feed-button-text"> Addresses </span><span className="small-font">({this.countSuggestionType(contributeStore.editResults, "7")})</span>
                  </Button>

                  <Button className={`btn tab ${contributeStore.page == 4 ? 'active' : ''}`} onClick={async () => {
                    contributeStore.page = 4;
                    this.forceUpdate();
                    //await profileStore.getUserWatchList(loginStore.miniToken);
                  }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Cocktail--Streamline-Core"><desc>Cocktail Streamline Icon: https://streamlinehq.com</desc><g id="cocktail--cook-alcohol-food-cocktail-drink-cooking-nutrition-alcoholic-beverage-glass"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M2.536 0.026a1.447 1.447 0 0 0 -1.138 2.342L6.25 8.543v3.946H4a0.75 0.75 0 0 0 0 1.5h6a0.75 0.75 0 0 0 0 -1.5H7.75V8.543l4.852 -6.175A1.447 1.447 0 0 0 11.464 0.026H2.536Z" clip-rule="evenodd" stroke-width="1"></path><path id="Subtract" fill="#2859c5" fill-rule="evenodd" d="M10.197 3H3.803l2.214 2.82a1.25 1.25 0 0 0 1.966 0L10.197 3Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
                    <span className="feed-button-text"> Socials </span><span className="small-font">({this.countSuggestionType(contributeStore.editResults, "8")})</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
          <hr/>
          {contributeStore.page == 0 ? (
          <div>

          <h6>New lodges:</h6>
          <div>
            {contributeStore.editResults.map((edit, index) => (
              edit.suggested_edit.suggestion_type == "3"
                ?
              <Card className={`edit-option${
                edit.suggested_edit.user_vote_status == 'no_vote'
                  ? '-active'
                  : ''
                }`} onClick={async () => {
                  this.props.setLoadingTrue();

                  this.props.contributeDetailsStore.clearLodgeInfo();
                  this.props.contributeDetailsStore.setLodgeInfo(edit.lodge.name, edit.lodge.number, edit.lodge.degree, 0, edit);
                  // this can not fetch an existing lodges info as it is a new lodge
                  this.props.nextStep();

                  this.props.setLoadingFalse();
                }}
                >
                <CardBody>
                  {edit.lodge.name + ' No. ' + edit.lodge.number + ' ' + getShortConstitutionFromConfigsById(edit.lodge.constitution, configurationStore.constitutionResults)}
                </CardBody>
              </Card>
              : ''
            ))}
          </div>
          <br/>
          <h6>Edits:</h6>
          <div>
            {contributeStore.editResults.map((edit, index) => (
              edit.suggested_edit.suggestion_type == "4"
                ?
              <Card className={`edit-option${
                edit.suggested_edit.user_vote_status === 'no_vote'
                  ? '-active'
                  : ''
                }`} onClick={async () => {
                  this.props.setLoadingTrue();

                  this.props.contributeDetailsStore.clearLodgeInfo();
                  await this.props.contributeDetailsStore.setLodgeInfo(edit.suggested_edit.true_lodge_name, edit.suggested_edit.true_lodge_number, edit.lodge.degree, edit.lodge.lodge_id, edit);
                  // this can fetch an existing lodges info as it is an edit
                  this.props.nextStep();

                  this.props.setLoadingFalse();
                }}
                >
                <CardBody>
                  {edit.suggested_edit.true_lodge_name + ' No. ' + edit.suggested_edit.true_lodge_number + ' ' + getShortConstitutionFromConfigsById(edit.suggested_edit.true_constitution, configurationStore.constitutionResults)}
                </CardBody>
              </Card>
              : ''
            ))}
          </div>

          </div>
          )
          :
          contributeStore.page == 1 ? (
          <div>

          <h6>Claims:</h6>
          <div>
            {contributeStore.editResults.map((edit, index) => (
              edit.suggested_edit.suggestion_type == "5" && edit.user.action == 0
                ?
              <Card className={`edit-option${
                edit.suggested_edit.user_vote_status === 'no_vote'
                  ? '-active'
                  : ''
                }`} onClick={async () => {
                  this.props.setLoadingTrue();

                  this.props.contributeDetailsStore.clearLodgeInfo();
                  //await this.props.contributeDetailsStore.setLodgeInfo(edit.suggested_edit.true_lodge_name, edit.suggested_edit.true_lodge_number, edit.lodge.degree, edit.lodge.lodge_id, edit);
                  await this.props.contributeDetailsStore.setMembershipInfo(edit);
                  // this can fetch an existing lodges info as it is an edit
                  this.props.nextStep();

                  this.props.setLoadingFalse();
                }}
                >
                <CardBody>
                  <b>{edit.user.name}</b> { ' claims membership to ' } <b>{edit.suggested_edit.true_lodge_name + ' No. ' + edit.suggested_edit.true_lodge_number + ' ' + getShortConstitutionFromConfigsById(edit.suggested_edit.true_constitution, configurationStore.constitutionResults) + ' ' + degreeFromIdNoCollective(edit.lodge.degree)}</b>
                </CardBody>
              </Card>
              : ''
            ))}
          </div>
          <br/>
          <h6>Removals:</h6>
          <div>
            {contributeStore.editResults.map((edit, index) => (
              edit.suggested_edit.suggestion_type == "5" && edit.user.action == 1
                ?
              <Card className={`edit-option${
                edit.suggested_edit.user_vote_status === 'no_vote'
                  ? '-active'
                  : ''
                }`} onClick={async () => {
                  this.props.setLoadingTrue();

                  this.props.contributeDetailsStore.clearLodgeInfo();
                  //await this.props.contributeDetailsStore.setLodgeInfo(edit.suggested_edit.true_lodge_name, edit.suggested_edit.true_lodge_number, edit.lodge.degree, edit.lodge.lodge_id, edit);
                  await this.props.contributeDetailsStore.setMembershipInfo(edit);
                  // this can fetch an existing lodges info as it is an edit
                  this.props.nextStep();

                  this.props.setLoadingFalse();
                }}
                >
                <CardBody>
                  Remove <b>{edit.user.name}</b> from <b>{edit.suggested_edit.true_lodge_name + ' No. ' + edit.suggested_edit.true_lodge_number + ' ' + getShortConstitutionFromConfigsById(edit.suggested_edit.true_constitution, configurationStore.constitutionResults) + ' ' + degreeFromIdNoCollective(edit.lodge.degree)}</b>
                </CardBody>
              </Card>
              : ''
            ))}
          </div>

          </div>
          )
          :
          contributeStore.page == 2 ? (
          <div>

          <h6>Proposals:</h6>
          <div>
            {contributeStore.editResults.map((edit, index) => (
              edit.suggested_edit.suggestion_type == "6" && edit.emergency_meeting.action == 0
                ?
              <Card className={`edit-option${
                edit.suggested_edit.user_vote_status === 'no_vote'
                  ? '-active'
                  : ''
                }`} onClick={async () => {
                  this.props.setLoadingTrue();

                  this.props.contributeDetailsStore.clearLodgeInfo();
                  //await this.props.contributeDetailsStore.setLodgeInfo(edit.suggested_edit.true_lodge_name, edit.suggested_edit.true_lodge_number, edit.lodge.degree, edit.lodge.lodge_id, edit);
                  await this.props.contributeDetailsStore.setMeetingInfo(edit);
                  // this can fetch an existing lodges info as it is an edit
                  this.props.nextStep();

                  this.props.setLoadingFalse();
                }}
                >
                <CardBody>
                  {edit.suggested_edit.true_lodge_name + ' No. ' + edit.suggested_edit.true_lodge_number + ' ' + getShortConstitutionFromConfigsById(edit.suggested_edit.true_constitution, configurationStore.constitutionResults) + ' ' + degreeFromIdNoCollective(edit.lodge.degree)} on <b>{edit.emergency_meeting.date}</b>
                </CardBody>
              </Card>
              : ''
            ))}
          </div>
          <br/>
          <h6>Removals:</h6>
          <div>
            {contributeStore.editResults.map((edit, index) => (
              edit.suggested_edit.suggestion_type == "6" && edit.emergency_meeting.action == 1
                ?
              <Card className={`edit-option${
                edit.suggested_edit.user_vote_status === 'no_vote'
                  ? '-active'
                  : ''
                }`} onClick={async () => {
                  this.props.setLoadingTrue();

                  this.props.contributeDetailsStore.clearLodgeInfo();
                  //await this.props.contributeDetailsStore.setLodgeInfo(edit.suggested_edit.true_lodge_name, edit.suggested_edit.true_lodge_number, edit.lodge.degree, edit.lodge.lodge_id, edit);
                  await this.props.contributeDetailsStore.setMeetingInfo(edit);
                  // this can fetch an existing lodges info as it is an edit
                  this.props.nextStep();

                  this.props.setLoadingFalse();
                }}
                >
                <CardBody>
                  {edit.suggested_edit.true_lodge_name + ' No. ' + edit.suggested_edit.true_lodge_number + ' ' + getShortConstitutionFromConfigsById(edit.suggested_edit.true_constitution, configurationStore.constitutionResults) + ' ' + degreeFromIdNoCollective(edit.lodge.degree)} on <b>{edit.emergency_meeting.date}</b>
                </CardBody>
              </Card>
              : ''
            ))}
          </div>

          </div>
          )
          :
          contributeStore.page == 3 ? (
          <div>
            <h6>New locations:</h6>
            <div>
              {contributeStore.editResults.map((edit, index) => (
                edit.suggested_edit.suggestion_type == "7"
                  ?
                <Card className={`edit-option${
                  edit.suggested_edit.user_vote_status == 'no_vote'
                    ? '-active'
                    : ''
                  }`} onClick={async () => {
                    this.props.setLoadingTrue();

                    this.props.contributeDetailsStore.clearLodgeInfo();
                    this.props.contributeDetailsStore.setLocationInfo(edit);
                    // this can not fetch an existing lodges info as it is a new lodge
                    this.props.nextStep();

                    this.props.setLoadingFalse();
                  }}
                  >
                  <CardBody>
                    {edit.location.name}
                  </CardBody>
                </Card>
                : ''
              ))}
            </div>
          </div>
          )
          :
          contributeStore.page == 4 ? (
          <div>
            <h6>Socials:</h6>
            <div>
              {contributeStore.editResults.map((edit, index) => (
                edit.suggested_edit.suggestion_type == "8"
                  ?
                <Card className={`edit-option${
                  edit.suggested_edit.user_vote_status == 'no_vote'
                    ? '-active'
                    : ''
                  }`} onClick={async () => {
                    this.props.setLoadingTrue();

                    this.props.contributeDetailsStore.clearLodgeInfo();
                    this.props.contributeDetailsStore.setLocationInfo(edit);
                    // this can not fetch an existing lodges info as it is a new lodge
                    this.props.nextStep();

                    this.props.setLoadingFalse();
                  }}
                  >
                  <CardBody>
                    {edit.social.title}
                  </CardBody>
                </Card>
                : ''
              ))}
            </div>
          </div>
          )
          :
          ''
          }

        <hr/>
        <br/>
        <div id='navigation_buttons' style={contributeStore.isLoading ? { display: 'none' } : { display: 'block' }}>
          <Button color="primary" onClick={this.props.searchStep}>
            Search
          </Button>
          <Button color="primary" onClick={contributeStore.toggleCallMeModal}>
            Contribute more
          </Button>
        </div>
      </div>
    );
  }
}

export default Contribute;

/* global fetch:false Request:false */
const key = process.env.REACT_APP_API_KEY;
const auth = 'Bearer ' + key;
const sandbox = process.env.REACT_APP_SANDBOX;
const basePath = process.env.REACT_APP_API_URL;

const makeRequest = (method, data, path, cb) => {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': auth,
      //'Access-Control-Allow-Origin': 'https://freemason-alpha.com', // Replace with your desired origin
      'Access-Control-Allow-Origin': '*', // Replace with your desired origin
      'Access-Control-Allow-Methods': '*',
      'Accept': '*/*',
    },
    body: data && JSON.stringify(data),
  };
  const request = new Request(basePath + path);
  return fetch(request, options).then((response) => {
    console.log(request);
    console.log(options);
    console.log(response);
    return response.json().then((r) => {
      if (response.status === 200) {
        return r;
      } else {
        return Promise.reject(r);
      }
    });
  });
};

const makeRequestWithToken = (method, token, data, path, cb) => {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': auth,
      // 'Access-Control-Allow-Origin': 'https://freemason-alpha.com', // Replace with your desired origin
      'Access-Control-Allow-Origin': '*', // Replace with your desired origin
      'Access-Control-Allow-Methods': '*',
      'Accept': '*/*',
      /*'CSRF-TOKEN': token*/
    },
    body: data && JSON.stringify(data),
  };
  console.log('************');
  console.log(JSON.stringify(data));
  console.log('************');
  const request = new Request(basePath + path);
  return fetch(request, options).then((response) => {
    // console.log(request);
    // console.log(options);
    // console.log(response);
    return response.json().then((r) => {
      if (response.status === 200) {
        return r;
      } else {
        return Promise.reject(r);
      }
    });
  });
};

const makeRequestWithFileData = (method, data, path, cb) => {
  const options = {
    method,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': auth,
      //'Access-Control-Allow-Origin': 'https://freemason-alpha.com', // Replace with your desired origin
      'Access-Control-Allow-Origin': '*', // Replace with your desired origin
      'Access-Control-Allow-Methods': '*',
      'Accept': '*/*',
    },
    body: data && JSON.stringify(data),
  };
  const request = new Request(basePath + path);
  return fetch(request, options).then((response) => {
    console.log(request);
    console.log(options);
    console.log(response);
    return response.json().then((r) => {
      if (response.status === 200) {
        return r;
      } else {
        return Promise.reject(r);
      }
    });
  });
};

export default {
  getToken: (data) => makeRequest('GET', data, '/api/token'),
  // Lodge endpoints
  getAllLodges: (data) => makeRequest('GET', null, '/api/lodges'),
  getAllLodgesSearch: (search, country, district, constitution, degree, address, today, data) => makeRequest('POST', data, `/api/lodges-search/${search}/${country}/${district}/${constitution}/${degree}/${address}/${today}`),
  getAllTheLodges: (constitution, data) => makeRequest('GET', null, `/api/thelodges/${constitution}`),
  getLodgeInfo: (lodge_name, lodge_number, degree, data) => makeRequest('GET', null, `/api/thelodgeinfo/${lodge_name}/${lodge_number}/${degree}`),
  getLodgeInfoTwo: (data) => makeRequest('POST', data, `/api/thelodgeinfotwo`),
  getLodgeMeetings: (lodge_name, lodge_number, degree, data) => makeRequest('GET', null, `/api/getlodgemeetings/${lodge_name}_${lodge_number}_${degree}`),
  getLodgeMeetingsTwo: (lodge_name, lodge_number, degree, data) => makeRequest('GET', null, `/api/getlodgemeetingstwo/${lodge_name}_${lodge_number}_${degree}`),
  getLodgeInfoById: (lodge_id, data) => makeRequest('GET', null, `/api/thelodgeinfobyid/${lodge_id}`),
  getLodgeMeetingsById: (lodge_id, data) => makeRequest('GET', null, `/api/getlodgemeetingsbyid/${lodge_id}`),
  getLodgeMeetingsTwoById: (lodge_id, data) => makeRequest('GET', null, `/api/getlodgemeetingstwobyid/${lodge_id}`),

  // Suggestion endpoints
  makeSuggestion: (token, data) => makeRequestWithToken('POST', token, data, '/api/MakeSuggestion'),
  // Contribute endpoints
  //getAllSuggestedEdits: (data) => makeRequest('GET', null, '/api/AllSuggestedEdits'),
  getAllSuggestedEdits: (data) => makeRequest('POST', data, '/api/AllSuggestedEdits'),
  // Cast Vote endpoint
  castVote: (token, data) => makeRequestWithToken('POST', token, data, '/api/CastVote'),

  loginUser: (token, data) => makeRequestWithToken('POST', token, data, '/api/loginuser'),

  confirmUser: (token, data) => makeRequestWithToken('POST', token, data, '/api/confirmuser'),

  confirmCode: (token, data) => makeRequestWithToken('POST', token, data, '/api/confirmcode'),

  changePassword: (token, data) => makeRequestWithToken('POST', token, data, '/api/changenreset'),

  // Configuration
  //getAllAddresses: (data) => makeRequest('GET', null, '/api/getAddresses'),
  //getAllConstitutions: (data) => makeRequest('GET', null, '/api/getConstitutions'),
  //getAllCountries: (data) => makeRequest('GET', null, '/api/getCountries'),
  //getAllDistricts: (data) => makeRequest('GET', null, '/api/getDistricts'),
  //getAllDegrees: (data) => makeRequest('GET', null, '/api/getDegrees'),
  //getAllDescriptions: (data) => makeRequest('GET', null, '/api/getDescriptions'),
  // to replace the above 6 api calls with 1
  getConfigurations: (data) => makeRequest('GET', null, '/api/getConfigurations'),


  // Diagram
  getDiagramData: (data) => makeRequest('GET', null, '/api/getDiagramData'),

  // Raffle Users
  getAllUsers: (data) => makeRequest('GET', null, '/api/getAllUsers'),

  // 2FA Code
  // generateTwoFA: data => makeRequest('GET', data, '/api/gen2FA'),

  // Confirm Referral Code
  confirmReferralCode: (token, data) => makeRequestWithToken('POST', token, data, '/api/register-pre'),

  // Register User
  registerUser: (token, data) => makeRequestWithToken('POST', token, data, '/api/register'),

  // 2FA Code Confirmation
  confirm2FACode: (token, data) => makeRequestWithToken('POST', token, data, '/api/register-confirmation'),

  // contact lodge
  contactLodge: (token, data) => makeRequestWithToken('POST', token, data, '/api/make-contact'),

  // contact lodge
  provideFeedback: (token, data) => makeRequestWithToken('POST', token, data, '/api/user-feedback'),

  // ???? what is this ????
  // contact lodge
  //claimMembership: (token, data) => makeRequestWithToken('POST', token, data, '/api/membership-claim'),

  logVisit: (data) => makeRequest('POST', data, '/api/logVisit'),

  updateNotifications: (data) => makeRequest('POST', data, '/api/updateNotifications'),

  updateWatchList: (data) => makeRequest('POST', data, '/api/updateWatchList'),

  getUserWatchList: (data) => makeRequest('POST', data, '/api/getUserWatchList'),

  logAttendance: (token, data) => makeRequestWithToken('POST', token, data, '/api/logAttendance'),
  removeLogAttendance: (token, data) => makeRequestWithToken('POST', token, data, '/api/removeLogAttendance'),

  getAllImpromptuMeetings: (data) => makeRequest('POST', data, '/api/getAllImpromptuMeetings'),

  getAllLeaderboards: (data) => makeRequest('POST', data, '/api/getAllLeaderboards'),

  getAchievementsStructureData: (data) => makeRequest('POST', data, '/api/getAchievementsStructureData'),

  getUserFeed: (data) => makeRequest('POST', data, '/api/getUserFeed'),
  getUserFeedWallOnly: (data) => makeRequest('POST', data, '/api/getUserFeedWallOnly'),

  getUserPublicProfile: (data) => makeRequest('POST', data, '/api/getUserPublicProfile'),

  getUserProfileFeed: (data) => makeRequest('POST', data, '/api/getUserProfileFeed'),

  postToUserProfileFeed: (data) => makeRequest('POST', data, '/api/postToUserProfileFeed'),

  sponsoredPostImpression: (data) => makeRequest('POST', data, '/api/sponsoredPostImpression'),

  cancelSponsoredPost: (data) => makeRequest('POST', data, '/api/cancelSponsoredPost'),

  /*getQuote: (type, data) => makeRequest('POST', { type, ...data }, '/v1/insurance/quotes'),
  createPolicyholder: data => makeRequest('POST', data, '/v1/insurance/policyholders'),
  updatePolicyholder: data => makeRequest('PUT', data, `/v1/insurance/policyholders`),
  createApplication: data => makeRequest('POST', data, '/v1/insurance/applications'),
  createPolicy: data => makeRequest('POST', data, '/v1/insurance/policies'),
  addPaymentMethod: (policyholderId, data) =>
    makeRequest('POST', data, `/v1/insurance/policyholders/${policyholderId}/payment-methods`),
  createAttachment: (policyId, data) => makeRequest('POST', data, `/v1/insurance/policies/${policyId}/attachments`),
  createBeneficiaries: (policyId, data) => makeRequest('PUT', data, `/v1/insurance/policies/${policyId}/beneficiaries`),
  callRequest: data => makeRequest('POST', data, '/call-request'),*/
};

// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { observer, inject } from 'mobx-react';

import { FeedbackStore } from '../../stores/feedback-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { ThankYouStore } from '../../stores/thank-you-store';
import { LoginStore } from '../../stores/login-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput, TextArea } from '../inputs/text';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  thankYouStep: () => void;
  feedbackStore?: FeedbackStore;
  contributeStore?: ContributeStore;
  thankYouStore?: ThankYouStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('feedbackStore', 'contributeStore', 'loginStore', 'thankYouStore')
@observer
export class Feedback extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  render() {
    if (!this.props.feedbackStore) {
      return;
    }

    const thankYouStore = this.props.thankYouStore;
    //thankYouStore.typeOfThankYou = 'feedback';// throwing an error after feedback submitted, then renavigating to feedback page

    const loginStore = this.props.loginStore;
    const feedbackStore = this.props.feedbackStore;
    const feedbackInput = feedbackStore.feedbackInput;
    const validationResult = feedbackStore.validationResult;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Feedback</u></h4>
          </Col>
        </Row>

        <Card className='post-option-active'>
        <CardBody>
        <span className="small-font-italic">
        Your feedback stands as a cornerstone in our quest for excellence within
        the Freemason Travellers Guide. Thank you for your contributions and valued
        input, which creates a more enriching experience for fellow masons, and guides
        us as we sculpt our shared path of exploration and discovery.
        </span>
        <br/><br/>
        <Row>
          <Col>
            <TextArea
              id="message"
              label="Message:"
              value={feedbackInput.message}
              onChange={e =>
                (feedbackInput.message = e.target.value)
              }
              invalid={!!validationResult.message}
              validationMessage={validationResult.message}
            />
          </Col>
        </Row>
        <br/>
        <Button color="primary" onClick={async () => {
          if (await feedbackStore.provideFeedback(
                feedbackInput.message,
                loginStore
              )) {
              toast.success("Your feedback has been submitted!");
              thankYouStore.typeOfThankYou = 'feedback';
              this.props.thankYouStep();
              feedbackInput.message = ""; // reset the feedback message after submission
          }
          else {
            toast.warning("Could not submit your feedback!");
          }
        }}>
          Submit
        </Button>

        </CardBody>
        </Card>

        <hr />
        <br />
        <div id='navigation_buttons'>
          <Button color="primary" onClick={this.props.searchStep}>
            Search
          </Button>
          {loginStore.isLoggedIn ? (
          <Button color="primary" onClick={async () => {
            this.props.contributeStore.setEdits(loginStore);
            this.props.contributeStep();
          }}>
            Contribute
          </Button>
          ): ''}
        </div>
      </div>
    );
  }
}

export default Feedback;

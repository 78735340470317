// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardBody
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { ImpromptuMeetingSetupImageStore } from '../../stores/impromptu-meeting-setup-image-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LoginStore } from '../../stores/login-store';

interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  raffleNameStep: () => void;
  addLodgeStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  impromptuMeetingSetupImageStore?: ImpromptuMeetingSetupImageStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  valid: boolean;
}

@inject('impromptuMeetingSetupImageStore', 'contributeStore', 'loginStore')
@observer
export class ImpromptuMeetingSetupImage extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: true,
    };
  }

  validateFileType = (event) => {
    const selectedFile = event.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/png'];

    if (!allowedTypes.includes(selectedFile.type)) {
      alert('Invalid file type. Please upload a JPEG or PNG file.');
      return false;
    }
    return true;
  }

  handleFileChange = (event) => {
    if (this.validateFileType(event)) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        // Get the file data as Base64 string
        const base64String = reader.result as string;
        this.props.impromptuMeetingSetupImageStore.image_file_data = base64String;
        this.props.impromptuMeetingSetupImageStore.image_file_name = file.name;
      };

      reader.readAsDataURL(file); // Read file as a Data URL (Base64 string)

      // Create a second FileReader to read as binary string
      const binaryReader = new FileReader();

      binaryReader.onloadend = () => {
        const binaryString = binaryReader.result as string; // This is the binary data
        const base64Binary = btoa(binaryString); // Convert binary string to Base64
        this.props.impromptuMeetingSetupImageStore.image_file_binary = base64Binary;

        // Optionally: Log or process the binary data as needed
        //console.log("Binary Data (Base64 Encoded):", base64Binary);
      };

      binaryReader.readAsBinaryString(file); // Read file as binary string

    } else {
      this.props.impromptuMeetingSetupImageStore.image_file_data = '';
      this.props.impromptuMeetingSetupImageStore.image_file_name = '';
      this.props.impromptuMeetingSetupImageStore.image_file_binary = ''; //
    }
  };

  render() {
    const { impromptuMeetingSetupImageStore, loginStore } = this.props;

    if (!impromptuMeetingSetupImageStore) {
      return null;
    }

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Setup Social Event - Image</u></h4>
          </Col>
        </Row>

        <Card className='post-option-active'>
          <CardBody>
            <div className="row row-justified">
              <div>
                <Row>
                  <Col>
                    {/* Display the uploaded image */}
                    {impromptuMeetingSetupImageStore.image_file_data && (
                      <img src={impromptuMeetingSetupImageStore.image_file_data} alt="Uploaded" style={{ maxWidth: '100%' }} />
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <input type="file" onChange={this.handleFileChange} />
                  </Col>
                </Row>
              </div>
            </div>
          </CardBody>
        </Card>

        <br />
        <Button color="primary" onClick={this.props.nextStep}>
          Next
        </Button>
        <Button color="primary" onClick={this.props.previousStep}>
          Back
        </Button>
        <hr />
        <br />
        <div id='navigation_buttons'>
          <Button color="primary" onClick={this.props.searchStep}>
            Search
          </Button>
          {loginStore?.isLoggedIn ? (
            <Button color="primary" onClick={async () => {
              this.props.contributeStore.setEdits(loginStore);
              this.props.contributeStep();
            }}>
              Contribute
            </Button>
          ) : ''}
        </div>
      </div>
    );
  }
}

export default ImpromptuMeetingSetupImage;

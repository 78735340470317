// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';

import { AchievementsStore } from '../../stores/achievements-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { LoginStore } from '../../stores/login-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  achievementsStore?: AchievementsStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('achievementsStore', 'contributeStore', 'loginStore',)
@observer
export class Achievements extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  render() {
    if (!this.props.achievementsStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const achievementsStore = this.props.achievementsStore;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h1>Achievements</h1>
          </Col>
        </Row>
        <br />
        <Card>
        <CardBody>
        <div className='leaderboard'>
        <table>
          <tr>
            <th>Contribution</th>
            <th>Points earned</th>
          </tr>
        <tbody>
          {achievementsStore.achievementResults.achievement_contributions.map((contribution_data, index) => (
            <tr>
              <td className='username'>{contribution_data.contribution}</td>
              <td className='username'>{contribution_data.points_earned} points per {contribution_data.contribution}</td>
            </tr>
          ))}
        </tbody>
        </table>
        </div>
        </CardBody>
        </Card>

        <hr />
        <Card>
        <CardBody>
        <div className='leaderboard'>
        <table>
          <tr>
            <th>Level</th>
            <th>Points</th>
            <th>Badge</th>
          </tr>
        <tbody>
          {achievementsStore.achievementResults.achievement_levels.map((level_data, index) => (
            <tr>
              <td className='rank'>{level_data.level}</td>
              <td className='username'>{level_data.points}</td>
              <td className='username'>{level_data.badge}</td>
            </tr>
          ))}
        </tbody>
        </table>
        </div>
        </CardBody>
        </Card>
        <hr />
        <br />
        <div id='navigation_buttons'>
          <Button color="" onClick={this.props.searchStep}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Definition-Search-Book--Streamline-Core"><desc>Definition Search Book Streamline Icon: https://streamlinehq.com</desc><g id="definition-search-book"><path id="Subtract" fill="#8fbffa" fill-rule="evenodd" d="M0.5 0.125a0.5 0.5 0 0 0 -0.5 0.5v10a0.5 0.5 0 0 0 0.5 0.5h4.756A4.492 4.492 0 0 1 5 9.625c0 -1.271 0.527 -2.42 1.375 -3.238v-4.96A2 2 0 0 0 4.5 0.126h-4Zm7.125 1.303v4.105a4.5 4.5 0 0 1 6.347 3.584H14V0.625a0.5 0.5 0 0 0 -0.5 -0.5h-4a2 2 0 0 0 -1.875 1.303Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union" fill="#2859c5" fill-rule="evenodd" d="M7.75 9.625a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1 -3.5 0Zm1.75 -3.25a3.25 3.25 0 1 0 1.706 6.017l1.263 1.263a0.75 0.75 0 0 0 1.06 -1.06l-1.262 -1.264A3.25 3.25 0 0 0 9.5 6.375Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            Search
          </Button>
          {loginStore.isLoggedIn ? (
          <Button color="" onClick={async () => {
            this.props.contributeStore.setEdits(loginStore);
            this.props.contributeStep();
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Application-Add--Streamline-Core"><desc>Application Add Streamline Icon: https://streamlinehq.com</desc><g id="application-add--application-new-add-square"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M4.846 0h-3.77C0.483 0 0 0.482 0 1.077v3.77c0 0.594 0.482 1.076 1.077 1.076h3.77c0.594 0 1.076 -0.482 1.076 -1.077v-3.77C5.923 0.483 5.441 0 4.846 0Zm8.077 8.077h-3.77c-0.594 0 -1.076 0.482 -1.076 1.077v3.77c0 0.594 0.482 1.076 1.077 1.076h3.77c0.594 0 1.076 -0.482 1.076 -1.077v-3.77c0 -0.594 -0.482 -1.076 -1.077 -1.076Zm-11.846 0h3.77c0.594 0 1.076 0.482 1.076 1.077v3.77c0 0.594 -0.482 1.076 -1.077 1.076h-3.77A1.077 1.077 0 0 1 0 12.923v-3.77c0 -0.594 0.482 -1.076 1.077 -1.076Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_2" fill="#2859c5" fill-rule="evenodd" d="M11.51 0.75a0.75 0.75 0 1 0 -1.5 0v1.733H8.279a0.75 0.75 0 1 0 0 1.5h1.733v1.732a0.75 0.75 0 0 0 1.5 0V3.983h1.732a0.75 0.75 0 1 0 0 -1.5h-1.732V0.75Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            Contribute
          </Button>
          ): ''}
        </div>
      </div>
    );
  }
}

export default Achievements;

// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { observer, inject } from 'mobx-react';

import { ResetPasswordConfirmStore } from '../../stores/reset-password-confirm-store';
import { ResetPasswordEmailStore } from '../../stores/reset-password-email-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { TextInput, PasswordInput } from '../inputs/text';
import { NumberInput } from '../inputs/number';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  loginStep: () => void;
  forgotPasswordStep: () => void;
  resetPasswordConfirmStore?: ResetPasswordConfirmStore;
  resetPasswordEmailStore?: ResetPasswordEmailStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('resetPasswordConfirmStore', 'resetPasswordEmailStore')
@observer
export class ResetPasswordConfirm extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  render() {
    if (!this.props.resetPasswordConfirmStore) {
      return;
    }

    const resetPasswordEmailStore = this.props.resetPasswordEmailStore;

    const resetPasswordConfirmStore = this.props.resetPasswordConfirmStore;
    const resetPasswordConfirmInput = resetPasswordConfirmStore.resetPasswordConfirmInput;
    const validationResult = resetPasswordConfirmStore.validationResult;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h1>Reset Password</h1>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col>
            <TextInput
              id="code"
              label="Confirmation Code"
              value={resetPasswordConfirmInput.code}
              onChange={e =>
                (resetPasswordConfirmInput.code = e.target.value)
              }
              invalid={!!validationResult.code}
              validationMessage={validationResult.code}
            />
          </Col>
        </Row>

        <div id='navigation_buttons'>
          <Button color="primary" onClick={async () => {

            resetPasswordConfirmStore.validate();
            const success = resetPasswordConfirmStore.checkValidation();
            //console.log('----');
            //console.log(success);
            //console.log('----');
            if (success) {
              //toast.info("Confirming status");
              if (await resetPasswordConfirmStore.confirmCode(resetPasswordEmailStore.resetPasswordEmailInput.username)) {
                //toast.success("Welcome back!");
                toast.success("Temporary password sent!");
                this.props.loginStep();
              } else {
                toast.error("An error has occured!");
              }
            }
          }}>
            Next
          </Button>

        </div>
      </div>
    );
  }
}

export default ResetPasswordConfirm;

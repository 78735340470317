// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { Line, Circle } from 'rc-progress';

import { FeedWallLeftPanelStore } from '../../stores/feed-wall-left-panel-store';
import { FeedWallStore } from '../../stores/feed-wall-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { LoginStore } from '../../stores/login-store';
import { AchievementsStore } from '../../stores/achievements-store';
import { LeaderboardStore } from '../../stores/leaderboard-store';
import { ViewProfileStore } from '../../stores/view-profile-store';

import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';

interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  profileStep: () => void;
  leaderboardStep: () => void;
  viewProfileStep: () => void;
  feedWallLeftPanelStore?: FeedWallLeftPanelStore;
  feedWallStore?: FeedWallStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
  achievementsStore?: AchievementsStore;
  leaderboardStore?: LeaderboardStore;
  viewProfileStore?: ViewProfileStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject(
  'feedWallLeftPanelStore',
  'contributeStore',
  'loginStore',
  'feedWallStore',
  'achievementsStore',
  'leaderboardStore',
  'viewProfileStore'
)
@observer
export class FeedWallLeftPanel extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  render() {
    if (!this.props.feedWallLeftPanelStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const feedWallLeftPanelStore = this.props.feedWallLeftPanelStore;
    const feedWallStore = this.props.feedWallStore;
    const achievementsStore = this.props.achievementsStore;
    const leaderboardStore = this.props.leaderboardStore;
    const viewProfileStore = this.props.viewProfileStore;

    return (
      <div>
        <div id="stuck-element" className='feed-wall-right-panel-standard profile-navigate-step'>
        <Row>
          <Col className="leader-board-lp-card-body-profile">
            <div>
              <img className='profile-picture' src='logo.png' alt="logo" height="60px" />
            </div>
            <h5 onClick={() => {
               this.props.profileStep();
             }}>{loginStore.username}
             </h5>
          </Col>
        </Row>
        {/*<Row>*/}
        {/*<Col>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Chat-Bubble-Text-Square--Streamline-Core"><desc>Chat Bubble Text Square Streamline Icon: https://streamlinehq.com</desc><g id="chat-bubble-text-square--messages-message-bubble-text-square-chat"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M2.5 0A1.5 1.5 0 0 0 1 1.5v8.919l-0.974 2.923a0.5 0.5 0 0 0 0.595 0.643L4.561 13H12.5a1.5 1.5 0 0 0 1.5 -1.5v-10A1.5 1.5 0 0 0 12.5 0h-10Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_2" fill="#2859c5" fill-rule="evenodd" d="M4.5 4.22a0.625 0.625 0 1 0 0 1.25h6a0.625 0.625 0 1 0 0 -1.25h-6Zm0 3.31a0.625 0.625 0 1 0 0 1.25h4a0.625 0.625 0 1 0 0 -1.25h-4Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
        </Col>*/}
          {/*<Col className='level-point-progress-level'>
            Level {loginStore.level}
          </Col>*/}
          {/*<Col>
            <div title={parseFloat(loginStore.percentage.toString()).toFixed(2).toString() + '%'} >
              <Line percent={loginStore.percentage} strokeWidth={4} trailWidth={3} strokeColor="green" />
            </div>
          </Col>*/}
          {/*<Col className='level-point-progress-points'>
            <span title={parseInt(loginStore.points.toString()) + ' / ' + parseInt((loginStore.points_high).toString())}>
            Points {parseInt(loginStore.points.toString())}
            </span>
          </Col>*/}
        {/*</Row>*/}
        </div>
        <hr/>
        <div className="your-contributions-step">
        <b>Your contributions</b>
        <br />
        <Card className="leader-board-lp x-small-font">
        <CardBody className="card-padding-override">
        <Row className="leader-board-lp-row">
          <Col>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Bank--Streamline-Core"><desc>Bank Streamline Icon: https://streamlinehq.com</desc><g id="bank--institution-saving-bank-payment-finance"><path id="Subtract" fill="#2859c5" fill-rule="evenodd" d="M10.284 5.934v5.054h2.664V5.933l-0.038 0.001h-2.626Z" clip-rule="evenodd" stroke-width="1"></path><path id="Subtract_2" fill="#2859c5" fill-rule="evenodd" d="M5.668 5.934v5.054h2.664V5.934H5.668Z" clip-rule="evenodd" stroke-width="1"></path><path id="Subtract_3" fill="#2859c5" fill-rule="evenodd" d="M1.052 5.933v5.055h2.664V5.934H1.09l-0.038 0Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M6.386 0.233a1.21 1.21 0 0 1 1.248 0.013l5.911 3.87c0.42 0.277 0.547 0.754 0.385 1.161 -0.156 0.392 -0.55 0.657 -1.02 0.657H1.09c-0.47 0 -0.864 -0.265 -1.02 -0.657 -0.162 -0.407 -0.034 -0.884 0.385 -1.16l0.001 -0.001 5.91 -3.87a0.496 0.496 0 0 1 0.02 -0.013ZM0 11.877c0 -0.6 0.572 -0.889 1 -0.889h12c0.428 0 1 0.289 1 0.89v1.167c0 0.6 -0.572 0.889 -1 0.889H1c-0.428 0 -1 -0.289 -1 -0.89v-1.167Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            <span> Lodges added: </span>
            <span>{loginStore.number_of_lodges}</span>
          </Col>
        </Row>
        <Row className="leader-board-lp-row">
          <Col>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Hand-Held-Tablet-Writing--Streamline-Core"><desc>Hand Held Tablet Writing Streamline Icon: https://streamlinehq.com</desc><g id="hand-held-tablet-writing--tablet-kindle-device-electronics-ipad-writing-digital-paper-notepad"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M1.867 0.094C0.923 0.094 0.203 0.879 0.203 1.79v10.418c0 0.912 0.72 1.697 1.664 1.697h8.225c0.944 0 1.664 -0.785 1.664 -1.697V1.844a1.75 1.75 0 0 0 -1.75 -1.75H1.867Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_2" fill="#2859c5" fill-rule="evenodd" d="M0.953 10.25h-0.75l0 0.75 0 1.21c0 0.911 0.72 1.696 1.664 1.696h8.225c0.944 0 1.664 -0.785 1.664 -1.697l0 -1.209 0 -0.75H0.953Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_3" fill="#2859c5" fill-rule="evenodd" d="M2.828 3.007a0.625 0.625 0 0 0 0 1.25h2.495a0.625 0.625 0 1 0 0 -1.25H2.828Zm0 2.868a0.625 0.625 0 0 0 0 1.25h1.248a0.625 0.625 0 1 0 0 -1.25H2.828Z" clip-rule="evenodd" stroke-width="1"></path><path id="Vector" fill="#2859c5" d="M9.402 7.394a0.5 0.5 0 0 1 -0.266 0.14l-2.148 0.386a0.5 0.5 0 0 1 -0.582 -0.573l0.359 -2.182a0.5 0.5 0 0 1 0.14 -0.273L11.021 0.796a1 1 0 0 1 1.42 0l1.06 1.06a1 1 0 0 1 0 1.42L9.402 7.394Z" stroke-width="1"></path></g></svg>
            <span> Edits: </span>
            <span>{loginStore.number_of_suggestions}</span>
          </Col>
        </Row>
        <Row className="leader-board-lp-row">
          <Col>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Politics-Vote-2--Streamline-Core"><desc>Politics Vote 2 Streamline Icon: https://streamlinehq.com</desc><g id="politics-vote-2"><path id="Subtract" fill="#8fbffa" fill-rule="evenodd" d="M3.99932 5.63647H2.27197c-0.82842 0 -1.499997 0.67158 -1.499997 1.5v6.33633c0 0.2761 0.223857 0.5 0.499997 0.5H12.1789c0.2762 0 0.5 -0.2239 0.5 -0.5V7.13647c0 -0.82842 -0.6715 -1.5 -1.5 -1.5H9.62247L8.04834 7.21061c-0.68342 0.68342 -1.79146 0.68342 -2.47488 0L3.99932 5.63647Z" clip-rule="evenodd" stroke-width="1"></path><path id="Vector 1085" fill="#2859c5" d="M9.42631 4.06484 7.16442 6.32673c-0.19526 0.19526 -0.51184 0.19526 -0.70711 0L3.45318 3.32259c-0.19526 -0.19526 -0.19526 -0.51184 0 -0.7071L5.71507 0.3536c0.19526 -0.195262 0.51184 -0.195262 0.7071 0l3.00414 3.00414c0.19526 0.19526 0.19526 0.51184 0 0.7071Z" stroke-width="1"></path><path id="Union" fill="#2859c5" fill-rule="evenodd" d="M4.29071 7.14819h1.22033l0.06242 0.06242c0.68342 0.68342 1.79146 0.68342 2.47488 0l0.06242 -0.06242h1.04941c0.34517 0 0.625 0.27983 0.625 0.625 0 0.34518 -0.27983 0.625 -0.625 0.625H4.29071c-0.34518 0 -0.625 -0.27982 -0.625 -0.625 0 -0.34517 0.27982 -0.625 0.625 -0.625ZM0.771973 13.4728V10H12.6789v3.4728c0 0.2761 -0.2238 0.5 -0.5 0.5H1.27197c-0.27614 0 -0.499997 -0.2239 -0.499997 -0.5Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            <span> Votes: </span>
            <span>{loginStore.number_of_votes}</span>
          </Col>
        </Row>
        <Row className="leader-board-lp-row">
          <Col>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Hierarchy-2--Streamline-Core"><desc>Hierarchy 2 Streamline Icon: https://streamlinehq.com</desc><g id="hierarchy-2--node-organization-links-structure-link-nodes-network-hierarchy"><path id="Union" fill="#2859c5" fill-rule="evenodd" d="M6.25 7.75v3.75h1.5V7.75H11a0.25 0.25 0 0 1 0.25 0.25v3.5a0.75 0.75 0 0 0 1.5 0V8A1.75 1.75 0 0 0 11 6.25H7.75V2.5h-1.5v3.75H3A1.75 1.75 0 0 0 1.25 8v3.5a0.75 0.75 0 0 0 1.5 0V8A0.25 0.25 0 0 1 3 7.75h3.25Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_2" fill="#8fbffa" fill-rule="evenodd" d="M5 2A1.5 1.5 0 0 1 6.5 0.5h1A1.5 1.5 0 0 1 9 2v1a1.5 1.5 0 0 1 -1.5 1.5h-1A1.5 1.5 0 0 1 5 3V2Zm0 9a1.5 1.5 0 0 1 1.5 -1.5h1A1.5 1.5 0 0 1 9 11v1a1.5 1.5 0 0 1 -1.5 1.5h-1A1.5 1.5 0 0 1 5 12v-1ZM1.5 9.5A1.5 1.5 0 0 0 0 11v1a1.5 1.5 0 0 0 1.5 1.5h1A1.5 1.5 0 0 0 4 12v-1a1.5 1.5 0 0 0 -1.5 -1.5h-1ZM10 11a1.5 1.5 0 0 1 1.5 -1.5h1A1.5 1.5 0 0 1 14 11v1a1.5 1.5 0 0 1 -1.5 1.5h-1A1.5 1.5 0 0 1 10 12v-1Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            <span> Referrals: </span>
            <span>{loginStore.number_of_referrals}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="No-Poverty--Streamline-Core"><desc>No Poverty Streamline Icon: https://streamlinehq.com</desc><g id="no-poverty"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M3.164 4.462a1.715 1.715 0 1 0 0 -3.431 1.715 1.715 0 0 0 0 3.43Zm7.672 0a1.715 1.715 0 1 0 0 -3.431 1.715 1.715 0 0 0 0 3.43Zm0 0.357a3.06 3.06 0 0 0 -1.898 0.656 2.959 2.959 0 0 1 0.95 2.917h3.521a0.5 0.5 0 0 0 0.5 -0.5 3.073 3.073 0 0 0 -3.073 -3.073Zm-7.672 0a3.06 3.06 0 0 1 1.898 0.656 2.959 2.959 0 0 0 -0.95 2.917H0.59a0.5 0.5 0 0 1 -0.5 -0.5A3.073 3.073 0 0 1 3.164 4.82ZM7 9.435a1.715 1.715 0 1 0 0 -3.43 1.715 1.715 0 0 0 0 3.43Zm-3.073 3.43a3.073 3.073 0 0 1 6.146 0 0.5 0.5 0 0 1 -0.5 0.5H4.427a0.5 0.5 0 0 1 -0.5 -0.5Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            <span> Attendance: </span>
            <span>{loginStore.number_of_total_visited}</span>
          </Col>
        </Row>
        </CardBody>
        </Card>
        </div>
        <hr/>
        <div className="top-three-leaderboard-step">
          {feedWallStore.userFeedTopThreeOnLeaderboard.length > 0 ? (
            <div>
            <span className="leader-board-lp-heading" onClick={async () => {
              leaderboardStore.setAllLeaderboards(loginStore);
              this.props.leaderboardStep();
            }}>
            Leaderboard Top 3
            </span>
            {feedWallStore.userFeedTopThreeOnLeaderboard.map((user, index) => (
                 <Card className="leader-board-lp x-small-font">
                 <CardBody className="leader-board-lp-card-body pointing-cursor">
                 <Row onClick={async () => {
                    if(user.public_id != null) {
                      await this.props.viewProfileStore.setProfile(loginStore.miniToken, user.public_id);
                      this.props.viewProfileStep();
                    }
                 }}>
                   <Col>
                   <span>{user.rank}.</span>
                   <span>{user.name}</span>
                   </Col>
                 </Row>
                 </CardBody>
                 </Card>
             ))}
            </div>
            )
            :
            'default something'
          }
        </div>
      </div>
    );
  }
}

export default FeedWallLeftPanel;
